@import '../../theme/bootstrap-custom-variables';

.activeDropBox {
    @extend .dropBox;
    background-color: $white;
    border-color: $brand-success;
    border-style: solid;
}

.dropArea {
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 80%;
}

.dropBox {
    background-color: $lightest-grey;
    border-color: $brand-primary;
    border-radius: 5px;
    border-style: dashed;
    border-width: 2px;
    display: inline-block;
    height: 250px;
    margin-bottom: 10px;
    width: 250px;

    &:focus,
    &:hover {
        background-color: $color-primary-hover-a50;
        border-style: solid;
        cursor: pointer;
    }
}

.dropBoxWithPreview {
    border: 1px solid;
    display: inline-block;
    margin-bottom: 10px;
    width: 250px;
}

.editorContainer {
    width: 100%;

    canvas {
        display: inline;
    }
}

.editorSection {
    display: inline-block;
    margin-top: 10px;
    width: 250px;
}

.editorSectionButton {
    @extend .editorSection;
    margin-top: 20px;
}

.imagePreview {
    display: block;
    width: 100%;
}
