.controls {
    margin-bottom: 20px;
}

.phaseSelectContainer {
    display: inline-block;
    vertical-align: top;

    label {
        margin-top: 8px;
        vertical-align: top;
    }
}

.phaseSelectForm {
    display: inline-block;
    margin-bottom: 5px !important;
    text-align: left;
    width: 180px;
}
