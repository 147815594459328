@import '../../../../../theme/bootstrap-custom-variables';

.guidedSection {
    text-align: center;
}

.typeText {
    font-size: 16px;
    margin-top: 10px;
}

.infoSection {
    font-size: 14px;
    margin-top: 5px;
}

.optional {
    border-color: $state-warning-text !important;
    color: $state-warning-text !important;
}

.recommended {
    border-color: $state-info-text !important;
    color: $state-info-text !important;
}

.required {
    border-color: $state-success-text !important;
    color: $state-success-text !important;
}
