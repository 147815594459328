@import '../../../../theme/bootstrap-custom-variables';

.navContainer {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    margin-bottom: 20px;

    @media print {
        border: 0;
        border-radius: 0;
        margin-bottom: 0;
    }
}
