@import '~@opengov/capital-style/scss/tokens';

.verifiedCertificationsList {
    gap: $unit-2;

    .description {
        color: $color-fg-secondary;
    }

    .verifiedCertifications {
        gap: $unit-1;
    }

    .verifiedCertificationsText {
        gap: $unit-half;
    }
}
