@import '../../../../theme/bootstrap-custom-variables';

.header {
    padding-top: 30px;
}

.container {
    @media (min-width: 1400px) {
        width: 1340px !important;
    }
}

.titleContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .title {
        font-size: 30px;
        font-weight: 500;
        padding-right: 15px;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.countdownTimer {
    @media (max-width: $screen-xs-min) {
        float: left;
    }

    @media (min-width: $screen-lg) {
        float: right;
    }
}

.timelineDetails {
    margin-bottom: 30px;
    margin-top: 10px;
}

.titleItem,
.timelineItem {
    display: inline-block;
    margin-right: 20px;
}

.timerText {
    font-weight: bold;
    margin-bottom: 10px;
}

.timezoneText {
    margin-top: 5px;
}

.navItems {
    padding-left: 130px;
}

.numberOfBidsTotal {
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;

    @media (min-width: $screen-xs-min) {
        border-right: 1px solid #dce0e0;
    }
}

.numberOfBidsLabel {
    font-weight: 700;
    padding-bottom: 10px;
}

@media (min-width: $screen-lg-min) {
    .pullRightLg {
        float: right;
    }
}
