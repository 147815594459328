.alert {
    align-items: center;
    display: flex;
    justify-content: left;
    margin-top: 10px;
    padding: 4px;
}

.errorText {
    color: #000;
    padding: 4px;
    text-align: left;
}

.icon {
    padding: 0 10px 0 40px;
}

.policyWarning {
    padding-bottom: 15px;
}
