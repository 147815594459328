.notaryLiveText {
    font-style: italic;
    margin-top: 5px;
}

.logo {
    margin-left: 5px;
    max-width: 100%;
    vertical-align: top;
    width: 20px;
}
