@import '~@opengov/capital-style/scss/tokens';

.container {
    color: $color-gray-700;
    font-family: $base-font;
    margin-bottom: $unit-7;
    margin-top: $unit-7;

    svg {
        fill: none;
        height: 55px;
        margin-bottom: $unit-3;
        width: 64px;

        path {
            fill: $color-gray-700;
        }
    }

    .header {
        color: $color-gray-700;
        font-size: $font-size-medium;
        font-weight: $font-weight-medium;
        line-height: $line-height-medium;
    }

    .subheader {
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        line-height: $line-height-default;
    }
}
