@import '~@opengov/capital-style/scss/tokens';

.bodyText {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    padding-bottom: $unit-half;

    .boldText {
        font-weight: $font-weight-bold;
    }
}
