.starContainer {
    display: inline-block;
    font-size: 6px;
    position: relative;

    .starWrap {
        position: relative;

        .starsActive {
            align-items: center;
            color: #f47a46;
            display: flex;
            overflow: hidden;
            position: relative;
            white-space: nowrap;
            z-index: 10;
        }

        .starsInactive {
            align-items: center;
            color: #dce0e0;
            display: flex;
            left: 0;
            position: absolute;
            -webkit-text-stroke: initial;
            top: 0;
        }

        .starStyle {
            padding-right: 3px;
        }
    }
}
