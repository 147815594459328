@import '../../theme/bootstrap-custom-variables';

.noDataPanel {
    height: 300px;
    text-align: center;

    h5 {
        margin-top: 40px;
    }
}

.noDataIcon {
    color: #777;
    font-size: 9em !important;
    margin-top: 60px;

    &.noDataSuccess {
        color: #449d44;
    }
}

.notEnabled {
    font-style: italic;
    margin-top: 30px;

    p {
        margin-bottom: 0;
    }
}
