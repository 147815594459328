@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    font-family: $base-font-alt;
}

.card {
    display: flex;
    flex-direction: column;
    font-size: $font-size-base;
    gap: $unit-2;
    padding: $unit-2;
}

.inlineSeparate {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: $font-size-medium;
    font-weight: $font-weight-semi-bold;
}

.description {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
}

.budgetCheckPass {
    color: $color-fg-success-small;
    font-weight: $font-weight-semi-bold;
}

.budgetCheckFail {
    color: $color-fg-error;
    font-weight: $font-weight-semi-bold;
}

.closeIcon {
    color: $color-fg-secondary;
    padding: 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-top: $unit-2;
}

.viewButton {
    color: $color-fg-link;
    display: flex;
    font-size: $font-size-base;
    justify-content: start;
    padding: 0;
    width: 50%;
}

.viewButton:focus {
    outline: none;
}

.chartContainer {
    padding-top: $unit-3;
}

.amountRemaining {
    display: flex;
    font-weight: $font-weight-regular;
    justify-content: end;
}

.itemProperty {
    font-weight: $font-weight-medium;
}

.overBudget {
    font-weight: $font-weight-semi-bold;
}

.detailsContainer {
    display: flex;
    text-wrap: nowrap;
}

.fallingBackToAccountLevel {
    color: $color-fg-secondary;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
}
