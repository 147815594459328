.controlContainer {
    display: inline-block;
    width: 80px;
}

.timelineField {
    margin-bottom: 15px;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.fieldName {
    display: inline-block;
    margin-top: 3px;
    vertical-align: top;
}

.timelineTypeTitle {
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 15px;
}

.removeButton {
    margin-left: 15px;
    padding: 0 !important;
}
