.settingsTableCol {
    white-space: nowrap;
}

.settingsTableTruncatedCol {
    max-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
