@import '~@opengov/capital-style/scss/tokens';

.helpWrapper {
    :global(.help-block) {
        color: $color-gray-700;
        display: block;
        font-size: $font-size-small;
        font-weight: $font-weight-regular;
        line-height: $line-height-small-font;
        margin: 0;
        margin-bottom: $unit-half;
    }
}
