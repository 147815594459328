@import '~@opengov/capital-style/scss/tokens';

.container {
    font-family: $base-font !important;
    padding-right: $unit-7;
    padding-left: $unit-7;

    .label {
        color: $color-gray-1000;
        font-family: $base-font;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        line-height: $line-height-tight;
        margin-bottom: $unit-half;
        align-content: center;
        display: flex;
    }

    .customInputLabel {
        @extend .label;

        font-size: $font-size-small;
    }

    .fieldTooltip {
        align-items: center;
    }

    .description {
        color: $color-gray-700;
        font-size: $font-size-small;
        font-family: $base-font;
        font-weight: $font-weight-regular;
        line-height: $line-height-tight;
        margin-bottom: $unit-1;
    }

    .signatureDescriptionMargin {
        margin-top: -$unit-1;
    }

    .descriptionMarginBottom {
        margin-bottom: $unit-2;
    }

    .border {
        border: 1px solid $color-gray-200;
    }

    .fieldSet {
        border: 1px solid $color-gray-200;
        margin-bottom: $unit-2;
        padding: $unit-2;
        border-radius: $unit-half;
    }

    .error {
        color: $color-error-700;
    }

    .action {
        color: $color-action;
    }

    .doneAction {
        background-color: $color-primary-brand;
        padding: 0 8px;
        color: $color-white;
    }

    .textSmall {
        font-size: $font-size-small;
    }

    .margin {
        margin: $unit-1;
    }

    .addField {
        border-radius: 3px;
        border: 1px solid $color-gray-200;
        background-color: $color-gray-50;
    }
}

.helpIcon {
    color: $color-gray-700;
    margin-left: $unit-1 !important;
    width: $unit-2 !important;
    height: $unit-2 !important;
}

.tooltipIcon {
    color: $color-gray-700;
    margin-top: -($unit-1/1.8);
    margin-left: $unit-1 !important;
    width: $unit-2 !important;
    height: $unit-2 !important;
}

.marginTooltip {
    margin-top: $unit-1 * 1.8 !important;
}

.checkbox {
    font-family: $base-font !important;
}
