.noContractsContainer {
    margin-top: 100px;
    text-align: center;
}

.noContractsText {
    font-size: 24px;
    margin-bottom: 30px;
}

.noContractsHeader {
    font-size: 30px;
    margin-bottom: 8px;
}
