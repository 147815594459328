@import '../../../../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.addBtn {
    margin-bottom: 15px;
    margin-top: 5px;
    text-align: center;
}

.addItemBtn {
    color: $state-info-text !important;
}

.openGovStyle {
    button {
        color: $color-gray-700;
        font-family: $base-font;
        font-weight: $font-weight-medium;
    }

    button:hover {
        background-color: $color-white;
        color: $color-gray-700;
        font-weight: $font-weight-medium;
    }
}
