@import '../../../theme/bootstrap-custom-variables';

.link {
    border-bottom: 2px dotted #808080;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5em;
    margin-bottom: 20px;
    padding: 2px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    max-width: 100%;
}

.info {
    font-size: 1.1em;
}

@media (max-width: $screen-xs-max) {
    .link {
        font-size: 1.1em;
        padding: 1px;
    }

    .info {
        font-size: 0.9em;
    }
}
