@import '~@opengov/capital-style/scss/tokens';

.dropzone {
    background-color: $color-white !important;
    border: 0;
    padding: $unit-2;
}

.dropzoneContainer {
    padding: 0 !important;
}

.dropzoneUploadReplacement {
    background-color: $color-white !important;
    border: 0;
    padding: 0 !important;
}

.panelGroup {
    .panelHeading {
        background-color: $color-white;
        padding: $unit-2 $unit-3;

        &.panelHeadingOpen {
            background-color: $color-primary-hover-light;
        }

        :global(.label-default) {
            font-size: $font-size-minimum;
            font-weight: $font-weight-regular;
        }

        :global(.panel-title) {
            a:hover,
            a:focus {
                text-decoration: none;
            }
        }
    }

    .panelBody {
        margin: $unit-3;
        border: 0 !important;
        padding: 0 !important;
    }
}
