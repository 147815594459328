.panel {
    border: 0 !important;
    box-shadow: none !important;
}

.tableFooter {
    margin-top: 3px;

    p {
        display: inline-block;
        font-size: 0.9em;
        font-style: italic;
        margin-bottom: 0;
    }
}
