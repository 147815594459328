.checkbox {
    margin-top: 0 !important;
}

.countryCodeCol {
    padding-right: 0 !important;
}

.extBtn {
    margin-top: 25px;
}

.extCol {
    padding-left: 0 !important;
}

.formError {
    color: #b23e24;
    margin-bottom: 20px;
}

.requiredNotice {
    margin-bottom: 20px;
    margin-left: 15px;
}
