@import '~@opengov/capital-style/scss/tokens';

.displayCertification {
    border: $border-gray-200;
    border-radius: $unit-half;
    gap: $unit-1;

    .editButton {
        align-items: center;
        display: flex;
        gap: $unit-half;
        padding: 0 12px;

        &:hover {
            text-decoration: none;
        }
    }

    .label {
        color: $color-fg-secondary;
        font-weight: $font-weight-medium;
    }

    .title {
        align-items: center;
        display: flex;
        gap: $unit-half;
    }
}

.verifiedCertification {
    background-color: $color-bg-secondary;
    border: 1px solid $color-fg-border-primary;
    gap: $unit-1;
}
