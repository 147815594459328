@import '../../../../theme/bootstrap-custom-variables';

.section {
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }
}

.sectionHeader {
    border-bottom: 1px solid $panel-default-border;
    color: $text-muted;
    font-weight: bold;
    padding-bottom: 8px;
}

.sectionItem {
    border-bottom: 1px solid $panel-default-border;
    padding: 8px 0;

    &:last-child {
        border-bottom: 0;
    }

    ul {
        margin-bottom: 0;
    }
}

.icon {
    margin-right: 4px;

    &:hover {
        text-decoration: none;
    }
}

.attachmentButton {
    margin-left: 8px;
}

.downloadContainer {
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
}

.downloadMsg {
    margin-top: 5px;
}
