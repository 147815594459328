@import '../../theme/bootstrap-custom-variables';

.logo {
    border: 1px solid $gray-light;
    padding: 3px;
}

.logoContainer {
    margin-bottom: 10px;
}

@media (min-width: $screen-lg) {
    .container {
        margin: 0 auto;
        max-width: 320px;

        img {
            margin: 0 auto;
        }
    }
}
