@import '~@opengov/capital-style/scss/tokens';

.ogLabel {
    color: $color-gray-1000 !important;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
    margin-bottom: $unit-half;
}
