@import '~@opengov/capital-style/scss/tokens';

.stepPanel:not(:first-child) {
    margin-top: 20px;
}

.stepPanelHeading {
    cursor: pointer;

    &:hover .stepTitle {
        text-decoration: underline;
    }
}

.stepPanelBody {
    padding: 0 !important;
}

.stepButton {
    padding-bottom: 3px !important;
    padding-top: 3px !important;
}

.insertButton {
    color: $color-fg-link !important;

    &:hover {
        color: $color-fg-link !important;
    }
}
