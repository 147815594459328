.initialTermLengthColumn {
    padding-right: 3px !important;
}

.initialTermLengthUnitColumn {
    padding-left: 3px !important;
}

.lengthColumn {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.lengthUnitColumn {
    padding-left: 3px !important;
}

.noRenewalsText {
    font-style: italic;
}

.optionName {
    margin-top: 10px;
}

.removeRenewalButtonColumn {
    padding-left: 0 !important;
}

.renewalsContainer {
    margin-bottom: 10px;
    margin-top: 10px;
}
