@import '../../../theme/bootstrap-custom-variables';

.container {
    min-height: 500px;
}

.logo {
    border: 1px solid $gray-light;
    padding: 3px;
}

.name {
    font-size: 1.4em;
    margin-bottom: 3px;
    margin-top: 15px;
}

.location {
    font-size: 1.1em;
    margin-bottom: 7px;
}

.helpCenterContainer {
    font-size: 1.2em;
    text-align: center;
}

@media (min-width: $screen-lg) {
    .controlCol {
        margin: 0 auto;
        max-width: 320px;

        img {
            margin: 0 auto;
        }
    }
}
