.email {
    font-style: italic;
}

.fullName {
    font-weight: bold;
}

.title {
    margin-bottom: 20px;
    text-align: center;
}
