.completeText {
    margin-bottom: 20px !important;
    margin-top: 10px;
}

.completeButton {
    margin-bottom: 10px;
}

.saveButton {
    border: 0 !important;
    padding: 0 !important;
    vertical-align: top;
}
