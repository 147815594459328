.header {
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
}

.subheader {
    font-size: 1.1em;
    margin-bottom: 20px;
    text-align: center;
}

.bigBtn {
    padding: 9px 15px !important;
}

.smallBtn {
    margin-right: 20px;
    margin-top: 3px;
}

.continueBtn {
    margin-left: 10px;
}

.modalSubtitle {
    margin-bottom: 0;
}
