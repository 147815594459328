.clickHandler {
    cursor: pointer;
}

.errorMessage {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.loadingError {
    font-size: 24px;
    margin-top: 40px;
    padding-bottom: 80px;
    text-align: center;
}
