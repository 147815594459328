.awardLetter {
    margin-bottom: 15px;
    text-align: right;
}

.sectionContainer {
    padding-top: 20px;
}

.vendorName {
    margin-top: 40px;
}
