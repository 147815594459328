@import '../../../../theme/bootstrap-custom-variables';

.attendanceBtn {
    width: 135px;
}

.attendanceCell {
    margin-bottom: 12px;
    overflow: visible !important;
}

.deleteLink {
    font-size: 0.9em;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.rsvpsTable {
    margin-top: 30px;
}

.rsvpToggle {
    margin-bottom: -5px;
    margin-left: 5px;
}

.rsvpToggleBlock {
    display: inline-block;
    margin-right: 10px;
}

.tableTitle {
    display: inline-block;
}

.timelineDate {
    color: #777;
    font-size: 14px;
    padding-left: 10px;
}

.userProfilePicture {
    margin-right: 7px;
}

.vendorDataCell {
    align-items: flex-end;
    display: inline-flex;
}

.vendorEmail {
    font-style: italic;
}

.vendorName {
    font-size: 1.1em;
    margin-bottom: 1px;
}

.timelineRsvpContainer {
    margin-left: auto; /* Couldn't apply this to the MUI Box component since it treats it as a custom HTML attribute for some reason */
}

@media (max-width: $screen-sm-max) {
    .tableTitle {
        text-align: left;
        width: 70vw;
    }
}
