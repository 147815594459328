@import '../../../../../../../../theme/bootstrap-custom-variables';

.customColumnInput {
    padding-right: 0 !important;
}

.deleteCustomColumnBtn {
    margin-top: 24px;
}

.optionsTitle {
    margin-bottom: 8px;
}

.addColumnBtn {
    text-align: center;
}

@media (max-width: $screen-sm-max) {
    .addColumnBtn {
        text-align: left;
    }
}
