@import '../../../theme/bootstrap-custom-variables';

.createButton {
    margin-bottom: 10px;
    text-align: right;
}

.documentsNav {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    margin-bottom: 20px;
    // Fixes off by one error that can cause sticky to not display right border
    margin-right: 1px;
}
