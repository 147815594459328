@import '../../../theme/bootstrap-custom-variables';

.containerBorder {
    border-top: 1px solid $table-border-color;
    padding-top: 20px;
}

.leftCol {
    text-align: right;
}

.viewBtn {
    margin-bottom: 10px;
    margin-top: 10px;
}

@media (max-width: $screen-xs-max) {
    .leftCol {
        text-align: center;
    }
}
