@import '../../theme/bootstrap-custom-variables';

.applyAlert {
    margin-bottom: 10px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    text-align: center;
}

.indent {
    padding-left: 18px;
}

.projectDetailNavContainer {
    min-height: 600px;
}

.projectDetailNav {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    // Fixes off by one error that can cause sticky to not display right border
    margin-right: 1px;
}

.navContainer {
    margin-bottom: 20px;

    @media print {
        margin-bottom: 0;
    }
}

.commentOverflow {
    overflow: auto !important;
}
