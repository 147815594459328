.tableContainer {
    margin-bottom: 20px;
}

.table {
    margin-bottom: 0 !important;
}

.noPermission {
    font-style: italic;
    padding: 5px 10px;
}
