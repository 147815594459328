.backButton {
    margin-right: 20px;
}

.completeIcon {
    margin-bottom: 10px;
    margin-top: 10px;
}

.instructionTitle {
    margin-bottom: 15px;
    text-align: center;
}

.modalBody {
    line-height: 1.6em;
    margin-bottom: 5px;
    margin-top: 20px;
}

.nextButtons {
    margin-top: 30px;
    text-align: center;
}

.nextIcon {
    margin-left: 5px;
}

.nextSteps {
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.nextStepsTitle {
    margin-bottom: 30px;
    text-align: center;
}

.startButton {
    margin-bottom: 20px;
    margin-top: 30px;
    text-align: center;
}
