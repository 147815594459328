@import '~@opengov/capital-style/scss/tokens';

.priceItemsContainer {
    display: flex;
    flex-direction: column;
    gap: $unit-1;
}

.container {
    display: flex;
    flex-direction: column;
    font-size: $font-size-base;
    gap: $unit-3;

    &.containerBackground {
        background: $color-bg-primary;
        border: 1px solid $color-fg-border-primary;
        border-radius: $unit-half;
        padding: $unit-3;

        @media (max-width: 600px) {
            padding: $unit-2;
        }
    }
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: $unit-2;
}

.titleContainer {
    align-items: center;
    display: flex;
    gap: $unit-3;
}

.linkButton {
    align-items: center;
    display: flex;
    gap: $unit-half;
}

.amount,
.loadingAccountInfo {
    padding-top: $unit-1;
}

.budgetCheckPass {
    color: $color-fg-success-small;
}

.budgetCheckFail {
    color: $color-fg-error;
}

.inlineText {
    display: flex;
    gap: $unit-half;
}

.strong {
    font-weight: $font-weight-medium;
}

.success {
    color: $color-fg-success-small;
}

.divider {
    border-top: 1px solid $color-fg-border-primary;
    flex-grow: 1;
    margin-bottom: $unit-1;
    margin-top: $unit-1;
}

.budgetCheck {
    display: flex;
    justify-content: space-between;
}

.viewDetails {
    color: $color-fg-secondary !important;
}

.internalBadge {
    background-color: $color-bg-tertiary;
    border-radius: $border-radius-default;
    color: $color-gray-700;
    font-size: $font-size-minimum;
    font-style: $base-font-alt;
    font-weight: $font-weight-semi-bold;
    letter-spacing: 0.2px;
    line-height: $unit-2;
    margin-left: $unit-1;
    padding: $unit-half $unit-1;
}

.title {
    font-weight: $font-weight-medium;
}

.titleMuted {
    color: $color-fg-secondary;
    font-weight: $font-weight-medium;
}

.purchaseDetails {
    display: flex;
    flex-direction: column;
    gap: $unit-1;
}

.attachmentList {
    margin-top: $unit-1;

    .attachmentItem {
        border-bottom: 1px solid $color-gray-200;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-bottom: $unit-1;
        padding-top: $unit-1;

        a {
            color: $color-action-700;
            text-decoration: none;
            font-weight: $font-weight-regular;
        }
    }

    .attachmentItem:first-child {
        border-top: 1px solid $color-gray-200;
    }
}

.summaryCard {
    align-self: stretch;
    background: $color-bg-primary;
    border: 1px solid $color-fg-border-primary;
    border-radius: $unit-half;
    display: flex;
    flex-direction: column;
    gap: $unit-3;
    padding: $unit-3;

    @media (max-width: 600px) {
        padding: $unit-2;
    }
}
