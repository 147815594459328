@import '../../theme/bootstrap-custom-variables';

.profilePicture {
    display: inline-block;
    margin-right: 5px;

    &.showOrganization {
        float: left;
        margin-right: 0;
    }
}

.user {
    padding: 10px 3px;

    &:not(:last-child) {
        border-bottom: 1px solid $table-border-color;
    }
}

.userList {
    margin-bottom: 0;
}

.userNameContainer {
    line-height: 1.2em;
    overflow: hidden;
    padding-left: 5px;
}
