@import '../../../theme/bootstrap-custom-variables';

.articleContainer {
    border-left: 3px solid $table-border-color;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-top: 2px;
}

.showToggle {
    margin-left: 10px;
}
