@import '../../../theme/bootstrap-custom-variables';

.pastEvaluationText {
    font-size: 1.1em;
    padding-bottom: 7px;
    padding-top: 7px;

    .currentEvaluationLink {
        display: inline-block;
    }
}

.navbarContainer {
    height: 61px;
    margin-top: -20px;

    .projectActionToolbar {
        margin-bottom: 0 !important;
    }
}

/*** Custom Navbar ***/
$bg-default: $white;
$bg-highlight: $navbar-default-link-active-bg;
$col-default: $navbar-default-link-color;
$col-highlight: $navbar-default-link-hover-color;

.projectActionToolbar {
    &:global(.navbar-default) {
        background-color: $bg-default;
        border-color: $bg-highlight;

        :global(.navbar-brand) {
            color: $col-default;

            &:hover,
            &:focus {
                color: $col-highlight;
            }
        }

        :global(.navbar-text) {
            color: $col-default;
        }

        :global(.navbar-nav) {
            > li {
                > a {
                    color: $col-default;

                    &:hover,
                    &:focus {
                        color: $col-highlight;
                    }
                }
            }

            > :global(.active) {
                > a,
                > a:hover,
                > a:focus {
                    background-color: $bg-highlight;
                    color: $col-highlight;
                }
            }

            > :global(.open) {
                > a,
                > a:hover,
                > a:focus {
                    background-color: $bg-highlight;
                    color: $col-highlight;
                }
            }
        }

        :global(.navbar-toggle) {
            border-color: $bg-highlight;

            &:hover,
            &:focus {
                background-color: $bg-highlight;
            }

            :global(.icon-bar) {
                background-color: $col-default;
            }
        }

        :global(.navbar-collapse),
        :global(.navbar-form) {
            border-color: $col-default;
        }

        :global(.navbar-link) {
            color: $col-default;

            &:hover {
                color: $col-highlight;
            }
        }
    }
    @media (max-width: 767px) {
        &:global(.navbar-default .navbar-nav .open .dropdown-menu) {
            > li > a {
                color: $col-default;

                &:hover,
                &:focus {
                    color: $col-highlight;
                }
            }

            > :global(.active) {
                > a,
                > a:hover,
                > a:focus {
                    background-color: $bg-highlight;
                    color: $col-highlight;
                }
            }
        }
    }

    &:global(.navbar-default) {
        min-height: 40px;

        :global(.navbar-nav) {
            > li > a {
                padding-bottom: 10px;
                padding-top: 10px;
            }

            > li button {
                margin-bottom: 5px;
                margin-top: 5px;
            }
        }
    }
}

.projectToggle {
    color: $col-default;
    display: inline-block;
    margin: 8px 15px 0;
}

.inactiveSubmitBtn {
    border-color: $state-success-text !important;
    color: $state-success-text !important;
}

.editIcon,
.saveIcon {
    font-size: 1.1em;
}
