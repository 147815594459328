@import '../../../theme/bootstrap-custom-variables';

.version1Column {
    padding-right: 0 !important;
}

.version2Column {
    padding-left: 0 !important;
}

.arrowIcon {
    margin-top: 8px;
    text-align: center;
}

@media (max-width: $screen-sm-max) {
    .version1Column,
    .version2Column {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
