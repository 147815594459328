@import '../../theme/bootstrap-custom-variables';

.input {
    resize: vertical;
}

.commentIcon {
    margin-left: 10px;
}

/* Show to screen-readers but not visually */
/* Per https://stackoverflow.com/questions/62107074/how-to-hide-a-text-and-make-it-accessible-by-screen-reader/62109988#62109988 */
.hidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px); /* maybe deprecated but we need to support legacy browsers */
    clip-path: inset(50%); /* modern browsers, clip-path works inwards from each corner */
    overflow: hidden;
    padding: 0;
    white-space: nowrap; /* added line to stop words getting smushed together (as they go onto separate lines and some screen readers do not understand line feeds as a space */
    width: 1px;
}

.labelWrapper {
    display: flex;
    justify-content: space-between;

    .characterCount {
        color: $gray-light;
        font-size: 12px;
    }
}
