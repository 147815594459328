@import '~@opengov/capital-style/scss/tokens/layout';

.noAttachmentsText {
    font-style: italic;
    margin-bottom: 10px;
}

.attachmentsList,
.fileUpload {
    margin-bottom: 10px !important;
}

.attachment {
    font-size: 1.05em !important;
    margin-bottom: 10px;
}

.tagSelect {
    margin-bottom: 0 !important;
    position: relative;
}

.container {
    margin-bottom: 20px;
}
