@import '../../../theme/bootstrap-custom-variables';

* {
    box-sizing: border-box;
}

.buttonBox {
    padding-top: 10px;

    .buttonLeft {
        width: 48%;
    }

    .buttonRight {
        border-color: $brand-primary !important;
        margin-left: 10px;
        width: 48%;

        &:hover {
            background-color: $btn-primary-bg !important;
            border-color: $btn-primary-border !important;
            color: $btn-primary-color !important;
        }
    }
}

.contactProcurated {
    align-items: center;
    background-color: #f8f9fb;
    border: 1px;
    border-color: #dce2e8;
    border-radius: 5px;
    border-style: solid;
    color: $text-color;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    justify-content: center;
    padding: 10px;
    row-gap: 5px;
    width: 100%;

    .muted {
        color: $gray-dark;
    }
}

.logoContainer {
    font-size: 11px;
    margin: 5px 5px 0 0;
    text-align: right;

    .logo {
        height: 11px;
        margin-left: 4px;
    }
}

.manageButton {
    margin: 10px 0;
    width: 100%;
}

.procuratedBox {
    border: 1px;
    border-color: #dce2e8;
    border-radius: 5px;
    border-style: solid;
    color: $gray-dark;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.starsDisplayContainer {
    align-items: center;
    color: $gray-dark;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 4px;
    text-overflow: visible;

    .mutedRatingText {
        font-size: 14px;
        margin-left: 10px;
    }

    .ratingText {
        color: $text-color;
        font-size: 20px;
        margin-left: 10px;
    }
}

.smallProcuratedBox {
    border: 1px;
    border-color: #dce2e8;
    border-radius: 5px;
    border-style: solid;
    color: $gray-dark;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    width: 100%;
}

@media (max-width: 1200px) {
    .mutedRatingText {
        margin-left: 0;
    }

    .ratingText {
        margin-left: 0;
    }
}

@media (max-width: 750px) {
    .mutedRatingText {
        margin-left: 10px;
    }

    .ratingText {
        margin-left: 10px;
    }
}
