@import '~@opengov/capital-style/scss/tokens';

.legendColor {
    border: 1px solid #000;
    border-radius: 2px;
    display: inline-block;
    height: 20px;
    width: 20px;

    p {
        display: inline-block;
        margin-left: 4px;
    }
}

.legendContainer {
    margin-bottom: 5px;
}

.legendText {
    display: inline-block;
    margin-left: 8px;
    vertical-align: top;
}

.specifyUnitPriceIcon {
    color: $color-fg-link;
    padding-left: $unit-half;
}

.tableFooter {
    margin-top: 3px;

    p {
        display: inline-block;
        font-size: 1em;
        font-style: italic;
        margin-bottom: 0;
    }
}
