@import '~@opengov/capital-style/scss/tokens';

.container {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;

    .section {
        background-color: $color-white;
        border: 1px solid $color-gray-200;
        margin-bottom: $unit-2;
        padding: $unit-3;
        padding-bottom: $unit-1;

        .row:not(:last-child) {
            margin-bottom: $unit-3;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: $font-weight-medium;
            line-height: $line-height-medium;
            margin-bottom: $unit-3;
        }

        label {
            font-weight: $font-weight-medium;
        }

        .noMargin {
            margin-bottom: 0;
        }

        .help {
            color: $color-gray-700;
            font-size: $font-size-small;
            font-weight: $font-weight-regular;
            line-height: $line-height-small-font;
        }

        .filesText {
            color: $color-gray-700;
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-regular;
            margin-top: 5px;

            .action {
                color: $color-action;
            }
        }

        .subsectionDescription {
            :global(button) {
                padding: 0;
            }
        }
    }
}

.dropzoneContainer {
    margin-top: 3px;
    padding: 0 !important;
}

.dropzone {
    border: 1px dashed $color-gray-200 !important;

    &:hover {
        background-color: $color-white !important;
    }
}

.invalidDropzone {
    border: 1px dashed $color-error-700 !important;

    :global(.dropzone-custom-content) {
        color: $color-error-700 !important;
    }
}

.invalidDropzoneText {
    color: $color-error-700;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
}

.alert {
    background-color: $color-warning-50;
    border-color: $color-warning-500;
    border-radius: 0;
    color: $color-gray-1000;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    padding: $unit-1;
    text-align: left;

    i {
        color: $color-warning-800;
        margin-right: $unit-half;
    }
}

.oldFont {
    * {
        font-family: $base-font !important;
    }
}
