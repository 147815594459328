@import '../../theme/bootstrap-custom-variables';

.overlay {
    background-color: $gray;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.notFound {
    color: #fff;
    margin-top: 150px;
    text-align: center;
}

.message {
    font-size: 1.4em;
    font-style: italic;
    margin-top: 20px;
}
