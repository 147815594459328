@import '../../../../../theme/bootstrap-custom-variables';

.auctionWell {
    margin-bottom: 10px;

    &.auctionInProgress {
        background-color: $state-warning-bg;
        border-color: $brand-warning;
    }

    &.auctionNotStarted {
        background-color: $state-info-bg;
        border-color: $brand-primary;
    }
}

.bolded {
    font-style: normal;
    font-weight: bold;
}

.italicized {
    font-style: italic;
}

.proposalSubmittedInfo {
    margin-bottom: 0;
    margin-top: 10px;
    text-align: center;
}
