@import '../../../theme/bootstrap-custom-variables';

.projectListTopNav {
    margin-bottom: 7px !important;
    margin-top: 7px;
}

.projectListTopNavLabel {
    color: $color-fg-secondary !important;
    font-size: 0.9em !important;
}

.projectListTopNavIcon {
    color: #337ab7;
}

.paginationAndExportContainer {
    display: flex;
    flex-direction: row-reverse;
}

.sortAndFilterContainer {
    align-items: center;
    display: flex;
    gap: 5px;
}

.sortByFieldForm {
    display: inline-block;
    margin-bottom: 0 !important;
    text-align: left;
    width: 170px;
}

.sortByDirectionDropdown {
    height: 30;
}

.statusFilterContainer {
    float: left;
    vertical-align: top;
}

.statusFilterLabel {
    color: #337ab7;
}

.statusFilterForm {
    display: inline-block;
    margin-bottom: 0 !important;
    text-align: left;
    width: 139px;
}

@media (max-width: $screen-md-max) {
    .paginationAndExportContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 3px;
        margin-right: 0;
    }
}

.sortOptionsAndDirectionsContainer {
    display: flex;
}
