@import '~@opengov/capital-style/scss/tokens';

.templateProjectHUDContainer {
    background: $color-gray-50;
    font-family: $base-font;
    margin-bottom: $unit-1;

    h1,
    h4 {
        font-family: $base-font;
    }

    h1 {
        font-size: $font-size-xxlarge;
        line-height: $line-height-large;
    }

    h4 {
        font-size: $font-size-default;
        line-height: $line-height-default;
    }

    .breadcrumbs {
        cursor: pointer;

        i {
            margin-right: $unit-half;
            vertical-align: text-top;
        }

        a {
            text-decoration: none;
        }
    }

    .subtitlesContainer {
        align-items: center;
        display: flex;

        .subtitle {
            color: $color-gray-700;
            font-size: $font-size-small;
            font-weight: $font-weight-regular;
            line-height: $line-height-minimum;
            margin-right: $unit-3;

            span {
                font-weight: $font-weight-semi-bold;
            }
        }

        .blockSubtitle {
            margin-bottom: $unit-2;
        }
    }

    .tableSubstitlesContainer {
        display: block;
    }

    .actionsContainer {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        .action {
            margin-right: $unit-1;
        }

        .lastAction {
            margin-right: 0;
        }
    }

    .mobileActionsContainer {
        :global(button) {
            font-size: $font-size-small;
            padding: $unit-half $unit-1;
        }
    }

    .connectedClientsContainer {
        .connectedClients {
            color: $color-primary-brand;
            font-size: $font-size-default;
            margin-right: 0;
            padding: $unit-1 $unit-2;
            text-wrap: nowrap;

            &.negateLeftPadding {
                margin-left: -$unit-2;
            }
        }

        > div {
            margin-bottom: 0 !important;
        }
    }
}
