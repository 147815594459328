@import '../../theme/bootstrap-custom-variables';

.label {
    color: $text-muted;
}

.section {
    margin-top: 30px;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 50px;
    }
}

.sectionHeader {
    border-bottom: 1px solid $panel-default-border;
    color: $text-muted;
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 8px;
    text-align: left;
    width: 100%;
}

.toggleHeader {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.sectionItem {
    border-bottom: 1px solid $panel-default-border;
    padding: 8px 0;

    &.lastItem {
        border-bottom: 0;
    }

    ul {
        margin-bottom: 0;
    }
}

@media (max-width: $screen-sm-max) {
    .label {
        margin-bottom: 3px;
    }

    .labelIcon {
        display: none;
    }
}
