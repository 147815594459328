.confirmButton {
    &.active {
        cursor: default;

        &:active,
        &:focus,
        &:hover {
            background-color: #449d44;
            outline: none;
        }
    }

    .checkBoxIcon {
        margin-right: 2px;
    }

    .checkBoxChecked {
        display: none;
    }

    &:hover {
        .checkBoxEmpty {
            display: none;
        }

        .checkBoxChecked {
            display: inline-block;
        }
    }
}

.confirmContainer {
    margin-bottom: 8px;
}

.info {
    font-size: 0.8em;
    font-style: italic;
    margin-top: 2px;
    text-align: center;
}
