@import '../../theme/bootstrap-custom-variables';

.clickableText {
    cursor: pointer;
}

.icon {
    color: #337ab7;
    padding: 0 4px 1px !important;
}

.muted {
    color: $gray-dark;
}

.retentionLabel {
    background-color: #f5f5f5;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 4px;
}
