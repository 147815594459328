.panel {
    padding: 40px;
    text-align: center;
}

.icon {
    margin-bottom: 10px;
    font-size: 30px;
}

.explanation {
    font-size: 18px;
    margin-top: 40px;
}
