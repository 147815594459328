@import '../../../../theme/bootstrap-custom-variables';

.actionButtons {
    background-color: #fff;
    border-bottom: 1px solid transparent;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: right;
}

.deleteIcon {
    margin-top: 25px;
}

@media (max-width: $screen-sm-max) {
    .deleteIcon {
        margin-top: 0;
    }
}
