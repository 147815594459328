@import '../../../../theme/bootstrap-custom-variables';

.container {
    border-top: 1px solid $panel-header-border-gray;
    margin-top: 30px;
    padding-top: 15px;
}

.saveBtn {
    border-color: $state-info-text !important;
    color: $state-info-text !important;
}

.leftButton {
    display: inline-block;
    margin-right: 20px;
}

.btnContainer {
    margin-top: 5px;
}

.errorContainer {
    color: $state-danger-text;
    margin-top: 5px;
    text-align: center;
}

@media (max-width: $screen-xs-max) {
    .btnContainer {
        margin: 10px 0;
        text-align: center;
    }

    .submitBtn {
        display: block;
        margin-left: 0;
        margin-top: 10px;
    }
}
