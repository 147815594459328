@import '../../../../../theme/bootstrap-custom-variables';

.followersContainer {
    height: 36px;
    margin-right: 130px;
}

.selectInput {
    margin-right: 34px;
}

.closeBtn {
    margin-top: 30px;

    &:hover {
        cursor: pointer;
    }
}

.fieldError {
    color: $state-danger-text;
}
