@import '~@opengov/capital-style/scss/tokens';

.approvalGroup {
    display: flex;
    flex-direction: column;
    gap: $unit-half;
}

.name {
    font-weight: $font-weight-semi-bold;
}

.approvers {
    display: flex;
    flex-direction: column;
    gap: $unit-1;

    .approver {
        display: flex;
        gap: $unit-1;

        &.approverNoPosition {
            align-items: center;
        }

        .position {
            color: $color-fg-secondary;
            font-family: $base-font-condensed;
            font-size: $font-size-small;
            line-height: $unit-2;
        }
    }
}
