.container {
    margin: 20px 0 30px;
    padding: 0 20px;
}

.text {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
}

.header {
    margin-bottom: 10px;
    margin-top: 10px;
}
