@import '../../../theme/bootstrap-custom-variables';

.projectListContainer {
    margin-bottom: 50px;
    margin-top: 10px;
}

.filterBtn {
    text-align: left !important;
}

.orgLogo {
    border: 1px solid $gray-light;
    margin-bottom: 10px;
    padding: 3px;
}

@media (min-width: $screen-lg) {
    .controlCol {
        margin: 0 auto;
        max-width: 320px;

        img {
            margin: 0 auto;
        }
    }
}
