@import '~@opengov/capital-style/scss/tokens';

.contentBlock {
    .advancedSettingsContainer {
        border-top: 1px solid $color-gray-200;

        .advancedSettingsButton {
            background-color: transparent;
            color: $color-action;
            padding: 0;
        }
    }

    :global(.action-sidebar) {
        button {
            padding: 0 !important;
        }
    }
}
