.agenda {
    margin-bottom: 15px;
}

.bold {
    font-weight: 500;
}

.calendarContainer {
    margin-top: 20px;
    min-height: 75vh;

    :global(.rbc-month-row) {
        flex-basis: 10vh;
        overflow: visible;
    }

    :global(.rbc-btn-group) {
        min-width: 176px;
    }
}

.copyToCalendar {
    border-top: solid 1px #ebebeb;
    margin-top: 20px;
    padding-top: 10px;
    text-align: center;

    > a > img {
        width: 35px;
    }
}

.eventInfoBlock {
    margin-top: 15px;
}

.location {
    margin-top: 10px;
    overflow-wrap: break-word;
}

.monthEvent {
    background-color: transparent;
    color: #000;
    cursor: pointer;
    font-size: 10px;
    margin-bottom: 10px;
    white-space: pre-line;
}

.projectStatusLabel {
    display: inline-block !important;
    margin-top: 5px;
}

.publicLabel {
    margin-right: 5px;
}

.rsvp {
    margin-top: 10px;
}

.rsvpBtn {
    width: 120px;
}

.timelineTitle {
    margin-bottom: 5px;
}

.timezone {
    color: #777;
    font-size: 10px;
    margin-left: 5px;
}
