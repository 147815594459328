@import '../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.templateTitle {
    font-size: 1.15em;
    margin-right: 8px;

    i {
        margin-right: 5px;
    }
}

.listHeader {
    padding: 0 16px 5px;
}

.statusLabel {
    font-size: 12px;
    position: relative;
    top: 5px;
    vertical-align: top !important;
}

.typeText {
    color: $text-muted;
    font-size: 1em;
    font-style: italic;
}

.sdv2Link {
    color: $color-fg-link;

    &:hover {
        color: $color-primary-focus;
    }
}
