@import '../../../theme/bootstrap-custom-variables';

$insert-text: $state-success-text;
$insert-border: $state-success-text;
$insert-background: #e6ffed;
$delete-text: $state-danger-text;
$delete-border: $state-danger-text;
$delete-background: #ffeef0;

.revisionDiffContainer {
    margin-top: 10px;

    :global(ins) {
        background-color: $insert-background; /* light green */
        color: $insert-text;
        font-size: 100%;
        text-decoration: none;
    }

    :global(.selectedRevision) {
        border-color: $insert-border;
    }

    :global(del) {
        background-color: $delete-background; /* light red */
        color: $delete-text;
        font-size: 100%;
        text-decoration: line-through;
    }

    :global(.selectedRevision) {
        border-color: $delete-border;
    }

    :global(.selectedRevision) {
        border-bottom: 2px solid;
        padding-bottom: 2px;
    }
}
