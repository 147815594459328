@import '~@opengov/capital-style/scss/tokens';

.datePicker {
    min-width: 240px;

    &.withHelpBlock {
        margin-bottom: 0 !important;
    }

    :global(.rw-cell-not-allowed:hover::before) {
        background-color: #ffe5b3;
        border: 1px solid #ccc;
        border-radius: 2px;
        content: '⃠';
        margin-left: -2px;
        margin-top: -4px;
        padding: 0 2px 4px;
        position: absolute;
        z-index: 100;
    }
}
