@import '../../../theme/bootstrap-custom-variables';

.tagTypeTitle {
    font-size: 1.2em;
    margin-bottom: 15px;
}

.tagTypeNav {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    margin-bottom: 15px;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exportButton {
    margin-bottom: 5px;
}
