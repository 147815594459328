@import '../../../theme/bootstrap-custom-variables';

.divider {
    border-top: 1px solid $panel-default-border;
    margin: 10px 0;
}

.eventDate {
    color: #777;
    font-size: 12px;
}

.loadingSpinner {
    display: block;
}

.rsvpsPopover {
    z-index: inherit !important;

    &:global(.popover.bottom) {
        margin-top: 0;
    }

    &:global(.popover.bottom > .arrow) {
        display: none;
    }
}
