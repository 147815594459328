@import '~@opengov/capital-style/scss/tokens';

.container {
    background-color: $color-warning-50;
    border: 1px solid $color-warning-500;
    display: flex;
    font-family: $base-font;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    margin-bottom: $unit-3;
    padding: $unit-2;

    i {
        color: $color-warning-800;
        margin-right: $unit-1;
    }

    .title {
        font-weight: $font-weight-medium;
    }

    .link {
        color: $color-action;
        cursor: pointer;
        font-weight: $font-weight-medium;
    }
}

.sharedSection {
    font-style: italic;
}

.small {
    font-size: 0.8em;
}
