.deleteBtn {
    margin-bottom: 5px;
    text-align: center;
}

.pricingOptions {
    border-radius: 0 !important;
    font-size: 13px;
    margin-bottom: 0 !important;

    input,
    textarea {
        font-size: 13px !important;
    }
}

.btnContainer {
    margin-top: 10px;
}

.doneBtn {
    margin-left: 10px;
}
