@import '../../../theme/bootstrap-custom-variables';

.resolvedAtDisplay {
    color: $text-muted;
    font-style: italic;
    margin-top: 8px;
}

.resolveError {
    clear: both;
    color: $state-danger-text;
    padding-top: 8px;
    text-align: left;
}
