.saveButton {
    width: 80px;
}

.saveAndCloseButton {
    width: 130px;
}

.submitText {
    font-size: 0.95em;
    margin-top: 10px;
    text-align: center;
}
