.descriptionContainer {
    margin-bottom: 10px;
    padding-top: 15px;
    text-align: left;
}

.vendorCol {
    margin-top: 28px;
}

.saveButton {
    width: 80px;
}

.saveAndCloseButton {
    width: 130px;
}

.submitText {
    font-size: 0.95em;
    margin-top: 10px;
    text-align: center;
}
