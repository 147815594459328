@import '../../../theme/bootstrap-custom-variables';

.titleContainer {
    margin-bottom: 20px;
}

.navItems {
    margin-bottom: 20px !important;
}

.counter {
    font-size: 0.9em;
    font-weight: normal;
}

.buttonContainer {
    text-align: right;
}

@media (max-width: $screen-xs-max) {
    .buttonContainer {
        margin-top: 10px;
    }
}
