.dragIcon {
    display: inline-block;
    font-size: 1.1em;
    line-height: 20px;
    margin-left: 5px;
    margin-right: 10px;
    vertical-align: top;
}

.removeButton {
    vertical-align: top;
}
