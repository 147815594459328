.titleContainer {
    margin-bottom: 20px;
}

.title {
    display: inline-block;
    margin-right: 8px;
}

.statusLabel {
    font-size: 12px;
    position: relative;
    top: 7px;
    vertical-align: top !important;
}

.connectedClients {
    font-size: 0.9em;
    margin-right: 0;
    margin-top: 5px;
}
