@import '~@opengov/capital-style/scss/tokens';

.navButtons {
    background-color: $color-white;
    border-top: 1px solid $color-fg-border-primary;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: $unit-3 $unit-7;
    z-index: 1;
}

@media (max-width: 900px) {
    .navButtons {
        padding: $unit-3 $unit-2;
    }
}

.navButton {
    align-items: center;
    display: flex;
    gap: $unit-half;
}

.nextButtonContainer {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-right: $unit-6;
}
