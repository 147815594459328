@import '~@opengov/capital-style/scss/tokens';

.container {
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    padding-top: $unit-20;
    text-align: center;

    h3 {
        color: $color-gray-700;
    }

    .icon {
        font-size: $font-size-maximum;
    }
}
