@import '~@opengov/capital-style/scss/tokens';

.navItems {
    margin-bottom: $unit-3 !important;
}

.backContainer {
    display: flex;
    margin-bottom: $unit-half;
    align-content: center;
    color: $color-gray-800;
    gap: $unit-half;
    width: fit-content;
}

.backText {
    font-size: $unit-2 -2;
    font-family: $base-font-alt;
    font-weight: 600;
    line-height: $unit-3;
    letter-spacing: 0.175px;
}
