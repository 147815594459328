@import '~@opengov/capital-style/scss/tokens';

.container {
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    h3 {
        line-height: $line-height-medium;
    }

    .info {
        color: $color-gray-700;
        font-size: $font-size-small;
        line-height: $line-height-tight;
    }

    .icon {
        color: $color-action;
        font-weight: $font-weight-medium;
    }
}
