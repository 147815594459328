@import '../../../../../theme/bootstrap-custom-variables';

.dragIcon {
    padding-left: 48px;
}

.sectionInfoContainer {
    display: flex;
}

.sectionTitle {
    width: 71.7%;

    &.isTwoColumnLayout {
        width: 50.9%;
    }
}

.textDisplay {
    font-weight: 700;
}

@media (max-width: $screen-md-max) {
    .sectionTitle {
        width: 72.5%;

        &.isTwoColumnLayout {
            width: 51.5%;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .sectionInfoContainer {
        flex-wrap: wrap;
    }

    .sectionTitle {
        width: 100%;
    }
}

@media (max-width: $screen-xs-max) {
    .sectionInfoContainer {
        flex-wrap: wrap;
    }

    .sectionTitle {
        width: 100%;

        &.isTwoColumnLayout {
            width: 100%;
        }
    }
}
