@import '../../theme/bootstrap-custom-variables';

.listText,
.listIcon {
    color: $govlist-blue;
}

.listIcon {
    margin-right: 5px;
}

.listItem .checked,
.listItem:hover .open {
    display: none;
}

.listItem:hover .checked {
    display: inline-block;
}

.listItem:focus,
.listItem:active {
    outline: none;
}
