@import '../../../theme/bootstrap-custom-variables';

.container {
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
}

.text {
    font-style: italic;
}

.endDateWrapper {
    margin: 0 auto;
    max-width: 382px;
    text-align: left;

    .endDateText {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    .endDateSubText {
        color: $gray-light;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 0;
    }
}
