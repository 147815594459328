@import '../../theme/bootstrap-custom-variables';

.choiceItem {
    .checked {
        display: none;
    }

    &:hover:not(.disabled) {
        .checked {
            display: inline-block;
        }

        .open {
            display: none;
        }
    }

    &:focus,
    &:active {
        outline: none;
    }
}

.multipleChoiceContainer {
    margin-bottom: 0;
}

.selectedItem {
    @extend .choiceItem;
    background-color: $state-success-bg !important;
    color: $state-success-text !important;
    font-weight: bold;

    .checked {
        display: inline-block;
    }

    .open {
        display: none;
    }
}

.choiceIcon {
    margin-right: 5px;
}
