.acceptedAt {
    line-height: 1em;
    margin-bottom: 15px;
}

.proposalViewerAgreement {
    cursor: pointer;
    display: inline-block;

    &:hover {
        .acceptedText {
            text-decoration: underline;
        }
    }
}

.verified {
    font-size: 9px;
    margin-right: 3px;
}
