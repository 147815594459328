@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    gap: $unit-3;
    justify-content: center;
    padding: $unit-7;
    text-align: center;
    width: 100%;
}

.icon {
    color: $color-gray-600;
    font-size: $unit-9;
}

.title {
    color: $color-fg-primary;
    font-size: $font-size-medium;
    font-weight: $font-weight-semi-bold;
}

.subtitle {
    color: $color-fg-secondary;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
}
