@import '../../../../theme/bootstrap-custom-variables';

.moduleSequenceContainer {
    margin-bottom: 40px;
    padding-top: 5px;
}

.moduleTitleContainer {
    background-color: transparent;
    border: 0;
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    width: 100%;
}

.moduleTitle {
    border-bottom: 1px solid $panel-default-border;
    cursor: pointer;
    font-size: 20px;
    padding: 2px 10px 5px;

    &.titleDisabled {
        color: $text-muted;
        opacity: 0.65;
    }

    &:hover .titleText {
        text-decoration: underline;
    }
}

.completeIcon {
    margin-left: 10px;
}

.moduleBodyMessage {
    padding: 16px 30px 26px;
    text-align: center;
}

.subHeading {
    color: $text-color;
    font-size: 16px;
    margin-bottom: 8px;
}

.pageButtonContainer {
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    height: 110px;
    margin: 0 10px 20px;
    padding: 5px;
    text-align: center;
    vertical-align: top;
    width: 120px;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.65;
    }

    &:hover:not(.disabled) {
        background-color: darken(#fff, 3%);
        border-color: $brand-primary;
        color: $brand-primary;
    }
}

.pageButton {
    display: inline-block;
    padding: 8px 0;
}

.primaryActionContainer {
    margin-bottom: 30px;
}

.secondaryActions {
    color: $text-color;
}

.actionItem {
    padding: 3px 0;

    .actionItemName {
        margin-left: 5px;
    }

    &:hover {
        cursor: pointer;

        .actionItemName {
            text-decoration: underline;
        }
    }

    &.disabledActionItem {
        color: $text-muted;

        &:hover {
            cursor: not-allowed;

            .actionItemName {
                text-decoration: none;
            }
        }
    }
}

.moduleTitle,
.pageButtonContainer,
.actionItem {
    // Prevent double clicks from highlighting the element
    user-select: none;
}

.completedBtnColor {
    color: $gray-dark;
}

@media (max-width: $screen-xs-max) {
    .pagesContainer {
        text-align: center;
    }
}

.upgradeSection {
    margin-bottom: 8px;
}

.moduleMangerContainer {
    padding-top: 5px;
}

.moduleTitleIcon {
    font-size: 12px;
    vertical-align: top;

    .iconText {
        color: #fff;
    }
}
