@import '~@opengov/capital-style/scss/tokens';

.scanningViewport {
    border: $border-gray-200;
    border-top: 0;
    border-radius: $border-radius-default 0 0 $border-radius-default;
    width: 100%;
    height: calc(100vh - 300px);

    :global(.dvs-viewer .dvs-viewer) {
        canvas {
            padding: 0 $unit-1;
        }
    }
}

:global(.dynamsoft-dialog) {
    display: none;
}

:global(.dynamsoft-backdrop) {
    display: none;
    z-index: -10000;
}

.scanningDialogContainer {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    font-family: $base-font-alt;
    padding: 0 $unit-2;
    gap: 0;
    flex-direction: row;

    @media (max-width: 900px) {
        padding: 0 $unit-2 $unit-2;
    }

    h3 {
        color: $color-fg-primary;
        font-size: $font-size-medium;
        font-weight: $font-weight-medium;
    }

    h4 {
        font-size: $font-size-default;
        font-weight: $font-weight-semi-bold;
    }

    input {
        font-size: $font-size-default;
    }

    :global(.form-group) label {
        font-family: $base-font-alt;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
    }

    :global(.form-control) {
        height: $unit-4;
    }

    :global(.react-select__control) {
        font-family: $base-font-alt;
        min-height: $unit-4;
    }

    .formField {
        color: $color-fg-primary;
        display: flex;
        flex-direction: column;
        font-size: $unit-2;
        gap: $unit-1;
        margin-bottom: $unit-2;
    }

    .formSection {
        margin-bottom: $unit-3;

        .heading {
            margin-bottom: 10px;
        }

        .subText {
            color: $color-fg-secondary;
        }

        label,
        .label,
        :global(.control-label) {
            color: $color-fg-primary;
            font-size: $unit-2;
            font-weight: $font-weight-medium;
        }

        :global(.help-block) {
            color: $color-fg-secondary;
            font-size: $unit-2;
        }
    }

    .formSection:last-of-type {
        margin-bottom: 0;
        margin-top: 0;
    }

    .workingMessage {
        margin: $unit-10;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $unit-2;
    }

    .leftPanel {
        width: 100%;

        .editionBarContainer {
            display: flex;
            border: $border-gray-200;
            border-bottom: 0;
            border-radius: $border-radius-default 0 0 0;

            .fakeThumbnailTop {
                flex: 0 0 $unit-25;
            }

            .editionBar {
                flex-grow: 1;
                background-color: $color-white;
                border-bottom: $border-gray-200;
                border-left: $border-gray-200;
                display: flex;
                padding: $unit-1;
                justify-content: space-between;

                .zoomSelect {
                    > div:nth-of-type(1) {
                        padding-left: $unit-1;
                    }
                }

                .editionBarLeft {
                    display: flex;
                    gap: $unit-2;

                    .flipButton > svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .hiddenScanningViewport {
            display: none;
        }

        .emptyStateViewport {
            display: flex;
            border: $border-gray-200;
            border-top: 0;
            border-radius: $border-radius-default 0 0 $border-radius-default;
            width: 100%;
            height: calc(100vh - 300px);

            .fakeThumbnail {
                flex: 0 0 $unit-25;
            }

            .noImagesMessage {
                svg {
                    font-size: $unit-5;
                }

                padding: $unit-10;
                text-align: center;
                color: $color-fg-secondary;
                height: 100%;
                width: 100%;
                border-left: $border-gray-200;
            }
        }
    }

    .rightPanel {
        border: $border-gray-200;
        border-left: 0;
        border-radius: 0 $border-radius-default $border-radius-default 0;
        width: $unit-50;
        display: flex;
        flex-direction: column;
        align-self: stretch;

        :global(.MuiAccordionSummary-root) {
            max-height: $unit-4;
        }

        :global(.MuiAccordion-root:first-of-type .MuiAccordionSummary-root) {
            border-top: 0;
        }
    }
}

.setupInstructionsContainer {
    color: $color-fg-secondary;
    padding: $unit-3;

    h3 {
        margin-bottom: $unit-3;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: $unit-1;

    .setupInstructionsIcons {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-bottom: $unit-3;

        svg {
            font-size: $unit-5;
        }
    }

    a:hover {
        text-decoration: none !important;
        color: $color-white !important;
    }
}

.uploadButtonContainer {
    padding-left: $unit-2;
    padding-right: $unit-2;
    display: flex;
    justify-content: space-between;
    margin-top: $unit-3;

    .rightGroup {
        display: flex;
    }
}

.noImagesMessage {
    padding: $unit-3;
    text-align: center;
    color: $color-fg-secondary;
}

.checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;

    label {
        font-weight: $font-weight-regular !important;
    }
    flex: 0 0 50%;
}

.checkboxGroup {
    display: flex;
}

.paginationBar {
    :global(.MuiTypography-root) {
        color: $color-fg-secondary;
    }

    .currentPageInput {
        width: $unit-4;
        padding: 0 !important;

        :global(.MuiInputBase-root) {
            margin: 0;
        }
    }
}
