.buttons {
    margin-top: 40px;
}

.actionButton {
    margin-right: 20px;
}

.reviseInstructions {
    margin-top: 5px;
}

.editNote {
    margin-bottom: 20px;
    margin-top: 20px;

    a:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

.status {
    clear: both;
    margin-bottom: 20px;
    margin-top: 20px;
}

.confirmationInfo {
    margin-bottom: 20px;
    margin-top: 10px;
}

.markApproved {
    margin-bottom: 30px;
}

.editFormError {
    margin-top: 20px;
}
