.revisionSearch {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    padding-top: 8px;
    text-align: right;
}

.revisionCount {
    margin-right: 10px;
}
