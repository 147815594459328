@import '~@opengov/capital-style/scss/tokens';

$screen-xs-min: 480px !default;

.backdrop {
    background-color: rgba($color-gray-1000, 0.25);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.container {
    background-color: $color-white;
    height: 100vh;
    opacity: 100%;
    overflow-y: auto;
    width: 50%;
    z-index: 1010;

    @media (max-width: 1200px) {
        width: 70%;
    }

    @media (max-width: $screen-xs-min) {
        width: 100%;
    }
}
