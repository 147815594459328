@import '~@opengov/capital-style/scss/tokens';

.requisitionsCreate {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-family: $base-font-alt;
    gap: $unit-2;
    padding: 0 $unit-7 $unit-7;

    @media (max-width: 900px) {
        padding: 0 $unit-2 $unit-2;
    }

    h3 {
        color: $color-fg-primary;
        font-size: $font-size-medium;
        font-weight: $font-weight-medium;
    }

    h4 {
        font-size: $font-size-default;
        font-weight: $font-weight-semi-bold;
    }

    input {
        font-size: $font-size-default;
    }

    :global(.form-group) label {
        font-family: $base-font-alt;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
    }

    :global(.form-control) {
        height: 32px;
    }

    :global(.react-select__control) {
        font-family: $base-font-alt;
        min-height: 32px;
    }

    .formField {
        color: $color-fg-primary;
        display: flex;
        flex-direction: column;
        font-size: $unit-2;
        gap: $unit-1;
        margin-bottom: $unit-2;
    }

    .formSection {
        margin-bottom: $unit-3;

        .heading {
            margin-bottom: 10px;
        }

        .subText {
            color: $color-fg-secondary;
        }

        label,
        .label,
        :global(.control-label) {
            color: $color-fg-primary;
            font-size: $unit-2;
            font-weight: $font-weight-medium;
        }

        :global(.help-block) {
            color: $color-fg-secondary;
            font-size: $unit-2;
        }
    }

    .formSection:last-of-type {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.formSection:last-of-type {
    margin-bottom: 0;
    margin-top: $unit-7;
}

.approvalContainer {
    align-self: stretch;
    background: $color-bg-primary;
    border: 1px solid $color-fg-border-primary;
    border-radius: $unit-half;
    display: flex;
    flex-direction: column;
    padding: $unit-3;

    @media (max-width: 600px) {
        padding: $unit-2;
    }

    .budgetYearLabel {
        color: $color-fg-secondary;
    }
}

.fullWidthLabel {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
}
