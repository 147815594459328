@import '~@opengov/capital-style/scss/tokens';

.contentBlockContainer {
    font-family: $base-font;
    margin-bottom: $unit-2;

    .contentBlock {
        background: $color-white;
        border: $border-gray-200;
        display: flex;
        padding: $unit-3;

        &:focus-within:has(:focus-visible),
        &.active {
            :global(.action-sidebar) {
                background: $color-primary-hover-light;
            }

            :global(.action-sidebar .fa) {
                color: $color-primary-brand;
            }
        }

        &.withActions {
            border-radius: 0 $unit-half $unit-half 0;
            padding: 0;
        }

        &.hasError :global(.content-block-main) {
            border: 1px solid $color-fg-error;
        }
    }

    .error {
        color: $color-fg-error;
        font-family: $base-font;
        font-size: $font-size-small;
        margin-top: $unit-half;
    }
}
