@import '~@opengov/capital-style/scss/tokens';

.searchInput {
    overflow: hidden;

    input {
        font-size: 13px;
        height: 30px;
    }
}

.contractSearchForm {
    display: flex;
    flex-direction: row;

    .searchInput {
        flex-grow: 1;
    }

    .contractSearchButton {
        margin-bottom: 15px;
        margin-left: $unit-1;
    }
}
