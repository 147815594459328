.logo {
    height: 40px;
    margin-right: 5px;
    width: 40px;
}

.vendorDataCell {
    align-items: center;
    display: inline-flex;
    height: 100%;
}

.vendorName {
    font-size: 1.05em;
    vertical-align: top;
}
