@import '../../../../theme/bootstrap-custom-variables';

.container {
    @media (min-width: 1400px) {
        width: 1340px !important;
    }
}

.summaryContainer {
    margin-top: 30px;
}

.budgetedAmount {
    padding-right: 40px;
    text-align: right;
}

.progressBar {
    margin-top: 10px;
    padding-right: 25px;
}

.infoSection {
    margin-top: 20px;
    padding-left: 30px;
}

.infoSectionText {
    margin-top: 10px;
    width: 70%;

    > span {
        text-align: left;
    }

    > strong {
        float: right;
    }
}

.summaryItem {
    border-radius: 4px;
    margin: 10px 0;
}

.auctionItemText {
    color: #337ab7;
    font-size: 16px;
}

.budgetedAmountText,
.currentTotalText {
    font-size: 24px;
    font-weight: 300;
    margin-top: 2px;
}

.budgetedAmount,
.noBidPriceText,
.noBidSupplierText {
    color: #777;
}

.listItemText,
.noBidPriceText,
.noBidSupplierText,
.priceText {
    font-size: 16px;
    word-wrap: anywhere;
}

.listItemLabel,
.priceLabel,
.vendorTotalDetails {
    color: #777;
    font-size: 12px;
}

.vendorTotalDetails {
    margin-top: 3px;
}

.bidSection {
    text-align: center;
}

.bidSection {
    border-left: 1px solid #dce0e0;
}

.emptyList {
    margin-left: 115px;
}

.summaryTitleText {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
}

.vendorRank {
    background-color: #eee;
    border-radius: 20px;
    margin-right: 15px;
    padding: 5px 9px;
}

.companyNameText {
    font-size: 16px;
    font-weight: 500;
}

.quantityLabel {
    color: $gray-base;
    font-size: 12px;
}

.numBids {
    font-weight: 500;
}

.total {
    color: #000;
    font-size: 14px;
    font-weight: 700;
}
