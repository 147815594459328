@import '~@opengov/capital-style/scss/tokens/layout';
@import '../../../theme/bootstrap-custom-variables';

.label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 10px;
}

.addSingleBtn {
    border-color: $state-info-text !important;
    color: $state-info-text !important;
}

.addMultiBtn {
    margin-right: 10px;
}

.previewContainer {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    border-top: 15px solid #d9d9d9;
    box-shadow: 0 3px 14px -4px rgba(0, 0, 0, 0.2);
    max-height: 900px;
    min-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 15px;
}

.noPreview {
    font-size: 1.2em;
    font-style: italic;
    margin-top: 30px;
}

.previewTitle {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.addQuestionButtonContainer {
    align-items: center;
    display: flex;
    margin: $unit-3 0;

    hr {
        flex-grow: 1;
    }

    button {
        margin: 0 $unit-2;
    }
}
