@import '../../../../theme/bootstrap-custom-variables';

.actionContainer {
    margin-top: 25px;
    text-align: right;
}

.editButton {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.manualEditButton {
    vertical-align: text-bottom;
}

.manualEntryName {
    font-size: 16px;
}

.manualEntryLabel {
    font-size: 10px;
    font-style: italic;
    line-height: 10px;
    padding-bottom: 4px;
}

.userSearchSelect {
    margin-bottom: 0 !important;
}

.userFormEditButtons {
    margin-top: 5px;
}

@media (max-width: $screen-xs-max) {
    .actionContainer {
        margin-top: 15px;
        text-align: center;
    }
}
