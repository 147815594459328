@import '~@opengov/capital-style/scss/tokens';

.errorContainer {
    align-items: center;
    display: flex;
    font-size: $font-size-base;
    justify-content: space-between;
    padding: $unit-2;
}

.errorMessageContainer {
    align-items: center;
    display: flex;
    gap: $unit-2;
}

.errorTitleContainer {
    align-items: center;
    display: flex;
    gap: $unit-1;
}

.errorTitle {
    color: $color-fg-primary;
    font-weight: $font-weight-medium;
}

.errorDescription {
    color: $color-fg-primary;
    font-weight: $font-weight-regular;
}

.icon {
    color: $color-fg-error;
}

.jumpToErrorBtn {
    color: $color-fg-link;
    font-size: $font-size-base;
}
