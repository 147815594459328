@import '../../theme/bootstrap-custom-variables';

.muted {
    color: $gray-dark;
}

.radio label {
    align-items: center;
    display: flex;
    font-weight: normal !important;
    justify-content: start;
}

.textField {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 5px;
    width: 88px;
}
