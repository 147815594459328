@import '../../../theme/custom-variables';
@import '../../../theme/bootstrap-custom-variables';

.titleText {
    color: $govlist-blue;
    font-size: 20px;
    margin-bottom: 35px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.templateSupportUrl {
    font-size: 18px;
    margin-bottom: 35px;
    margin-top: -30px;

    a {
        text-decoration: underline;
    }
}

.iconText {
    margin-top: 8px;
}

.iconButton {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

.iconButton:hover {
    color: $brand-primary;
}

.templateIconButton {
    @extend .iconButton;
    padding: 10px;
}

.disabledIconButton {
    @extend .iconButton;
    color: #b3b3b3;

    &:hover {
        color: #b3b3b3;
        cursor: not-allowed;
    }
}

.categoryRow {
    margin-bottom: 35px;
}

@media (min-width: $screen-sm) {
    .templateIcons {
        padding-left: 20px;
        padding-right: 20px;
    }
}
