@import '../../../theme/bootstrap-custom-variables';

$active-color: $brand-primary;

.panelClosed {
    &:hover {
        border-color: $active-color;

        .panelHeader,
        .icon {
            color: $active-color;
        }
    }

    .panelHeader {
        background: none !important;
        border-bottom: 0;
    }

    .icon {
        color: $text-muted;
    }

    .iconText {
        color: #fff;
    }

    .completeIcon {
        color: $brand-success;
    }
}

.panelOpen {
    border-color: $active-color;

    .panelHeader {
        background: $active-color !important;
        color: #fff !important;
    }

    .iconText {
        color: $active-color;
    }
}

.panelDisabled {
    background-color: #dfe3e8;

    &:hover {
        border-color: transparent;

        .panelHeader,
        .icon {
            color: $text-muted;
        }
    }
}

.panelHeader {
    @extend %prevent-double-click;
    cursor: pointer;
}

.doneButton {
    color: $active-color !important;
    padding-bottom: 3px !important;
    padding-top: 3px !important;

    &:hover {
        color: $active-color !important;
    }
}

.title {
    font-size: 18px;
    font-weight: 400;
}

.icon {
    font-size: 12px;
    margin-right: 8px;
}
