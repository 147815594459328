@import '../../../theme/bootstrap-custom-variables';
@import '../../../theme/custom-variables';

.displayOnMobile {
    @media (min-width: $navbar-breakpoint-max-width) {
        display: none !important;
    }
}

.newBadge {
    color: #f00;
    font-size: 0.75em !important;
    position: absolute;
    right: 13px;
    top: 16px;

    @media (max-width: $navbar-breakpoint-max-width) {
        left: 22px;
        top: 11px;
    }
}

.navButton {
    background-color: transparent !important;
    border-color: #fff !important;
    border-radius: 2px !important;
    font-size: 13px !important;
    margin-left: 10px;
    padding-left: 18px !important;
    padding-right: 18px !important;

    &:focus,
    &:hover {
        background-color: #fff !important;
        color: $opengov-blue !important;
        outline-color: #fff !important;
    }
}

[id='dashboards-nav-dropdown']:focus {
    @include focus-border();
    padding: 14px;
}

[id='account-nav-dropdown']:focus {
    @include focus-border();
    padding: 9px 14px !important;
}
