@import '~@opengov/capital-style/scss/tokens';

.formGroup {
    height: 38px;
}

.input {
    border: 1px solid $color-gray-400;
    box-shadow: none;
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    padding: $unit-1;

    &:hover:not(:disabled, :read-only) {
        border: 1px solid $color-gray-700;
        box-shadow: 0 0 0 1px $color-gray-700;
    }

    &:focus:not(:disabled, :read-only) {
        border: 1px solid $color-primary-selection;
        box-shadow: 0 0 0 1px $color-primary-focus;
    }

    &:global(.form-control) {
        height: 38px;
    }
}

.label {
    color: $color-gray-1000 !important;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
    margin-bottom: $unit-half;

    i {
        color: $color-gray-700 !important;
    }
}

.noMarginBottom {
    margin-bottom: 0;
}

.errorInput {
    border: 1px solid $color-fg-error;
}

.errorInput:hover {
    box-shadow: 0 0 0 1px $color-fg-error;
}

.validationError {
    color: $color-fg-error !important;
    margin-top: $unit-1 !important;
}

.addon {
    background-color: $color-white;
    padding-left: $unit-2;
    padding-right: $unit-2;
}

.characterCount {
    color: $color-gray-700;
    font-size: $font-size-minimum;
}

.labelWrapperForCharacterCount {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
}
