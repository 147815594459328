@import '../../../../../../theme/bootstrap-custom-variables';

.listItem {
    margin-bottom: 20px !important;
}

.completeListItem {
    @extend .listItem;
    background-color: lighten($state-success-bg, 8%) !important;
    border-color: $state-success-text !important;
}

.stackCircle {
    color: $input-border;
}

.successCircle {
    color: $brand-success;
}

.title {
    display: inline-block;
    font-size: 16px;
    margin-left: 10px;
    margin-top: 5px;
    vertical-align: top;
    width: 70%;
}

.documentInfo {
    margin-bottom: 10px;
}

.dropzone {
    background-color: $lightest-grey;
    border-color: $brand-primary;
    border-radius: 5px;
    border-style: dashed;
    border-width: 2px;
    padding: 15px;
    text-align: center;
    width: 100%;
}

.successDropzone {
    @extend .dropzone;
    background-color: $state-success-bg;
    border-color: $state-success-text;
}

.activeDropzone {
    @extend .dropzone;
    background-color: darken($state-success-bg, 5%);
    border-color: $state-success-text;
    border-style: solid;
}

.dropzoneText {
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
}

.uploadList {
    margin-bottom: 10px;
}

.uploadItem {
    margin-bottom: 10px;
}

.attachmentItem {
    border-top: 1px solid $panel-header-border-gray;
    padding-bottom: 8px;
    padding-top: 8px;

    &:first-child {
        border-top: 0;
    }
}

.attachmentError {
    color: $state-danger-text;
    margin-top: 3px;
}

.deleteAttachmentButton {
    color: $brand-primary !important;
    float: right;
}

.fileIcon {
    font-size: 1.1em !important;
    margin-right: 8px;
}
