@import '~@opengov/capital-style/scss/tokens';

.flags {
    padding: $unit-3 0 0;
}

.error {
    color: $color-fg-error !important;
    margin-top: $unit-1;
}
