.nav {
    margin-bottom: 10px !important;
}

.listWrapper {
    margin-bottom: 40px;
}

.listHeading {
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 18px;
    text-align: center;
}
