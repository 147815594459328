.cancelBtn {
    margin-right: 20px;
}

.errorMsg {
    margin-bottom: 10px;
}

.infoMsg {
    margin-top: 20px;
}

.buttonGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
