@import '../../theme/bootstrap-custom-variables';

$active-color: $brand-primary;

.navItems > li {
    margin-left: 0 !important;

    &:not(:last-child) {
        margin-right: 20px;
    }

    > a {
        border-bottom: 2px solid transparent;
        border-radius: 0 !important;
        color: $text-color;
        font-size: 1.1em;
        font-weight: bold;
        padding: 8px 4px !important;

        &:hover {
            background: none !important;
            color: $active-color;
        }

        &:focus-visible {
            background: none !important;
            border: 2px solid $active-color;
            border-radius: 5px !important;
            outline: none !important;
            padding: 6px 2px !important;
        }

        &:active {
            background: none !important;
            box-shadow: none;
            outline: none !important;
        }
    }

    &:global(.active) > a {
        background: none !important;
        border-bottom: 2px solid $active-color;
        color: $active-color !important;

        &:hover {
            cursor: default;
        }
    }
}
