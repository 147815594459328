@import '../../theme/bootstrap-custom-variables';

.footer {
    background-color: $white;
    box-shadow: none;
    padding: 7px;
    text-align: center;
}

.info {
    background-color: $state-info-bg;
    border-top: 1px solid $state-info-border;
}

.success {
    background-color: $state-success-bg;
    border-top: 1px solid $state-success-border;
}

.warning {
    background-color: $state-warning-bg;
    border-top: 1px solid $state-warning-border;
}
