.navItems {
    margin-bottom: 30px !important;
}

.heading {
    margin-bottom: 10px;
}

.plus {
    margin-right: 5px;
}

.button {
    margin-bottom: 10px;
}

.errorPanel {
    height: 350px;
}

.departmentBlock {
    text-align: center;
}

.deptHeader {
    margin-bottom: 15px;
}
