.item:not(:last-child) {
    margin-bottom: 10px;
}

.text {
    border: 1px solid #ddd;
    border-radius: 3px;
    max-height: 300px;
    overflow-y: scroll;
    padding: 5px 5px 0;
}

.title {
    margin-bottom: 10px;
}
