@import '../../../../theme/bootstrap-custom-variables';

.submitButton {
    margin-bottom: 20px;
    margin-top: 20px;
}

.error {
    color: $state-danger-text;
    margin-bottom: 10px;
}

.help {
    margin-bottom: 20px;
}

.sectionsStatus {
    font-size: 1.1em;
}

.sectionTitle {
    display: inline-block;
    margin-top: 4px;
}

.warningIcon {
    margin-left: 5px;
}

.checkContainer {
    float: right;
    margin-right: 3px;
}

.iconCircle {
    color: $input-border;
}

.warningMsg {
    color: $state-warning-text;
    margin-bottom: 20px;
}
