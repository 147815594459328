@import '~@opengov/capital-style/scss/tokens';

.hidden {
    display: none;
}

.subsectionTitle {
    margin-top: 50px;
}

.vendorCertifications {
    display: flex;
    flex-direction: column;
    font-family: $base-font-alt;
    font-size: $font-size-base;
    gap: $unit-4;
    margin-bottom: $unit-7;

    button {
        font-family: $base-font-alt !important;
    }

    h2 {
        font-size: $font-size-large;
    }

    h3 {
        font-size: $font-size-medium;
        font-weight: $font-weight-semi-bold;
    }

    h4 {
        font-size: $font-size-base;
        font-weight: $font-weight-semi-bold;
    }

    :global(.form-group) {
        margin: 0;

        label {
            font-family: $base-font-alt;
        }
    }

    :global(.MuiTypography-root) {
        font-family: $base-font-alt !important;
    }

    :global(.MuiTypography-body1) {
        font-size: $font-size-base !important;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $unit-half;

        .helpText {
            color: $color-fg-secondary;
        }
    }
}
