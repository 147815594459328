@import '../../../../../../theme/bootstrap-custom-variables';

.container {
    padding-bottom: 20px;
    padding-top: 20px;
}

.profilePicture {
    display: inline-block;
    margin-right: 8px;
}

.viewed {
    text-align: center;
}

.emailButton {
    margin-bottom: 6px;
}

.comment {
    margin-top: 20px;
    text-align: center;
}

.commentIcon {
    margin-left: 8px;

    &:hover {
        cursor: pointer;
    }
}

.approveButton {
    margin-right: 10px;
}

.error {
    margin-top: 20px;
    text-align: center;
}

.approver {
    word-wrap: break-word;
}

.approvalsItem {
    border-top: 1px solid $table-border-color;

    &:first-child {
        border-top: 1px solid $table-border-color;
    }
}

@media (max-width: $screen-sm-max) {
    .approver {
        margin-bottom: 10px;
        text-align: center;
    }
}

@media (max-width: $screen-xs-max) {
    .viewed {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
