@import '~@opengov/capital-style/scss/tokens';

$screen-xs-min: 480px !default;

.container {
    .banner {
        background-color: $color-error-50;
        border: 1px solid $color-fg-error;
        font-size: $font-size-default;
        line-height: $line-height-default;
        padding: $unit-2;

        .icon {
            color: $color-fg-error;
            display: inline-block;
            font-weight: $font-weight-regular;
            margin-right: $unit-1;
        }

        .title {
            color: $color-gray-1000;
            display: inline-block;
            font-weight: $font-weight-medium;
            margin-right: $unit-1;
        }

        .extraInformation {
            padding-left: $unit-3;

            @media (max-width: $screen-xs-min) {
                padding-left: 0;
            }
        }

        .description {
            color: $color-gray-1000;
            display: inline-block;
            font-weight: $font-weight-regular;

            @media (max-width: $screen-xs-min) {
                display: block;
                margin-left: $unit-3;
            }
        }

        &.warning {
            color: $color-fg-warning-large;
            background-color: $color-bg-warning-light;
            border-color: $color-fg-warning-large;

            .icon {
                color: $color-fg-warning-large;
            }
        }
    }

    &.largeIcon {
        .icon {
            float: left;
            margin-right: $unit-2;
        }

        .title {
            display: block;
            margin-right: 0;
            overflow: auto;
        }

        .description {
            display: block;
            overflow: auto;
        }
    }
}
