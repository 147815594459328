@import '../../../../theme/bootstrap-custom-variables';

.checkmark {
    color: $btn-success-bg;
    margin: 20px 0 5px;
}

.closeButton {
    margin-bottom: 15px;
    text-align: right;

    button {
        float: none;
    }
}

.logo {
    border: 1px solid $gray-light;
    margin: 0 auto;
    max-width: 200px;
    padding: 3px;
}

.notificationSettings {
    margin-top: 30px;
    text-align: left;
}
