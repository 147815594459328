@import '../../../../../theme/bootstrap-custom-variables';

.errorText {
    color: $brand-danger;
    font-size: 12px;
    font-style: italic;
}

.subText {
    color: $gray-dark;
    font-size: 12px;
    font-style: italic;
    line-height: 25px;
    white-space: normal;
}
