@import '../../../../theme/bootstrap-custom-variables';

.inputLabel {
    margin: 0 0 3px 5px;
}

.selectInput {
    display: inline-block;
    vertical-align: top;
    width: 80%;
}

.buttons {
    display: inline-block;
    float: right;
}

.submitBtn {
    text-align: right;
}

.cancelBtn {
    margin-top: 10px;
    text-align: right;
}

.errorMessage {
    margin: 5px 0 0 3px;
}

@media (max-width: $screen-xs-max) {
    .selectInput {
        display: block;
        width: 100%;
    }

    .buttons {
        display: block;
        float: none;
        margin-top: 10px;
    }

    .submitBtn {
        float: right;
    }

    .cancelBtn {
        text-align: left;
    }
}
