@import '../../theme/bootstrap-custom-variables';

.logo {
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
}

.errorWell {
    background-color: $state-danger-bg !important;
    border-color: $state-danger-border !important;
    color: #a94442;
}

.removeIcon {
    cursor: pointer;
    float: right;
}

.loginPanel {
    padding-bottom: 10px;
    padding-top: 15px;
}

.emailInput {
    margin-bottom: 20px;
}

.passwordInput {
    margin-bottom: 30px;
}

.terms {
    font-size: 0.9em;
    font-style: italic;
    text-align: center;
}

.termsLinks {
    font-weight: bold;
}

@media (min-width: $screen-lg) {
    .container {
        margin-left: auto;
        margin-right: auto;
        max-width: 560px;
    }
}

@media (min-width: $screen-sm) {
    .logo {
        margin-left: auto;
        margin-right: auto;
        max-width: 264px !important;
    }
}
