/**
 * This is a slightly modified copy of https://github.com/OpenGov/component-library/tree/main/applications/component-library/src/Button
 * until we use the React component library
 */

@import '@opengov/capital-style/scss/tokens';

/**
 * Base Button styles
 *
 * Common styles across all button types, styles, and states.
 */
%base-button {
    border: 1px solid transparent;
    border-radius: $border-radius-default;
    display: inline-block;
    font-family: $base-font;
    font-weight: $font-weight-medium;
    letter-spacing: $letter-spacing-default;
    position: relative;
    text-decoration: none;
    transition: $transition-natural;
    white-space: nowrap;

    svg {
        margin-bottom: -3px;
    }

    &.stateSelected {
        background: $color-primary-selection;
        border-color: $color-primary-medium;
        color: $color-white;

        svg {
            fill: $color-white;
        }

        &:hover {
            background: $color-primary-medium;
            color: $color-white;
        }
    }

    &:focus {
        @include focus-default;
        outline: 2px solid $color-primary-focus;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        background-color: $color-gray-200;
        border: $border-gray-300;
        box-shadow: none;
        color: $color-gray-400;
        cursor: not-allowed;

        svg {
            fill: $color-gray-400;
        }
    }
}

/**
 * Button Sizes
 */
.sizeDefault {
    font-size: $font-size-default;
    line-height: $line-height-tight;
    padding: $unit-1 $unit-2;
}

.sizeSmall {
    font-size: $font-size-small;
    line-height: $line-height-small-font;
    padding: $unit-half $unit-1;
}

.sizeLarge {
    font-size: $font-size-large;
    line-height: $line-height-default;
    padding: $unit-1 $unit-5;
}

/**
 * Button Styles
 */
.variantSecondary,
.variantSecondary-alt,
.variantText {
    @extend %base-button;
    background: $color-gray-50;
    border: $border-gray-400;
    color: $color-gray-700;

    svg {
        fill: $color-gray-700;
    }

    &:hover {
        background: $color-gray-200;
        border: $border-gray-700;
        color: $color-gray-1000;

        svg {
            fill: $color-gray-1000;
        }
    }

    &:focus {
        @include focus-default;
        outline: 2px solid $color-primary-focus;
    }

    &:active,
    &.stateLoading {
        background: $color-gray-200;
        border: $border-gray-400;
        color: $color-gray-1000;
    }

    &[disabled],
    &[disabled]:hover {
        background-color: $color-gray-200;
        border: $border-gray-300;
        box-shadow: none;
        color: $color-gray-400;
        cursor: not-allowed;
    }
}

.variantSecondary-alt {
    background: $color-white;
    border-color: $color-gray-400;
}

.variantPrimary {
    @extend %base-button;
    background: $color-primary-brand;
    border-color: transparent;
    color: $color-white;

    svg {
        fill: $color-white;
    }

    &:hover {
        background: $color-primary-medium;

        svg {
            fill: $color-white;
        }

        &:active {
            background: $color-primary-brand;
            border-color: $color-primary-medium;

            svg {
                fill: $color-white;
            }
        }

        &.stateLoading {
            background: $color-primary-brand;
            border: transparent;
            color: $color-white;
        }

        &:focus {
            @include focus-default;
        }
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus {
        background-color: $color-primary-selection-a50;
        border: $color-primary-selection-a50;
        color: $color-white;
    }
}

.variantDestructive {
    @extend %base-button;
    background: $color-fg-error;
    border: $border-error-700;
    color: $color-white;

    svg {
        fill: $color-white;
    }

    &:hover {
        background: $color-error-800;
        border: $border-error-900;
        box-shadow: none;

        svg {
            fill: $color-white;
        }
    }

    &.stateLoading {
        background: $color-fg-error;
    }

    &:active,
    &.stateSelected {
        background: $color-error-800;
        border: $border-error-900;

        svg {
            fill: $color-white;
        }

        &:hover {
            background-color: $color-error-900;
        }
    }

    &:focus {
        @include focus-default;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus {
        background-color: $color-error-300;
        border: $color-error-300;
        color: $color-white;
    }
}

.variantTertiary {
    @extend %base-button;
    background-color: transparent;
    border: 1px solid transparent;
    color: $color-gray-700;

    &:hover {
        background: $color-gray-50;
        border: $border-gray-300;
        color: $color-gray-1000;

        svg {
            fill: $color-gray-1000;
        }
    }

    svg {
        fill: $color-gray-700;
    }

    &:focus {
        @include focus-default;
    }

    &.stateSelected {
        background: $color-primary-selection;
        border: 1px solid $color-primary-focus;
        color: $color-white;

        svg {
            fill: $color-white;
        }
    }

    &:active,
    &.stateLoading {
        background: $color-gray-100;
        border-color: transparent;
        color: $color-gray-1000;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        background-color: transparent;
        border: transparent;
        box-shadow: none;
        color: $color-gray-500;
        cursor: not-allowed;

        svg {
            fill: $color-gray-500;
        }
    }
}

.variantText {
    background: none;

    &,
    &:hover,
    &:active {
        border-color: transparent;
    }

    &:active {
        background: none;
    }

    &:hover {
        background: none;
        color: $color-primary-brand;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
        background-color: transparent;
        border: transparent;
        box-shadow: none;
        color: $color-gray-500;
        cursor: not-allowed;

        svg {
            fill: $color-gray-500;
        }
    }
}

.buttonChildrenContainerVisible {
    visibility: visible;
}

.buttonChildrenContainerNotVisible {
    visibility: hidden;
}

.loadingAnimation {
    animation: simpleLoader 1.6s linear infinite;
    border: 3px solid #165cab;
    border-color: rgba(255, 255, 255, 1) rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    display: inline-block;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;

    .sizeLarge & {
        height: $unit-3;
        width: $unit-3;
    }

    .sizeDefault & {
        height: $unit-3 - $unit-half;
        width: $unit-3 - $unit-half;
    }

    .sizeSmall & {
        border-width: 2px;
        height: $unit-2 - $unit-half;
        width: $unit-2 - $unit-half;
    }
}

@keyframes simpleLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.buttonGroupWithDivider {
    align-items: center;
    display: flex;
    gap: $unit-2;
    margin: $unit-3 0;

    hr {
        flex-grow: 1;
    }
}

.noPadding {
    padding: 0;
}
