.contractReviewsList {
    margin-top: 10px;

    .contractReviewsListItem {
        border-left: 0;
        border-right: 0;
    }
}

.complaintData {
    font-weight: bold;
    margin-top: 3px;
    padding-bottom: 20px;
}

.reviewDetails {
    margin-left: 15px;
}

.stars {
    font-size: 1.2em;
}

.title {
    margin-bottom: 5px;
}
