@import '../../../../theme/bootstrap-custom-variables';

.title {
    margin: 10px 0;
    text-align: center;
}

.helpIcon {
    color: $brand-primary;
    cursor: pointer;
    font-size: 16px !important;
    margin-top: 6px;
    margin-left: 6px;
    vertical-align: top;
}

.viewOnlyTitle {
    margin: 10px 0 40px;
    text-align: center;
}

.approverForm {
    margin-bottom: 40px !important;
}

.approvalInfo {
    margin-bottom: 40px;
    text-align: center;
}

.instructions {
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center;
}

.reviewWell {
    text-align: center;
}

.reviewWellInfo {
    margin-bottom: 15px;
    margin-top: 10px;
}

.newReviewStepButton {
    margin-bottom: 15px;
    text-align: right;
}

.publishBtn {
    margin-top: 40px;
}

.publishInfo {
    margin-top: 15px;
    text-align: center;
}

.noReviewStepsInfo {
    font-size: 20px;
    text-align: center;
}
