@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    gap: $unit-2;
}

.formContainer {
    display: flex;
    gap: $unit-2;
}

.searchButton {
    height: 34px;
    margin-top: 25px;
}

.title {
    font-size: $font-size-medium;
    font-weight: $font-weight-semi-bold;
}
