@import '~@opengov/capital-style/scss/tokens';

.hidden {
    display: none;
}

.container {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    padding: $unit-3;
}

.borderBottom {
    border-bottom: 1px solid $color-gray-200;
}

.timeline {
    background-color: $color-white;
    border-bottom: 1px solid $color-gray-200;
    padding-bottom: $unit-3;
    padding-top: $unit-3;

    :global(.form-group) {
        margin-bottom: 0;
    }
}

.timelineWithInputs {
    padding-bottom: $unit-1;
}

.noPadding {
    padding: 0 !important;
}

.removeLocationButton {
    @extend .noPadding;
    font-size: $font-size-base !important;
}

.removeButton {
    @extend .noPadding;

    color: $color-error-700 !important;
}

.legend {
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: $line-height-small-font;
}

.label {
    @extend .legend;
    color: $color-gray-1000;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
}

.action {
    color: $color-action !important;
}

.inputsContainer {
    :global(.form-group) {
        margin-bottom: $unit-2;
    }
}

.marginlessFormGroup {
    :global(.form-group) {
        margin-bottom: 0;
    }
}

.dropdown {
    :global(.dropdown-menu) {
        li > a {
            padding-bottom: $unit-half;
            padding-top: $unit-half;
        }
    }
}

.agendaContainer {
    :global(.attachment-uploader-component-container) {
        margin-bottom: 0 !important;
    }

    .dropzoneContainer {
        padding: 0;
    }

    .dropzone {
        background-color: $color-white;
        border: 0;
        padding: 0;

        &:hover {
            background-color: $color-white;
        }
    }
}
