@import '~@opengov/capital-style/scss/tokens';

.container {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    color: $color-action;
    cursor: pointer;
    font-family: $base-font;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .section {
        padding: calc($unit-1 + $unit-half) $unit-2 $unit-1;

        &:not(:last-of-type) {
            border-bottom: 1px solid $color-gray-200;
        }

        .sectionNumber {
            font-weight: $font-weight-medium;
            margin-right: $unit-half;
        }

        .manualNumber {
            font-size: $font-size-small;
            font-weight: $font-weight-medium;
        }
    }
}
