@import '~@opengov/capital-style/scss/tokens/colors';

.dropzoneContainer {
    padding: 8px;
}

.invalidFileBorder {
    border-color: $color-error-600;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
}
