.userProfileList {
    display: inline-block;
}

.reviewGroupPositionUsersList {
    margin-top: 8px;
}

.reviewGroupPositionUser {
    margin-bottom: 15px;
}

.reviewGroupPositionUserProfile {
    margin-right: 8px;
    vertical-align: top;
}

.reviewGroupPositionUserInfo {
    display: inline-block;
}

.reviewGroupPositionUserAssignButton {
    line-height: 1;
}
