@import '../../../../theme/bootstrap-custom-variables';

.summaryHeader {
    margin-bottom: 15px;
}

.summaryValue {
    font-size: 24px;
    font-weight: 300;
}

.mobileLabel {
    margin-top: 30px;
}

.countdownTimer {
    @media (max-width: $screen-xs-min) {
        float: left;
    }

    @media (min-width: $screen-lg) {
        float: right;
    }
}
