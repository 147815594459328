.vendorNameCell {
    border: 0;
    cursor: pointer;
    padding-left: 0 !important;

    &:hover {
        .vendorName {
            text-decoration: underline;
        }
    }
}

.panelTitle {
    line-height: 30px;
    // To ensure proper spacing if the grid has no title
    min-height: 30px;
    text-align: right;
}

.exportButtons {
    line-height: 25px;
    margin-left: 8px;
}
