@import '../../../../../../theme/bootstrap-custom-variables';

.reportButtonContainer {
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    height: 110px;
    margin: 0 10px 20px;
    padding: 5px;
    text-align: center;
    vertical-align: top;
    width: 120px;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.65;
    }

    &:hover:not(.disabled) {
        background-color: darken(#fff, 3%);
        border-color: $brand-primary;
        color: $brand-primary;
    }
}

.reportButton {
    display: inline-block;
    padding: 8px 0;
}

.reportButtonContainer {
    // Prevent double clicks from highlighting the element
    user-select: none;
}
