@import '~@opengov/capital-style/scss/tokens';

.projectIdContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.projectIdField {
    flex-grow: 3;
}

.autoNumberButton {
    display: flex;
    flex-direction: column-reverse;
    margin-left: $unit-1;
    margin-bottom: 15px;
}
