.inviteDropdownButton {
    border: 0 !important;
    padding: 0 !important;
}

.statusCol {
    overflow: visible !important;
}

.container {
    min-height: 500px;
}

.progressBar {
    padding: 10px 0;
}

.legend {
    padding-top: 10px;
}

.dot {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 4px;
    width: 10px;
}

.accepted {
    background-color: #337ab7;
}

.pending {
    background-color: #ed9c28;
}

.vendorListContainer {
    :global(.ag-cell-wrapper) {
        line-height: initial !important;
    }
}
