.dropdown {
    vertical-align: top !important;

    button {
        border-radius: 50%;
        font-size: 0.8em;
        height: 25px;
        padding: 2px;
        width: 25px;
    }
}
