.container {
    margin-bottom: 5px;
}

.icon {
    margin-right: 4px;

    &:hover {
        text-decoration: none;
    }
}
