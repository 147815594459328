.vendorStats {
    margin-bottom: 30px;
}

.viewCount {
    cursor: pointer;
    font-size: 2em;
}

.noDataTitle {
    font-style: italic;
    margin-top: 60px;
    text-align: center;
}

.printContainer {
    margin-bottom: 5px;
}

.emailAuditsContainer {
    margin-bottom: 30px;

    ul {
        font-size: 0.8em;
    }
}

.tabsNav {
    margin-bottom: 20px !important;
    margin-top: 5px;
}

.vendorFilter {
    margin-top: 5px;
    text-align: left;
}
