@import '../../../theme/bootstrap-custom-variables';

.pagination {
    margin: 0 !important;

    li > a,
    li > span {
        font-size: 12px !important;
        padding: 5px 10px !important;
    }
}

.paginationContainer {
    display: flex;
}

.paginationText {
    color: $text-muted;
    display: inline-block;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 7px;
    vertical-align: top;

    @media (max-width: $screen-xs-max) {
        display: none;
    }
}
