.userProfilePicture {
    float: left;
    margin-right: 7px;
    vertical-align: top;
}

.listGroupItem {
    align-items: center;
    display: flex;
    height: 50px;
}

.listGroupItemTitle {
    font-size: 18px;
}

.actions {
    width: 150px;
}
