@import '~@opengov/capital-style/scss/tokens';

.container {
    min-height: 500px;
}

.nav {
    margin-bottom: 20px !important;
}

.paddingForCells {
    padding: 0 24px !important;
    padding-top: 5px !important;
}

.paddingForHeaders {
    padding: 0 24px !important;
}

:global(.MuiTypography-root) {
    font-family: $base-font-alt !important;
}
