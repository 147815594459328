@import '../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.backContainer {
    height: $unit-5;
    width: fit-content !important;
}

.arrowContainer {
    display: flex;
    cursor: pointer;
    align-content: center;
}

.back {
    color: $opengov-blue;
    cursor: pointer;
    margin-left: $unit-3;
    width: $unit-2 + 3px !important;
    height: $unit-3;
    margin-top: -3px;
}

.backText {
    color: $opengov-blue;
    margin-left: $unit-1;
    font-size: $unit-half * 3;
}
