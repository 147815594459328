@import '../../../theme/bootstrap-custom-variables';

.starContainer {
    &.selectable {
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
    }

    &.unhighlighted {
        color: $panel-default-border;
    }
}
