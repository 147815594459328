@import '../../theme/bootstrap-custom-variables';

.logo {
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
}

.panel {
    padding: 30px 0;
}

.goBack {
    display: inline-block;
    margin-top: 10px;
}
