.editButton {
    padding: 1px 5px !important;
}

.container {
    dt {
        text-overflow: inherit;
        white-space: normal;
    }
}

.datePicker {
    width: 240px;
}
