.sectionTitle {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.versionName {
    font-weight: 500;
    margin-bottom: 3px;
}

.userPicture {
    display: inline-block;
    float: left;
    margin: 3px 15px 10px 5px;
    vertical-align: top;
}
