.checkboxCol {
    float: left;
    margin-top: 3px;
    padding-right: 10px;
}

.header {
    margin-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}

.projectInfoCol {
    overflow: hidden;
}

.selectAllButton {
    margin-bottom: 5px;
    margin-left: 17px;
    padding: 3px !important;

    i {
        margin-right: 9px;
    }
}
