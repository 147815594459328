@import '../../../../theme/bootstrap-custom-variables';

.acceptanceContainer {
    font-size: 0.85em;
    margin-top: 5px;

    &:hover {
        cursor: pointer;

        .acceptedText {
            text-decoration: underline;
        }
    }

    .verified {
        font-size: 9px;
    }
}

.userRow {
    border-top: 1px solid $table-border-color;
    padding: 12px 30px;

    &:last-child {
        border-bottom: 1px solid $table-border-color;
    }
}

.profilePicture {
    display: inline-block;
    margin-right: 16px;
    vertical-align: top;
}

.userInfo {
    display: inline-block;
}

.userName {
    font-size: 1.2em;
}

.removeBtn {
    color: $state-danger-text !important;
    padding: 0 !important;

    &:hover {
        color: lighten($state-danger-text, 5%) !important;
    }
}
