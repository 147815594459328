@import '../../../../theme/bootstrap-custom-variables';

.quickSearchInput {
    margin-bottom: 0 !important;
    overflow: hidden;

    input {
        font-size: 13px;
        height: 30px;
    }
}

.checkboxFormGroup {
    margin-bottom: 0 !important;
}

.searchPriceTablesCheckbox {
    color: $color-fg-secondary !important;
    font-size: 0.9em !important;
    margin: 4px 0 0 !important;
    padding-left: 7px;

    label {
        align-items: center;
        display: inline-flex;
    }

    input[type='checkbox'] {
        margin-top: -1px !important; /* Visually align label and checkbox */
    }
}

.tutorialContainer {
    text-align: right;

    button {
        padding: 5px 0 !important;
    }
}
