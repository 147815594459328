@import '~@opengov/capital-style/scss/tokens';

.container {
    margin-top: 40px;
}

.logoContainer {
    margin-bottom: 20px;
    text-align: center;
}

.saveButton {
    margin-bottom: 10px;
    text-align: center;

    button {
        width: 120px;
    }
}

.showBillingAddressCheckbox {
    margin-bottom: 10px;
}

.title {
    color: $color-gray-1000;
    font-weight: 700;
}

.requiredText {
    color: $color-gray-1000;
    margin-bottom: 20px;
}
