@import '../../../../../theme/bootstrap-custom-variables';

.buttonBar {
    margin-bottom: 10px;
}

.evaluationPhaseScorecard {
    clear: both;
    margin-bottom: 40px;
}

.evaluationPhaseTitle {
    float: left;
    margin: 5px 0;
}

.infoRow {
    margin-bottom: 3px;
}

.listItem:hover {
    background-color: $well-bg;
    cursor: pointer;
}

.progressBar {
    margin-bottom: 5px !important;
}

.skinnyCol {
    padding-left: 0 !important;
}

.submitButton {
    width: 120px;
}

.submitWarning {
    text-align: center;
}

.tableHeader {
    margin-bottom: 5px;
    padding: 0 15px;
}

@media (max-width: $screen-sm-max) {
    .buttonCol {
        text-align: center;
    }

    .skinnyCol {
        padding-left: 15px !important;
    }

    .submitWarning {
        margin-bottom: 5px;
    }

    .tableHeader {
        display: none;
    }
}
