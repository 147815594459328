.container {
    margin: 20px 0 30px;
    padding: 0 20px;
    text-align: left;
}

.text {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
}
