@import '~@opengov/capital-style/scss/tokens';

.container {
    font-family: $base-font-alt;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .header {
        background-color: $color-white;
        border-bottom: 1px solid $color-gray-200;
        border-top: 1px solid $color-gray-200;

        :global(.section-header-title-only-container) {
            padding-left: $unit-3;
            padding-right: $unit-3;
        }
    }

    .content {
        padding: $unit-4 $unit-3;

        .contentBox {
            background-color: $color-white;
            padding: $unit-3;
        }
    }

    .footer {
        background-color: $color-white;
        border-top: 1px solid $color-gray-200;
        padding-bottom: $unit-2;
        padding-top: $unit-2;
    }
}
