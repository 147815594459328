.buttonsContainer {
    display: block;
    margin-bottom: 20px;
    overflow: hidden;
}

.itemDescriptionContainer {
    padding: 0 10px;
}

.newCommentContainer {
    margin-bottom: 40px;
}

.resolvedLink {
    cursor: pointer;
}

.commentsContainer {
    clear: both;
}
