@import '../../../theme/bootstrap-custom-variables';

.commentContainer {
    padding: 10px;
}

.commentPicture {
    float: left;
    width: 40px;
}

.commentContent {
    margin-left: 40px;
}

.selectedComment {
    background-color: $light-highlight-yellow;
    border-bottom: 1px solid $table-border-color;
    border-top: 1px solid $table-border-color;
}
