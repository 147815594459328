@import '../../../../../theme/bootstrap-custom-variables';

.addBtn {
    display: inline-block;
    margin-left: 10px;
    margin-top: 15px;
}

.checkbox {
    @extend %prevent-double-click;
}
