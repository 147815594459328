.scoringCriterium {
    margin-bottom: 10px;
    text-align: center;
}

.descriptionContainer {
    margin-bottom: 10px;
    margin-top: 3px;
}

.toggleButton {
    width: 170px;
}

.toggleButtonContainer {
    margin-bottom: 10px;
    text-align: right;
}

.unsubmitText {
    font-size: 0.95em;
    margin-top: 10px;
    text-align: center;
}
