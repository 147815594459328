@import '~@opengov/capital-style/scss/tokens';

.scrollPaper {
    align-items: baseline !important;
}

.container {
    color: $color-gray-1000;

    *:not(:global(.fa)) {
        font-family: $base-font-alt !important;
    }

    .tableHeaderText {
        color: $color-gray-700;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        line-height: $line-height-tight;
    }

    .fontWeightRegular {
        font-weight: $font-weight-regular;
    }

    .tableRow {
        align-items: center;
        border-top: 1px solid $color-gray-200;
        display: flex;
        padding-bottom: $unit-2;
        padding-top: $unit-2;
    }

    .tableCount {
        color: $color-gray-700;
        font-size: $font-size-medium;
        font-weight: $font-weight-regular;
        line-height: $line-height-default;
    }

    .vendorSearchTypeLabel,
    label {
        @extend .tableHeaderText;

        color: $color-gray-1000;
    }

    .totalVendorsLabel {
        color: $color-gray-1000;
        font-size: $font-size-default;
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
    }

    .totalVendorsCount {
        color: $color-gray-1000;
        font-size: $font-size-xxlarge;
        font-weight: $font-weight-medium;
        line-height: $line-height-large;
    }

    .previouslyNotified {
        color: $color-gray-700;
        font-size: $font-size-minimum;
        font-weight: $font-weight-medium;
        line-height: $line-height-minimum;

        &:hover {
            color: $color-gray-700;
            text-decoration: none;
        }

        svg {
            vertical-align: middle;
        }
    }

    .readOnlyLocationChip {
        background-color: $color-gray-50;
        border: 1px solid $color-gray-200;
        border-radius: $unit-half;
        color: $color-gray-700;
        display: inline-block;
        font-size: $font-size-minimum;
        margin-right: $unit-1;
        margin-top: $unit-1;
        padding: $unit-half $unit-half + $unit-quarter;
    }

    .errorDark {
        color: $color-bg-error-dark;

        &:hover {
            color: $color-bg-error-dark;
        }

        svg {
            fill: $color-bg-error-dark;
        }
    }

    .addMoreButton {
        border-color: $color-action;
        color: $color-action;

        &:hover {
            background-color: $color-white;
            border-color: $color-action;
            color: $color-action-1000;
        }

        svg {
            fill: $color-action;
        }
    }

    .header {
        background-color: $color-gray-50;
        border-bottom: 1px solid $color-gray-300;

        .totalsContainer {
            .totalsBox {
                border-left: 1px solid $color-action-800;
                text-align: center;

                .total {
                    font-size: $font-size-xxxlarge;
                    font-weight: $font-weight-semi-bold;
                    line-height: $line-height-large;
                }
            }
        }

        .categoriesButton {
            background-color: transparent !important;
            border: 0 !important;
            box-shadow: none;
            font-weight: $font-weight-regular;

            :global(.caret) {
                display: none;
            }

            .categoriesMoreText {
                font-weight: $font-weight-medium;
            }
        }

        .categoriesDropdownInfo {
            a {
                align-items: center;
                display: flex;
                padding-left: $unit-2;
            }
        }
    }

    .footer {
        border-top: 1px solid $color-gray-200;
    }
}
