.user {
    margin-bottom: 5px;
}

.reviewBlock {
    margin-bottom: 5px;
}

.contractComplaintsList {
    margin-top: 10px;
}

.noCompaints {
    margin-top: 4px;
}
