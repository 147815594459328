@import '~@opengov/capital-style/scss/tokens';

.container {
    background: $color-gray-200;
    border-left: 1px solid $color-gray-200;
    width: 34px;

    .actionGroup {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: $unit-1 0;

        &.hidden {
            display: none;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $color-white;
        }

        button {
            padding: 0;

            &:hover {
                background: none;
                border-color: transparent;
            }
        }
    }

    :global(.fa) {
        color: $color-gray-700;
        font-size: 1rem;
        line-height: $line-height-default;
    }
}
