@import '../../../theme/bootstrap-custom-variables';

.deptHead {
    font-weight: bold;
    padding: 0 5px 5px !important;
}

.deptCol {
    padding: 0 5px !important;
    white-space: pre-wrap;
}

.table {
    padding: 0 15px;
}

.deptListRow {
    background-color: #fff;
    border-bottom: 1px solid $table-border-color;
    padding-bottom: 15px;
    padding-top: 15px;

    &:first-child {
        border-top: 1px solid $table-border-color;
    }

    &:hover {
        background-color: $pale-gold;
    }
}

.deptText {
    margin-top: 8px;
}

.exportButton {
    margin-bottom: 10px;
    text-align: right;

    button + button {
        margin-left: 8px;
    }
}

@media (max-width: $screen-sm-max) {
    .deptHead {
        display: none;
    }

    .deptCol {
        margin-bottom: 5px;
    }

    .deptText {
        margin-top: 0;
    }
}
