.navbarContainer {
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 901;
}

.navbarToggle {
    border-radius: 3px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding: 5px 10px !important;
}

.navBtn {
    margin-left: 15px;
    margin-right: 15px;
}

.popover {
    left: -70px !important;
    width: 230px;

    :global(.arrow) {
        left: 50% !important;
    }
}
