@import '../../../theme/bootstrap-custom-variables';

.listItem {
    border-top: 1px solid $table-border-color;
    padding: 15px 30px;

    &:last-child {
        border-bottom: 1px solid $table-border-color;
    }
}

.title {
    font-size: 16px;
}

.requiredText {
    float: right;
    margin-left: 10px;
    width: 90px;
}

@media (max-width: $screen-xs-max) {
    .requiredText {
        float: none;
        margin-left: 0;
        margin-top: 2px;
    }
}
