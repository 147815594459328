.banner {
    background: #fff;
    margin-bottom: 20px;
    margin-top: -20px;
    text-align: center;
}

@media (min-width: 0) and (max-width: 768px) {
    .leftImage {
        margin: 0 auto;
    }

    .centerImage {
        display: none;
    }

    .rightImage {
        display: none;
    }
}

@media (min-width: 768px) {
    .leftImage {
        float: left;
    }

    .centerImage {
        margin: 0 auto;
    }

    .rightImage {
        float: right;
    }
}
