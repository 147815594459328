@import '../../../../theme/bootstrap-custom-variables';

.addBtn {
    color: $state-info-text !important;
    padding: 0 !important;

    &:hover {
        color: lighten($state-info-text, 5%) !important;
    }
}

.controlButtons {
    display: inline-block;
}

.criteria {
    .editingControls {
        display: inline-block;
    }
}

.dragIcon {
    font-size: 1.5em;
    margin: 5px 0;
}

.editingControls {
    display: none;
}

.questionLogicIcon {
    margin-top: 5px;

    i {
        font-size: 1.2em;
        padding: 3px 0;
    }
}

.removeBtn {
    color: $state-danger-text !important;
    margin-top: 24px;
    padding: 0 !important;

    &:hover {
        color: lighten($state-danger-text, 5%) !important;
    }
}

.multiItem:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
