.answer {
    margin-top: 5px;
}

.container {
    min-height: 250px;
}

.noSubscriptions {
    font-size: 1.2em;
    font-style: italic;
    margin-top: 30px;
    text-align: center;
}

.questionIcon {
    float: left;
}

.questionText {
    margin-top: 10px;
    overflow: hidden;
}
