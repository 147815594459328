@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    gap: $unit-half;

    .toggle {
        font-size: $unit-2;
        margin-left: auto;
        width: $unit-2;
    }

    .stepNameIconContainer {
        align-items: center;
        border-radius: 50%;
        color: $color-white;
        display: flex;
        font-size: $font-size-small;
        height: 18px;
        justify-content: center;
        line-height: $line-height-minimum;
        min-width: 18px;
    }

    .stepName {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: $unit-1;
    }

    .stepStatus {
        font-size: $font-size-minimum;
        font-weight: $font-weight-regular;
        line-height: $unit-2;
        padding-left: $unit-3;
    }
}
