@import '../../../theme/bootstrap-custom-variables';

.threadContainer {
    border: 1px solid $table-border-color;
    margin-bottom: 50px;
}

.threadComments {
    padding-bottom: 10px;
    padding-top: 10px;
}

.resolvedThread {
    background-color: $background-gray;
}
