@import '~@opengov/capital-style/scss/tokens';

.noBottomMargin {
    margin-bottom: 0 !important;
}

.openGovStyle {
    background-color: $color-in-progress-50;
    height: $unit-3;

    :global(.progress-bar) {
        font-family: $base-font;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        padding-top: $unit-half;
    }
}

.footerLabel {
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-default;
    font-weight: $font-weight-medium;
    margin-top: $unit-half;
}
