.passwordInput {
    margin-bottom: 10px !important;

    :global(.help-block) {
        margin-bottom: -5px !important;
    }
}

.passwordInput2 {
    margin-top: 20px;
}

.progressBar {
    height: 4px !important;
    margin-bottom: 2px !important;
}

.helpText {
    font-size: 12px;
    padding-bottom: 100px;
}

.showPasswordToggle {
    font-size: 12px;
}

.formError {
    color: #b23e24;
    margin-bottom: 20px;
}

.requiredNotice {
    margin-bottom: 20px;
}
