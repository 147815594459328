.templateRow {
    .editButton {
        display: none;
        float: right;
    }

    &:hover .editButton {
        display: inline-block;
    }
}

.projectTypeLabel {
    text-transform: capitalize;

    &.purchase {
        background-color: #003c81;
    }

    &.contract {
        background-color: #3c763d;
    }

    &.addendum {
        background-color: #8a6d3b;
    }

    &.other {
        background-color: #a94442;
    }
}
