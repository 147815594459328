.body {
    font-size: 12px;
}

.email {
    font-style: italic;
}

.fileIcon {
    margin-right: 5px;
}

.fullName {
    font-weight: bold;
}

.header {
    font-weight: bold;
    padding: 0 16px 5px;
}

.option {
    text-align: middle;
}
