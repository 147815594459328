.actionButtons {
    margin-bottom: 15px;
}

.addendumForm {
    margin-top: 10px;
}

.draftAddendumsTitle {
    margin-bottom: 15px;
    text-align: center;
}
