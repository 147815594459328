.subTitle {
    margin-bottom: 40px;
    margin-top: 8px;
    text-align: center;
}

.title {
    font-size: 18px;
    text-align: center;
}

.loginLinkContainer {
    margin-top: 10px;
    text-align: center;
}
