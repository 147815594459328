@import '../../../../theme/custom-variables';

.checklistCreateModal {
    color: $govlist-blue;
}

.checklistCreateModalContainer {
    font-size: 14px;
    padding-top: 20px;
    text-align: center;
}
