@import '../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.questionLogicIcon {
    margin-top: 5px;

    i {
        font-size: 1.2em;
        padding: 3px 0;
    }
}

.questionLogic {
    color: $state-info-text;
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 5px;
}

.openGovStyle {
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-base;
    font-style: normal;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
}
