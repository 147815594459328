@import '../../../../../../../theme/bootstrap-custom-variables';

.conditionalSection {
    margin-bottom: 5px;
    margin-top: 3px;
}

@media (max-width: $screen-xs-max) {
    .infoColumn:not(:last-child) {
        margin-bottom: 10px;
    }
}
