@import '../../../../theme/bootstrap-custom-variables';

.navContainer {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    margin-top: 30px;
    width: 100%;

    /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
    .navItem {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

    @media print {
        border: 0;
        border-radius: 0;
        margin-bottom: 0;
    }
}

.container {
    @media (min-width: 1400px) {
        width: 1340px !important;
    }
}

.lineItemDetailsContainer {
    margin-top: 30px;
}

.lineItemDetailsListItem {
    border-radius: 4px;
    margin-top: 10px;
}

.infoSection {
    color: #777;
}

.infoSectionText {
    margin-top: 5px;

    > span {
        text-align: left;
    }

    > strong {
        float: right;
    }
}

.listItemInfoText {
    color: #777;

    > span {
        text-align: left;
    }
}

.lowestTotalText,
.lowestUnitPriceText {
    font-size: 24px;
}

.lowestTotalSection {
    @media (min-width: $screen-lg-min) {
        border-right: 1px solid #dce0e0;
    }
}

.lowestUnitPriceSection {
    border-right: 1px solid #dce0e0;
}

.priceItemListHeader {
    margin-bottom: 15px;
    margin-top: 30px;
}

.listItemLabel,
.priceLabel {
    color: #777;
    font-size: 12px;
}

.listItemDateAndBidSection {
    @media (min-width: $screen-sm-min) {
        text-align: right;
    }
}

.companyNameText,
.priceText {
    font-size: 16px;
}

.quantityText {
    color: #777;
    margin-top: 3px;
}

.timeline {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
}

.vendorRank {
    background-color: #eee;
    border-radius: 20px;
    margin-right: 10px;
    padding: 5px 9px;
}

.noBidRank {
    margin-right: 36px;
}

.noBidText {
    color: #777;
    font-size: 16px;
    font-weight: 400;
}

.showMoreButtonContainer {
    margin-top: 4px;
}
