.disabledButtonTooltip {
    pointer-events: none;
}

.zeroPadding {
    border: 0 !important;
    padding: 0 !important;

    &:focus {
        outline: none !important;
    }
}
