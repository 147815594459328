@import '~@opengov/capital-style/scss/tokens';

.horizontalLabel {
    margin-top: 8px;
}

.remove {
    float: right;
    margin-left: 8px;

    i {
        color: #337ab7;
        cursor: pointer;
    }
}

.timezone {
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 35px;
}

.timezoneContainer {
    margin-bottom: 20px !important;
}

.openGovHelpBlock {
    color: $color-error-700 !important;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
}
