@import '../../../../theme/bootstrap-custom-variables';

.imageContainer {
    background-color: $panel-default-border;
    background-size: cover;
    border: 2px solid $panel-default-border;
    border-radius: 10%;
    display: inline-block;
    height: 130px;
    width: 130px;
}
