@import '~@opengov/capital-style/scss/tokens';

.firstSuggestionList {
    margin-bottom: 40px;
}

.initButton {
    padding-top: 40px;
}

.pricingTables {
    margin-bottom: 50px;
}

.viewButton {
    width: 170px;
}

.viewButtonContainer {
    margin-bottom: 8px;
}

.fileUploadDivider {
    margin-top: 12px;
}

.exampleFileLink {
    margin-top: 20px;
    text-align: center;
}

.fileUploadError {
    margin-top: 6px;
}

// sdv2 stuff
.container {
    font-family: $base-font;

    .toolbar {
        align-items: center;
        border-bottom: 1px solid $color-gray-200;
        display: flex;
        margin-left: -$unit-3;
        margin-right: -$unit-3;
        margin-top: -$unit-1;
        padding-bottom: $unit-2;
        padding-left: $unit-3;
        padding-right: $unit-3;
    }

    .legend {
        font-family: $base-font !important;
    }
}

.instructions {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-small;
    line-height: $line-height-default;
    padding: $unit-3;

    p {
        margin-bottom: 0;
    }
}

.attachmentsContainer {
    background-color: $color-gray-50;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .dropzone {
        background-color: $color-gray-50;
        border: 2px dashed $color-gray-600;
        border-radius: 0;
        padding: $unit-2;

        &:focus {
            background-color: $color-gray-50;
            border: 2px dashed $color-gray-600;
        }

        &:hover {
            background-color: $color-gray-100;
            border: 2px dashed $color-gray-600;
        }
    }

    .dropzoneContainer {
        border: 1px solid $color-gray-200;
        border-radius: $unit-half;
    }

    .dropzoneContent {
        color: $color-gray-700;
        text-align: center;

        .link {
            color: $color-action;
        }
    }
}
