@import '~@opengov/capital-style/scss/tokens';

.row {
    background-color: $color-bg-secondary;
    border: 1px solid $color-fg-border-primary;
    border-bottom: 0;
    display: flex;
    gap: $unit-3;
    padding: $unit-1;

    &:last-of-type {
        border-bottom: 1px solid $color-fg-border-primary;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        gap: $unit-2;

        &.buttons {
            background-color: transparent;
            border: 0;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .unstyledButton {
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
    }

    .unstyledButton:hover {
        background: none;
    }

    .emptyBlock {
        width: 100px;

        @media (max-width: 600px) {
            display: none;
        }
    }

    .splitAccountButtonContainer {
        flex: 1 0 0;
    }

    .remainingAmount {
        align-items: center;
        display: flex;
        font-size: $font-size-small;
        gap: $unit-half;
        justify-content: flex-end;
        width: 200px;

        @media (max-width: 600px) {
            display: none;
        }

        .boldText {
            font-weight: $font-weight-semi-bold;

            .redText {
                color: $color-fg-error;
            }
        }
    }
}
