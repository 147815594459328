.adminName {
    display: inline-block;
    margin-left: 10px;
}

.link {
    display: inline-block;
}

.userProfilePicture {
    vertical-align: top;
}
