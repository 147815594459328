.totalCol {
    font-weight: bold;
    text-align: center;
}

.selectableCell:hover {
    color: #337ab7;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.criteriaScore {
    @extend .selectableCell;
    text-align: center;

    &:hover {
        text-decoration: none;
    }
}
