.modalBody {
    min-height: 200px;
}

.reportName {
    margin: 0 10px 15px;
}

.backButtonContainer {
    margin-top: 20px;
}
