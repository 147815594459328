@import '~@opengov/capital-style/scss/tokens';

.container {
    background-color: $color-gray-50;
    border: 1px solid $color-gray-200;
    color: $color-gray-700;
    font-family: $base-font;
    line-height: $line-height-default;
    margin-top: $unit-2;
    padding-left: $unit-2;
    padding-right: $unit-2;
    padding-top: $unit-2;

    .header {
        padding-bottom: $unit-2;
        padding-left: $unit-2;

        button {
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
            line-height: $line-height-default;
            text-align: left;
            width: 100%;

            i {
                font-size: $font-size-small;
            }

            &:hover {
                color: $color-gray-700;
            }
        }
    }

    .collapsableHeader {
        cursor: pointer;
        padding-left: 0;
    }

    .sectionsContainer {
        display: none;
        padding-left: $unit-2;

        .sectionContainer {
            margin-bottom: $unit-2;
        }

        .subheader {
            font-size: $font-size-small;
            font-weight: $font-weight-medium;
            line-height: $line-height-minimum;
            margin-bottom: 0;
        }

        .subheaderHelp {
            font-size: $font-size-small;
            font-weight: $font-weight-regular;
            line-height: $line-height-default;
            margin-bottom: $unit-1;
        }

        .sectionType {
            margin-bottom: $unit-1;
            margin-top: $unit-half;

            button {
                align-items: center;
                background-color: $color-white;
                border: 1px solid $color-gray-400;
                display: flex;
                font-size: $font-size-base;
                font-weight: $font-weight-medium;
                line-height: $line-height-default;
                padding: $unit-1 $unit-2;
                text-align: left;
                width: 100%;

                .sectionTypeTitle {
                    flex: 5;
                }

                .sectionTypeActions {
                    flex: 1;
                    text-align: right;
                }
            }
        }

        .cancelContainer {
            margin-bottom: $unit-3;
            margin-top: $unit-3;
        }
    }

    .sectionContainerCollapsable {
        padding-left: calc($unit-2 + $unit-half);
    }

    .visible {
        display: block;
    }
}
