.label {
    margin-right: 8px;
    margin-top: 8px;
    vertical-align: top;
}

.selectFormClass {
    display: inline-block;
    text-align: left;
    width: 130px;
}
