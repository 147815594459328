@import '~@opengov/capital-style/scss/tokens';

.success {
    background-color: $color-bg-success-dark;
}

.royal {
    background-color: $color-in-progress-600;
}

.danger {
    background-color: $color-fg-error;
}

.muted {
    background-color: $color-fg-secondary;
}
