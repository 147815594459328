@import '../../../theme/bootstrap-custom-variables';

.blockContainer {
    margin-bottom: 5px;
    text-align: right;
}

.counter {
    color: $brand-primary;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
}
