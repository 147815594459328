@import '../../theme/bootstrap-custom-variables';

.addButton {
    width: 60px;
}

.categorySearchLabels label {
    font-size: 0.85em;
    line-height: 0.85em;
}

.categorySetSelect {
    margin-bottom: 0 !important;
}

.categorySelectSearchContainer {
    padding-left: 0 !important;
}

.leafIconContainer {
    display: inline-block;
    text-align: right;
    width: 15px;
}

.listItem {
    &.listItemNoPadding {
        padding: 0;
    }

    &:hover {
        background-color: $list-group-hover-bg;
        cursor: pointer;

        &.listItemSelected {
            background-color: #c4e3f3;

            .addButton {
                cursor: default;
            }
        }

        &.leafItemSelected {
            cursor: default;
        }
    }

    .listItemButton {
        border: 0;
        padding: 10px 15px;
        text-align: left;
        width: 100%;
    }

    .listItemText {
        overflow: hidden;
        padding-right: 70px;
        white-space: break-spaces;
    }
}

.listItemRightIcons {
    position: absolute;
    right: 10px;
    top: 10px;
}

.selectAllButton {
    margin-right: 3px;
    padding: 3px !important;
}

.selectedTitle {
    margin-left: 5px;
}

.selectedCategories {
    margin-bottom: 15px;
}

.selectedContainer {
    margin-bottom: 10px;
}

.selectedCode {
    border: 1px solid #000;
    display: inline-block;
    margin-right: 10px;
}

.selectedCodeText {
    border-right: 1px solid #000;
    display: inline-block;
    padding: 10px;
}

.removeSelected {
    padding: 10px;

    &:hover {
        cursor: pointer;
    }
}

.icon:hover {
    font-size: 1.2em;
}

.selectButton {
    float: right;
    margin-bottom: 10px;
    margin-left: 10px;

    button {
        width: 110px;
    }
}

.toggleMode {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.checkIcon {
    margin-right: 5px;
}

.nextArrow {
    margin-left: 5px;
}

.editIcon {
    margin-left: 10px;
}

.searchSelect {
    margin-bottom: 0 !important;
}

.viewAllButton {
    margin-top: 8px;
}

.multiSelectValueContainer {
    background-color: rgba(0, 126, 255, 0.08);
    border: 1px solid rgba(0, 126, 255, 0.24);
    border-radius: 2px;
    color: #007eff;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1.4;
    margin-left: 5px;
    margin-top: 5px;
    vertical-align: top;
}

.multiSelectValueLabel {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    cursor: default;
    display: inline-block;
    padding: 2px 5px;
    vertical-align: middle;
}

.multiSelectValueSetName {
    font-size: 11px;
    line-height: 8px;
}

.multiSelectValueIcon {
    @extend %prevent-double-click;
    border-bottom-right-radius: 2px;
    border-left: 1px solid rgba(0, 126, 255, 0.24);
    border-top-right-radius: 2px;
    color: #007eff;
    cursor: pointer;
    display: inline-block;
    padding: 6px 5px;
    vertical-align: middle;

    &:hover,
    &:focus {
        border-left: 1px solid rgba(0, 126, 255, 0.24);
        color: #007eff;
        text-decoration: none;
    }
}

.selectedItemCode {
    background-color: rgba(0, 126, 255, 0.08);
    border: 1px solid rgba(0, 126, 255, 0.24);
    border-radius: 2px;
    color: #007eff;
    display: inline-block;
    font-size: 0.85em;
    line-height: 1.2;
    margin-right: 5px;
    padding: 2px 0;
    text-align: center;
    width: 60px;
}

.showMoreCodeSetsHelp {
    margin-top: 8px;
}

.stackedSelectSearchContainer {
    margin-top: 10px;
    padding-left: 15px !important;
}

@media (max-width: $screen-xs-max) {
    .categorySelectSearchContainer {
        margin-top: 10px;
        padding-left: 15px !important;
    }
}

.categoryList {
    margin: 0;
    padding: 0;
}

.advancedCategoryView {
    :global(.list-group) {
        margin-bottom: 0;
    }
}
