@import '~@opengov/capital-style/scss/tokens';

.attachments {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    padding: $unit-3;

    .heading {
        color: $color-gray-700;
        font-family: $base-font;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        line-height: $line-height-default;
        margin-bottom: $unit-2;

        h3 {
            color: $color-gray-1000;
            line-height: $line-height-medium;
        }
    }
}
