@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    gap: $unit-1;
}

.format {
    color: $color-fg-secondary;
    font-family: Benton Sans Condensed;
    font-size: $font-size-minimum;
    font-weight: $font-weight-regular;
    text-align: right;
}

.dateRange {
    display: flex;
    gap: $unit-1;
}

.dateTypeContainer {
    display: flex;
    width: 100%;
    gap: $unit-half;
}

.datePicker {
    margin-bottom: 0;
}

.datePicker div div span {
    // This is a hack to override the default styles of the date picker and remove the "calendar" icon
    display: none;
}

.dateTypeSelect {
    margin-bottom: 0;
    width: 30%;
}

.datePickerSelect {
    font-weight: $font-weight-medium;
    margin-bottom: 0;
}

.oneDatePicker {
    font-weight: $font-weight-medium;
    margin-bottom: 0;
    width: 70%;
}
