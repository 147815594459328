@import '~@opengov/capital-style/scss/tokens';

.imageIcon {
    margin-right: 10px;
    max-height: 30px;
    width: 30px;
}

.fileIcon {
    margin: 0 10px;
    width: 30px;
}

.removeIcon {
    border: 0 !important;
    margin-right: 14px;
    padding: 0 !important;
}

.editIcon {
    border: 0 !important;
    margin-right: 20px;
    padding: 0 !important;
}

.actionIconFormCol {
    margin-top: 7px;
}

.questionLogicIcon {
    float: right;
    margin-left: 8px;
}

.dragIcon {
    margin-right: 15px;
}

.attachmentContainer {
    border-top: 1px solid $color-gray-200;
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-small;
    line-height: $line-height-default;
    margin-bottom: $unit-half;
    padding-bottom: $unit-1;
    padding-top: $unit-1;

    .link {
        color: $color-action;
        font-size: $font-size-default;

        i {
            margin-right: $unit-half;
        }
    }

    .automationsButton {
        i,
        button {
            color: $color-gray-700 !important;
            font-weight: $font-weight-medium;

            &:hover {
                border: 0;
                border-radius: 0;
                text-decoration: none;
            }
        }
    }
}

.attachmentFormContainer {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: $unit-half;
    font-family: $base-font;
    font-size: $font-size-small;
    line-height: $line-height-default;
    margin-bottom: $unit-2;
    padding: calc($unit-1 + $unit-half);

    .closeButton {
        color: $color-gray-700;

        &:hover {
            color: $color-gray-700;
        }
    }

    .removeButton {
        padding: 0;
    }
}
