.flexRow {
    display: flex;
    min-height: 70px;
}

.selfCenter {
    align-self: center;
}

.noMargin {
    margin-bottom: 0;
}

.input {
    max-width: 50px;
    text-align: center;
}

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
