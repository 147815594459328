@import '../../../../../../../theme/bootstrap-custom-variables';

.addTitleBtnContainer {
    margin-top: 5px;
    text-align: center;
}

.description {
    color: $text-muted;
    font-size: 13px;
}

.title {
    font-size: 1.3em;
}

.titleContainer {
    margin-bottom: 2px;
}
