@import '../../theme/bootstrap-custom-variables';

.addendaNav {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    margin-bottom: 20px;
    // Fixes off by one error that can cause sticky to not display right border
    margin-right: 1px;

    li:global(.active) > a {
        background-color: $well-bg !important;
        color: inherit !important;

        > :global(.badge) {
            background-color: $badge-bg !important;
            color: $badge-color !important;
        }
    }
}

.container {
    min-height: 500px;
}

.divider {
    border-bottom: 1px solid $panel-default-border;
    margin: 40px 0;
}
