.container {
    margin-bottom: 30px;
}

.listItem {
    font-size: 1.05em;
    word-wrap: break-word;
}

.iconContainer {
    display: inline-block;
    float: left;
    margin-right: 5px;
    margin-top: 2px;
}

.fileContainer {
    margin-left: 8px;
}

.fileDate {
    font-size: 1em;
    font-style: italic;
}

.attachmentSectionTitle {
    font-weight: normal;
    margin: 8px 10px;
}
