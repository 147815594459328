@import '../../../../theme/bootstrap-custom-variables';

.userCol {
    width: 60%;

    > div {
        margin-bottom: 0 !important;
    }
}

.permissionCol {
    width: 18%;

    > div {
        margin-bottom: 0 !important;
    }
}

.actionCol {
    width: 22%;
}

.inviteBtn {
    vertical-align: top;
    width: 74px;
}

.removeBtn {
    color: $state-danger-text;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    top: 3px;
}
