@import '../../theme/bootstrap-custom-variables';

.buttonGroup {
    margin-bottom: 5px;
}

@media (max-width: $screen-md-max) {
    .buttonGroup {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
}

.titleContainer {
    display: inline-block;
    font-size: 1.2em;
    padding-bottom: 5px;
    padding-top: 3px;

    &.collapsible:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    i {
        padding-right: 5px;
    }
}

.tableContainer {
    max-width: 100%;
    overflow-x: auto;
}

.buttonsContainer {
    display: flex;
}
