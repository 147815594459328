.toggle {
    font-weight: 700 !important;
}

.createdBy {
    margin-bottom: 15px;
}

.noZIndex {
    z-index: 0 !important; // !important is needed to override the react-bootstrap form-control class that sets a z-index of 2
}
