.vendorName:hover {
    cursor: pointer;
    text-decoration: underline;
}

.criteriaDescription {
    margin-bottom: -20px;
    margin-top: 20px;
    text-align: center;
}

.criteriaScore {
    margin-top: 20px;
    padding: 3px;
    text-align: center;
}

.totalScore {
    @extend .criteriaScore;
    font-weight: bold;
}
