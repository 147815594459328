@import '../../theme/bootstrap-custom-variables';

.logo {
    height: 60px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.panel {
    padding: 30px 0;
}

.pageTitle {
    font-size: $font-size-h3;
    margin-bottom: 30px;
    margin-top: -20px;
    text-align: center;
}

.buttonBlock {
    margin: 0 auto;
    width: 195px;
}

.topHeader {
    margin-bottom: 30px;
    text-align: center;
}

.titleFontSize {
    font-size: $font-size-h2;
}

.subtitleFontSize {
    font-size: $font-size-h4;
}

.subHeader {
    color: $gray-dark;
    margin-bottom: 40px;
    text-align: center;
}

.subText {
    color: $gray-dark;
    margin-bottom: 10px;
    text-align: center;
}

.startButton {
    font-size: 22px !important;
    padding: 10px 16px !important;
}

.activateButton {
    @extend .startButton;
    margin-bottom: 10px !important;
    width: 200px;
}
