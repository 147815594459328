@import '../../../theme/bootstrap-custom-variables';

.commentContainer {
    color: $text-muted;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: normal;
    min-width: 34px;
}

.commentRight {
    float: right;
    margin-left: 5px;
}

.commentContainer:hover {
    color: $brand-info;
}

.icon {
    font-size: 18px !important;
}

.hasComments {
    color: lighten($brand-primary, 5%);
    display: inline-block !important;
}

.noUnderline {
    &:hover {
        text-decoration: none;
    }

    &:focus {
        color: $brand-info;
        text-decoration: none;
    }
}
