@import '../../../../theme/custom-variables';
@import '../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.projectCreateModal {
    color: $govlist-blue;
}

.projectCreateModalContainer {
    font-size: 14px;
    padding-top: 20px;
    text-align: center;
}

.projectCreateIcons {
    padding-bottom: 20px;
}

.additionalRow {
    margin-top: 40px;
}

.titleText {
    color: $govlist-blue;
    font-size: 20px;
    margin-bottom: 35px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.categoriesInfoText {
    color: $text-muted;
    font-size: 18px;
    font-style: italic;
    margin-top: 5px;
}

.confirmTitle {
    @extend .titleText;
    font-weight: bold;
    margin-bottom: 25px;
}

.iconText {
    margin-top: 8px;
}

.iconButton {
    background-color: inherit;
    border: 0;
    box-shadow: none;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    outline: none;
    vertical-align: top;

    &:hover {
        @extend .iconButton;
        color: $brand-primary;
    }

    &:focus:not(:focus-visible) {
        @extend .iconButton;
        color: inherit;
    }

    &:focus-visible {
        @extend .iconButton;
        color: $brand-primary;
        outline-color: #000;
        outline-style: solid;
        outline-width: 4px;
    }
}

.disabledIconButton {
    @extend .iconButton;
    color: #b3b3b3;

    &:hover {
        color: #b3b3b3;
        cursor: not-allowed;
    }
}

.backBtn:focus,
.backBtn:active {
    outline: none;
}

.confirmationContainer {
    dl {
        margin-bottom: 30px;
        text-align: left;
    }

    dt {
        margin-bottom: 3px;
    }

    dd {
        margin-bottom: 10px;
    }
}

.backBtnContainer {
    text-align: left;
}

.backBtn,
.backBtn:hover,
.backBtn:active,
.backBtn:focus {
    color: $govlist-blue !important;
}

.closeBtn {
    @extend .backBtn;
}

.loadingContainer {
    display: block;
    margin-top: 25px;
    text-align: center;
}

.loadingSpinner {
    margin-bottom: 30px;
}

.loadingBody {
    height: 155px;
    position: relative;
}

.loadingReviewGroupsSpinner {
    display: flex;
    min-height: 0 !important;
    padding-top: 0 !important;
}

.libraryBtn {
    margin-top: 20px;
}

.integrationAlert {
    margin-bottom: 0 !important;
    padding: 10px !important;
    text-align: center;
}

@media (min-width: $screen-sm) {
    .projectCreateModalContainer {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.reviewGroupsContainer {
    display: flex;
    flex-direction: column;
    gap: $unit-4;
}

.reviewGroup {
    font-size: $unit-2;
}

@media (min-width: $screen-sm) {
    .reviewGroupIcons {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.iconButton {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

.iconButton:hover {
    color: $brand-primary;
}

.reviewGroupIconButton {
    @extend .iconButton;
    padding: 10px;
}

.iconText {
    margin-top: $unit-1;
}

.requisitionLoadingStatus {
    margin-top: 10px;
}
