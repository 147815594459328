@import '~@opengov/capital-style/scss/tokens';

.vendorCertificationSelfReportingForm {
    gap: $unit-2;

    :global(.form-group) {
        margin: 0;
    }

    :global(.checkbox) {
        margin-bottom: 4px;
        margin-top: 4px;
    }

    .icon {
        color: $color-fg-secondary;
    }
}
