@import '../../../theme/bootstrap-custom-variables';

.createButton {
    margin-bottom: 10px;
    text-align: right;
}

.listHeaders {
    font-weight: bold;
    margin-bottom: 5px;
    padding: 0 15px;
}

.departmentsColumn {
    padding-right: 0 !important;
}

.approversColumn {
    padding-left: 0 !important;
}

.arrowIcon {
    margin-top: 8px;
    text-align: center;
}

.linkButton {
    padding: 5px !important;
}

.departmentDisplayItem {
    margin-top: 3px;
}

.navItems {
    margin-bottom: 20px !important;
}

.approvalStepNumberLabel {
    background-color: $color-bg-tertiary;
    border-radius: 4px;
    font-weight: 700;
    margin-right: 10px;
    padding: 5px 9px;
}

.approvalStepRow {
    margin-top: 50px;
}

.dragIcon {
    margin-left: 4px;
}

.addBtn {
    color: $state-info-text !important;
    padding: 0 !important;

    &:hover {
        color: lighten($state-info-text, 5%) !important;
    }
}

.removeBtn {
    color: $state-danger-text !important;
    padding: 0 !important;

    &:hover {
        color: lighten($state-danger-text, 5%) !important;
    }
}

@media (max-width: $screen-xs-max) {
    .departmentsColumn,
    .approversColumn {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .workflowItem > div {
        margin-bottom: 8px;
        text-align: center;
    }
}

@media (min-width: $screen-sm-min) {
    .reviewStep {
        display: flex;
    }
}
