.header {
    margin-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}

.selectAllButton {
    margin-bottom: 5px;
    margin-left: 17px;
    padding: 3px !important;

    i {
        margin-right: 9px;
    }
}

.warningText {
    font-size: 16px;
    margin-bottom: 30px;
    padding: 0 20px;
}
