@import '~@opengov/capital-style/scss/tokens';

.panelTitle {
    line-height: 30px;
    // To ensure proper spacing if the grid has no title
    min-height: 30px;
    text-align: right;
}

.exportButtons {
    display: flex;
    gap: $unit-1;
    justify-content: flex-end;
    line-height: 25px;
    margin-left: 8px;
}
