.container {
    margin-bottom: 50px;
}

.disabledFormText {
    font-style: italic;
    margin-bottom: 10px;
}

.title {
    margin-bottom: 10px;
}

.variableName {
    display: inline-block;
    float: left;
    width: 130px;
}

.variableExplainer {
    margin-bottom: 5px;
    overflow: hidden;
}
