.exportButton {
    margin-bottom: 10px;
    text-align: right;

    button + button {
        margin-left: 8px;
    }
}

.deleteButton {
    padding: 1px !important;
}
