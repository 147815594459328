.notification {
    text-align: center;
    width: 100%;
}

.alert {
    margin-bottom: 20px !important;
    padding: 5px 0 !important;
}

.userRequestAlert {
    margin-top: -20px;
}

.connectionAlert {
    left: 50%;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    position: fixed;
    text-align: center;
    transform: translateX(-50%);
    z-index: 10000;
}

:global(.orderedLists) {
    ol {
        list-style-type: var(--olFirstLevel, upper-alpha);

        > li > ol {
            list-style-type: var(--olSecondLevel, decimal);

            > li > ol {
                list-style-type: var(--olThirdLevel, lower-alpha);

                > li > ol {
                    list-style-type: var(--olFourthLevel, lower-roman);

                    > li > ol {
                        list-style-type: var(--olFifthLevel, upper-roman);
                    }
                }
            }
        }
    }
}
