.agreementsTable {
    margin-top: 20px;
}

.proposalViewerAgreementInfo {
    font-style: italic;
    margin-bottom: 40px;
    margin-top: 10px;
    text-align: center;
}

.noProposals {
    margin: 40px 0;
    text-align: center;
}

.noProposalsHeader {
    font-size: 22px;
    margin-bottom: 20px;
}

.noProposalsText {
    font-size: 16px;
}

.noProposalsAdditionalText {
    font-size: 16px;
    margin-top: 5px;
}

.noProposalsButton {
    margin-top: 20px;
}

.bidSealedInfo {
    margin-top: 20px;
    text-align: center;
}
