.forgotPassword {
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 10px;
    color: #293fa1;
}
