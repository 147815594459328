@import '~@opengov/capital-style/scss/tokens';

$between-padding: 4px;
$between-font-size: 11px;

.removeFilterButton {
    font-size: 14px;
    padding: 0 !important;
}

.formContainer {
    font-size: 12px;
    margin-bottom: 5px;
}

.betweenStart {
    font-size: $between-font-size;
    padding-right: $between-padding !important;
}

.betweenEnd {
    font-size: $between-font-size;
    padding-left: $between-padding !important;
}

.filtersList {
    padding: $unit-half;
}

.filtersFormContainers {
    font-size: 12px;
}
