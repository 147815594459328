.criteriaTitle:hover {
    cursor: pointer;
    text-decoration: underline;
}

.criteriaDescription {
    font-style: italic;
    margin-top: 10px;
}

.criteriaScore {
    margin-top: 20px;
    padding: 3px;
    text-align: center;
}
