@import '../../../theme/bootstrap-custom-variables';

.followersContainer {
    background-color: $background-gray !important;
    border-top: 1px solid $table-border-color;
    padding: 10px;
}

.followersForm {
    margin-top: 8px;
}
