@import '~@opengov/capital-style/scss/tokens';

@mixin shared-styles($bg-color) {
    background-color: $bg-color !important;
    border-bottom: 0 !important;
    padding-bottom: $unit-1 !important;
    padding-top: $unit-1 !important;
}

.warning {
    @include shared-styles(transparent);
}

.error {
    @include shared-styles($color-bg-error-light);
}
