@import '../../theme/bootstrap-custom-variables';

.editIcon {
    border-radius: 50%;
    color: $brand-primary;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    max-width: 30px;
    position: absolute;
    right: 0;
    top: 2px;
    z-index: 900;

    &:hover {
        color: $brand-info;
    }

    &.disabled,
    &.disabled:hover {
        color: $text-muted;
        cursor: not-allowed;
    }
}
