.headerRow {
    margin-bottom: 10px;
}

.headerCol {
    font-weight: bold;
    text-align: center;
}

.rowItem {
    margin-bottom: 40px;
}
