@import '../../../theme/bootstrap-custom-variables';

.help {
    margin-bottom: 20px;
    margin-left: 2px;
    margin-top: -8px !important;
}

.departmentBlock {
    margin-bottom: 10px;
}

.avatar {
    margin-bottom: 10px;
    text-align: center;
}

.formError {
    color: #b23e24;
    margin-bottom: 20px;
}

.requiredNotice {
    margin-bottom: 20px;
}
