@import '../../../../theme/bootstrap-custom-variables';

.warningCircle {
    color: $brand-warning;
    margin-left: 5px;
}

.exportButtonDropdown :focus-visible {
    outline: auto;
}

.dividerLine {
    margin: 8px 20px;
}
