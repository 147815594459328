@import '../../theme/bootstrap-custom-variables';

.container {
    color: $govlist-blue;
    text-align: center;
}

.listItem {
    color: $govlist-blue !important;
    font-size: 1.1em;

    &:focus,
    &:active {
        outline: none;
    }
}

.titleText {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.icon {
    margin-right: 10px;
}

.helpIcon {
    color: $govlist-blue !important;
    margin-right: 10px;
}

.logo {
    margin-left: 5px;
    max-width: 100%;
    vertical-align: top;
    width: 20px;
}

.integrationContainer {
    font-size: 0.8em;
    font-style: italic;
}

.optionTitle {
    display: inline-block;
}
