.bidSectionForm {
    padding-top: 20px;
}

.bidSectionContainer {
    display: flex;
    justify-content: start;
}

.bidFormInput {
    width: 130px;
}

.listItemLabel {
    margin-bottom: 8px;
}

.quantityLabel {
    color: #777;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 8px;
    margin-top: 31px;
}

.totalLabel {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.lowestBidSet,
.lowestBidReached {
    font-size: 13px;
    margin-left: 10px;
}

.lowestBidSet {
    color: #777;
}

.lowestBidReached {
    color: #b23e24;
}

.changeButton {
    padding-top: 2px;
}
