@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    font-size: $unit-2;
    gap: $unit-half;
    line-height: $line-height-tight;
    padding: $unit-2 0;

    + .container {
        border-top: 1px solid $color-fg-border-primary;
    }

    .heading {
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-minimum;
    }

    .information {
        color: $color-fg-secondary;
        font-size: $font-size-small;
    }

    .icon {
        margin-right: $unit-half;
    }
}
