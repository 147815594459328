.notification {
    height: 60px;
    margin-bottom: 5px;
    margin-top: -20px;
    text-align: center;
    width: 100%;
}

.alert {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
}
