@import '../../../../theme/bootstrap-custom-variables';

.iconContainer {
    padding-right: 0 !important;
    text-align: right;
}

.imageIcon {
    margin-top: 2px;
    max-height: 30px;
    max-width: 30px;
}

.fileIcon {
    margin-top: 8px;
}

.removeBtn {
    color: $state-danger-text !important;
    margin-right: 12px;
    padding: 0 !important;

    &:hover {
        color: lighten($state-danger-text, 10%) !important;
    }
}

.progressBar {
    height: 12px;
    margin-bottom: 10px !important;
    margin-top: 3px;
}

.errorText {
    color: $state-danger-text;
    margin-bottom: 10px;
    margin-top: -10px;
    text-align: right;
}

.loadingContainer {
    height: 49px;
}

.appendixSelectCol {
    padding-right: 0 !important;
}

.uploadButtonCol {
    padding-left: 0 !important;
}
