.autoNumberButton {
    margin-left: 10px;
}

.budgetTitle {
    margin-bottom: 5px;
    text-align: center;
}

.budgetWell {
    padding-bottom: 5px !important;
    padding-top: 10px !important;
}

.contactEdit {
    margin-top: -12px;
    text-align: right;
    text-decoration: underline;
}

.contactField:hover .displayContactComment {
    display: inline-block;
}

.displayContactComment {
    display: none;
}
