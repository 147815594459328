@import '~@opengov/capital-style/scss/tokens';

.customOptionContainer {
    border-left: $unit-half solid transparent;
    display: flex;
    padding: $unit-2;
    padding-right: 20px;

    &.active {
        background-color: $color-primary-hover-light;
        border-left: $unit-half solid $color-action;
    }
}

.customOptionContent {
    overflow-wrap: break-word;
    align-items: flex-start;
    text-align: left;

    .customOptionNote {
        color: $color-gray-700;
        font-size: $font-size-small;
        font-weight: $font-weight-regular;
        margin-top: $unit-half;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
