@import '~@opengov/capital-style/scss/tokens';

.container {
    font-family: $base-font-alt;

    .hidden {
        opacity: 0;
    }

    .filtersCount {
        background-color: $color-gray-100;
        border-radius: $unit-half;
        font-size: $font-size-small;
        padding-left: $unit-half;
        padding-right: $unit-half;
    }

    .dropdown {
        :global(.dropdown-menu) {
            width: 310px;

            a {
                color: $color-gray-1000;
                font-size: $font-size-default;
                padding: 0 $unit-2;
                white-space: initial;
            }
        }

        .filterInput {
            :global(.form-group) {
                margin-bottom: 0;
            }
        }
    }
}
