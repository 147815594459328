.bidTabulations {
    margin-top: 20px;
}

.completeEvaluationButtonWrapper {
    text-align: center;
}

.noProposals {
    margin: 40px 0;
    text-align: center;
}

.noProposalsHeader {
    font-size: 22px;
    margin-bottom: 20px;
}
