.childContainer {
    min-height: 400px;
}

.projectNav {
    margin-bottom: 20px !important;

    @media print {
        margin-bottom: 0 !important;
    }
}
