@import '~@opengov/capital-style/scss/tokens';

.option {
    display: flex;
    justify-content: space-between;

    .subText {
        color: $color-fg-secondary;
    }
}
