@import '~@opengov/capital-style/scss/tokens';

.container {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;

    .section {
        background-color: $color-white;
        border: 1px solid $color-gray-200;
        margin-bottom: $unit-2;
        padding: $unit-3;
        padding-bottom: $unit-1;

        .row:not(:last-child) {
            margin-bottom: $unit-3;
        }

        h3 {
            font-size: $font-size-medium;
            font-weight: $font-weight-medium;
            line-height: $line-height-medium;
            margin-bottom: $unit-3;
        }

        label {
            font-weight: $font-weight-medium;
        }
    }

    .inlineRadioInput {
        :global(.radio-input-item-container) {
            flex: initial;
        }
    }
}

.noMarginBottom {
    margin-bottom: 0;
}

.helpText {
    color: $color-gray-700;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: $line-height-small-font;
    margin-bottom: calc($unit-1 + $unit-half);
}
