.exampleHeading {
    font-weight: bold;
}

.exampleInput {
    height: 100px !important;
    margin-bottom: 45px;
}

.modalTitle {
    text-align: center;
}

.modalBody {
    padding: 20px 30px !important;
}

.questionIcon {
    color: #337ab7;
}

.guideList {
    margin-bottom: 15px;
}

.heading {
    font-weight: bold;
}

.modal {
    max-width: 580px;
}
