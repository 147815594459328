@import '../../../theme/bootstrap-custom-variables';

.title {
    margin-top: 20px;
    padding-bottom: 10px;
    text-align: right;
}

.titleBorder {
    border-bottom: 1px solid $brand-primary;
}

@media (max-width: $screen-xs-max) {
    .title {
        text-align: center;
    }
}
