@import '../../../theme/bootstrap-custom-variables';

* {
    box-sizing: border-box;
}

.procuratedBox {
    align-items: center;
    border: 1px;
    border-color: #dce2e8;
    border-radius: 5px;
    border-style: solid;
    color: #777;
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 12px;
    height: fit-content;
    justify-content: space-between;
    padding: 10px 20px;
    text-align: left;
    width: 100%;

    .buttonBox {
        column-gap: 10px;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 10px 0;
        padding: 10px;
        row-gap: 10px;
        text-align: right;

        .buttonLeft {
            height: 42px;
            padding: 10px;
            width: 200px;
        }

        .buttonRight {
            border-color: $brand-primary !important;
            margin-left: 10px;
            padding: 10px;
            width: 200px;

            &:hover {
                background-color: $btn-primary-bg !important;
                border-color: $btn-primary-border !important;
                color: $btn-primary-color !important;
            }
        }

        .logoContainer {
            font-size: 11px;
            margin: 10px 5px 0 0;
            text-align: center;

            .logo {
                height: 11px;
                margin-left: 4px;
            }
        }
    }

    .reviews {
        font-size: 14px;
    }

    .starsDisplayContainer {
        align-items: left;
        color: #777;
        display: inline-flex;
        flex-wrap: wrap;
        padding: 4px 4px 4px 0;
        text-overflow: visible;

        .mutedRatingText {
            font-size: 14px;
            margin-left: 10px;
        }

        .ratingText {
            color: $text-color;
            font-size: 20px;
            margin-left: 10px;
        }
    }

    .writeButton {
        margin-left: 10px;
        padding: 10px;
        width: 300px;
    }
}

@media (max-width: 1500px) {
    .buttonLeft {
        width: 100%;
    }

    .buttonRight {
        width: 100%;
    }

    .column {
        width: 100%;
    }

    .procuratedBox {
        width: fit-content;
    }

    .starsDisplayContainer {
        justify-content: center;
    }
}
