.confirmCheckbox {
    font-size: 1.1em;
    margin-top: 20px;
    text-align: center;
}

.postOptionsContainer {
    margin-top: 20px;
    text-align: center;
}

.postBody {
    display: inline-block;
    text-align: left;

    h4 {
        margin-bottom: 10px;
        text-align: center;
    }

    :global(.form-group) {
        margin-bottom: 5px !important;
    }
}

.sealedBidOptions {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 10px 10px 0;

    h5 {
        margin-bottom: 8px;
    }
}
