@import '~@opengov/capital-style/scss/tokens';

.toolbarContainer {
    :global(#SharedToolbarContainer) {
        background: $color-white url('./Placeholder_Toolbar.png') 25px 14px no-repeat;
        position: sticky;
        top: 0;

        :global(.tox-editor-header) {
            border: 0;
            border-radius: 0;
        }
    }
}
