.searchBar {
    padding-top: 22px;
}

.searchInput {
    margin-bottom: 0 !important;
}

.searchButton {
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: right;
}
