.checkbox {
    margin: 0;

    > label > input[type='checkbox'] {
        transform: scale(1.25);
    }
}

.notificationsTable {
    margin-bottom: 20px;
}

.tabsNav {
    margin-bottom: 20px !important;
    margin-top: 5px;
}

.zeroStateContainer {
    margin: 0 !important;
}
