@import '../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.pagination {
    margin: 0 !important;
}

.flexPaginationContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.paginationTextRightSide {
    color: $text-muted;
    margin-left: $unit-1;
}

.logo {
    border: 1px solid $panel-default-border;
    float: left;
    margin-right: 8px;
    vertical-align: top;
    width: 42px;
}

.contractsTable {
    background-color: #fff;
    cursor: pointer;
}

.userProfilePicture {
    margin-right: 7px;
}

.booleanIcon {
    font-size: 1.5em;

    &.isTrue {
        color: $brand-success;
    }

    &.isFalse {
        color: $brand-danger;
    }
}

.infoIcon {
    color: #337ab7;
}

.searchForm {
    display: inline-block;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.statusLabel {
    font-size: 12px;
}

.pageSizeSelect {
    margin-bottom: 10px;
    margin-left: auto;
    max-width: $unit-10 + $unit-2;
}

.resetContractFiltersButton {
    margin-bottom: 15px;
    margin-left: $unit-1;
}

.inlineComponentsContainer {
    margin-top: $unit-1;
    flex-grow: 2;
}
