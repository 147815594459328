@import '~@opengov/capital-style/scss/tokens';

.requisitionLogs {
    color: $color-fg-secondary;
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    gap: $unit-1;

    .requisitionLog + .requisitionLog {
        border-top: 1px solid $color-fg-border-primary;
        padding-top: $unit-1;
    }
}
