@import '~@opengov/capital-style/scss/tokens';

.title {
    color: $color-fg-primary;
    font-family: $base-font;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    line-height: $line-height-medium;
}

.sectionHeader {
    font-weight: $font-weight-bold;
}

.childQuestionTitle {
    font-size: $font-size-base;
}

.noPromptBelow {
    margin-bottom: calc($unit-1 + $unit-half);
}

.prompt {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    a {
        text-decoration: underline;
    }

    p {
        margin-bottom: calc($unit-1 * 1.5);
    }
}
