@import '../../theme/bootstrap-custom-variables';

$button-color: $brand-primary;

.orderButton {
    color: $button-color;
    cursor: pointer;
    display: block;
    font-size: 2.8em;

    &:hover {
        color: lighten($button-color, 10%);
    }
}

.disabled {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;

    &:hover {
        color: $button-color;
    }
}
