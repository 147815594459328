@import '../../theme/bootstrap-custom-variables';

.group {
    white-space: normal !important;
}

.group:hover {
    background-color: $brand-primary !important;
    color: #fff !important;
    cursor: pointer;

    .icon {
        color: #fff !important;
    }
}

.disabledItem:hover {
    cursor: not-allowed;
}

.icon {
    margin-right: 10px;
}

.description {
    margin-bottom: 10px;
}

.searchFormGroup {
    margin-bottom: 0 !important;
}
