@import '~@opengov/capital-style/scss/tokens';

.legend {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    flex-grow: 1;

    .mobileLegendContainer {
        border-bottom: $border-gray-200;
        gap: $unit-1;
        margin-bottom: $unit-half;
        padding-bottom: $unit-1;

        .mobileLegendDetails {
            gap: $unit-half;

            p {
                font-size: $font-size-small;
            }
        }
    }

    .table {
        margin-bottom: $unit-half;

        td {
            padding: $unit-half !important;
        }

        .headerRow {
            color: $color-gray-800;

            th {
                border-bottom: 0;
            }

            :global(.MuiTypography-h5) {
                font-weight: $font-weight-regular;
            }
        }

        .legendRow {
            border-bottom: $border-gray-200;
        }

        .numberColumn {
            padding-right: $unit-2 !important;
            text-align: right;
        }

        .percentColumn {
            align-items: center;
            background-color: $color-bg-secondary;
            border-left: $border-gray-200;
            display: flex;
            gap: $unit-1;
            justify-content: flex-end;
            padding-right: $unit-2 !important;
        }

        .typeColumn {
            i {
                padding-right: $unit-1;
            }

            font-weight: $font-weight-medium;
            padding-left: $unit-2 !important;
        }
    }

    .showMoreButton {
        color: $color-action-800;
        margin-left: 27px;
    }
}
