@import '../../../../theme/bootstrap-custom-variables';

.submitButton {
    margin-right: 20px;
}

.deleteButton {
    color: $state-danger-text !important;
    margin-left: 50px;
}

.errorMsg {
    margin-bottom: 10px;
}
