@import '~@opengov/capital-style/scss/tokens';

.condition {
    margin-bottom: 8px;
}

.deleteButton {
    padding: 5px;

    &:hover {
        cursor: pointer;
    }
}

.templateTag {
    background-color: #eee;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 0.9em;
    margin: 0 5px;
    padding: 2px 7px;
}

// sdv2 stuff
.itemContainer {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: $unit-half;
    cursor: pointer;
    font-family: $base-font;
    margin-bottom: $unit-2;
    padding: $unit-3;

    .logicCondition {
        border-radius: $unit-half;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
        margin-left: $unit-1;
        margin-right: $unit-1;
        padding: $unit-quarter $unit-1;
        padding-top: $unit-half;
    }

    .equal {
        background: $color-warning-50;
        border: 1px solid $color-warning-800;
        color: $color-warning-800;
    }

    .include {
        background: $color-info-50;
        border: 1px solid $color-info-800;
        color: $color-info-800;
    }

    .notInclude {
        background: $color-in-progress-50;
        border: 1px solid $color-in-progress-800;
        color: $color-in-progress-800;
    }

    .notEqual {
        background: #faebef;
        border: 1px solid #7d3347;
        color: #7d3347;
    }

    .hide {
        background: $color-error-50;
        border: 1px solid $color-error-700;
        color: $color-ruby-500;
    }

    .show {
        background: $color-success-50;
        border: 1px solid $color-success-700;
        color: $color-success-700;
    }

    .logicResult {
        color: $color-gray-700;
        font-size: $font-size-default;
        font-weight: $font-weight-regular;
        margin-top: $unit-2;

        i {
            margin-right: $unit-2;
        }
    }
}
