@import '~@opengov/capital-style/scss/tokens';

.navButtons {
    align-items: center;
    background-color: $color-white;
    border-top: 1px solid $color-fg-border-primary;
    bottom: 0;
    display: flex;
    gap: $unit-1;
    justify-content: flex-end;
    margin-left: -$unit-3;
    margin-right: -$unit-3;
    padding: $unit-3;
    position: sticky;
    z-index: 1;
}
