.statusLabel {
    border-radius: 4px !important;
    display: block !important;
    font-size: 100% !important;
    line-height: normal !important;
    margin-bottom: 2px;
    padding: 4px !important;
    white-space: normal !important;
}

.projectLabel {
    color: #777;
    font-size: 12px;
    font-style: italic;
}
