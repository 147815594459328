@import '../../../../theme/custom-variables';

.titleText {
    color: $govlist-blue;
    font-size: 20px;
    margin-bottom: 35px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.confirmTitle {
    @extend .titleText;
    font-weight: bold;
    margin-bottom: 25px;
}

.confirmationContainer {
    dl {
        margin-bottom: 30px;
        text-align: left;
    }

    dt {
        margin-bottom: 3px;
    }

    dd {
        margin-bottom: 10px;
    }
}

.creatingText {
    color: $govlist-blue;
    margin-top: 15px;
}
