.warningText {
    font-size: 16px;
    margin: 20px 0 30px;
    padding: 0 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.cancelBtn {
    margin-right: 20px;
}

.btnIcon {
    margin-right: 5px;
}

.modalTitle {
    margin-right: 30px;
    display: inline !important;
}
