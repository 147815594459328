@import '../../../../../theme/bootstrap-custom-variables';

@media (max-width: $screen-xs-max) {
    .buttonContainer {
        margin-top: 10px;
    }

    .searchBar {
        padding: 10px;
    }
}
