@import '~@opengov/capital-style/scss/tokens';

.moreInformation {
    border-top: 1px solid $color-fg-border-primary;
    padding-top: $unit-3;

    .addMoreInformation {
        align-items: center;
        background-color: transparent !important;
        border: 0;
        box-shadow: none !important;
        color: $color-fg-secondary !important;
        display: flex;
        font-weight: $font-weight-semi-bold;
        gap: $unit-half;
        height: $unit-3;
        justify-content: flex-end;
        margin: 0;
        padding: 3px 0 5px;
    }

    .addMoreInformation:hover,
    .addMoreInformation:active {
        background-color: transparent !important;
        box-shadow: none !important;
    }

    label,
    .label,
    :global(.control-label) {
        font-family: $base-font-alt;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
    }
}
