@import '../../../../theme/bootstrap-custom-variables';

.container {
    padding: 10px;
    width: 60%;

    .title {
        padding: 10px 0 40px;
        text-align: left;
    }

    .column {
        max-width: 400px;
        padding-bottom: 15px;

        .description {
            padding-bottom: 30px;
        }

        .schedule {
            padding-bottom: 30px;
        }

        .policyAvailable {
            padding-bottom: 15px;
            padding-top: 30px;
        }

        .toggle {
            align-items: center;
            display: flex;
            font-weight: 500;
            gap: 8px;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: right;
        padding-top: 30px;

        .button {
            margin-left: 10px;
        }
    }

    .muted {
        color: $gray-dark;
        padding-top: 10px;
    }
}
