@import '../../../theme/bootstrap-custom-variables';

.addendumConfirmText {
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 15px;
    margin-top: 2px;
}

.companyNameCell {
    align-items: center;
    display: flex;
}

.italicized {
    font-style: italic;
}

.label {
    color: $text-muted;
}

.navButtons {
    text-align: right;
}

.printIcon {
    cursor: pointer;
    margin-left: 6px;
}

.section {
    margin-top: 30px;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 50px;
        @media print {
            margin-bottom: 0;
        }
    }
}

.sectionHeader {
    border-bottom: 1px solid $panel-default-border;
    color: $text-muted;
    font-weight: bold;
    padding-bottom: 8px;
}

.sectionItem {
    border-bottom: 1px solid $panel-default-border;
    padding: 8px 0;

    &:last-child {
        border-bottom: 0;
    }

    ul {
        margin-bottom: 0;
    }
}

@media (max-width: $screen-sm-max) {
    .label {
        margin-bottom: 3px;
    }
}

.vendorListUl {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin-bottom: 5px;

        button {
            margin-top: 15px;
        }

        a {
            cursor: pointer;
            font-size: 0.8em;
        }
    }
}
