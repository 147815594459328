.container {
    min-height: 200px;
}

.title {
    font-size: 16px;
}

.initButton {
    margin-bottom: 50px;
    padding-top: 40px;
}
