@import '~@opengov/capital-style/scss/tokens';

.code:hover {
    cursor: default;
}

.longFormList {
    margin-bottom: 0 !important;
    margin-top: $unit-1;
}

.longFormListItemNoBorder {
    padding-bottom: $unit-1;
    padding-top: $unit-1;
}

.longFormListItem {
    padding-bottom: $unit-1;
    padding-top: $unit-1;
    border-bottom: 1px solid $color-fg-border-secondary;
}

.extraCategories {
    font-style: italic;
}
