@import '~@opengov/capital-style/scss/tokens';

.details {
    .moreInformation {
        margin-top: $unit-2;
        display: flex;
        flex-direction: column;
        gap: $unit-1;
        padding-left: $unit-5;
    }
}

.attachmentSection {
    margin-top: $unit-3;
    padding-left: $unit-5;
    padding-right: $unit-2;
    margin-bottom: $unit-1;

    label {
        font-family: $base-font-alt;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        margin-bottom: $unit-1;
    }
}

.attachmentList {
    .attachmentItem {
        border-bottom: 1px solid $color-gray-200;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-bottom: $unit-1;
        padding-top: $unit-1;

        a {
            text-decoration: none;
            font-weight: $font-weight-regular;
        }
    }

    .attachmentItem:first-child {
        border-top: 1px solid $color-gray-200;
    }
}
