@import '~@opengov/capital-style/scss/tokens';

.generalInformation {
    display: flex;
    flex-direction: column;
    gap: $unit-3;
    margin: 0;

    h5 {
        font-weight: $font-weight-semi-bold;
    }

    label,
    .label,
    :global(.control-label) {
        color: $color-fg-primary;
        font-size: $unit-2;
        font-weight: $font-weight-medium;
    }

    :global(.help-block) {
        color: $color-fg-secondary;
        font-size: $unit-2;
    }

    // Otherwise the feedback gets misaligned as there is a x/xx label on top of the field.
    :global(.form-control-feedback.glyphicon) {
        top: $unit-2;
    }

    .creator {
        align-items: center;
        display: flex;
        gap: $unit-1;
    }

    .datePicker {
        min-width: 260px;
    }

    .datesContainer {
        :global(label) {
            font-size: $font-size-small !important;
        }

        .budgetYearText {
            color: $color-fg-secondary;
            font-size: $unit-2;
            font-weight: $font-weight-medium;
            margin-bottom: auto;
            margin-top: auto;

            .budgetYearLightText {
                font-weight: $font-weight-regular;
            }
        }
    }

    .heading {
        font-size: $font-size-default;
        font-weight: $font-weight-semi-bold;
        padding-bottom: 10px;
    }

    .subText {
        color: $color-fg-secondary;
        font-weight: $font-weight-regular;
    }

    .fiscalYearLabel {
        color: $color-gray-1000 !important;
        font-family: $base-font !important;
        font-size: $font-size-base !important;
        font-weight: $font-weight-medium !important;
        line-height: $line-height-default !important;
        margin-bottom: $unit-half !important;
        max-width: 65% !important;
        min-width: 50% !important;
        display: flex;
    }
}
