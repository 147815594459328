@import '../../theme/bootstrap-custom-variables';

.selectBlock {
    :global(.react-select__control--is-disabled) {
        background-color: $color-gray-200;
        border-color: $color-fg-border-tertiary;

        :global(.react-select__single-value) {
            color: $color-fg-primary;
        }
    }

    :global(.react-select__multi-value__label) {
        cursor: default;
    }

    :global(.react-select__multi-value__remove) {
        cursor: pointer;
    }

    :global(.react-select__placeholder) {
        color: $color-fg-secondary;
        max-height: 20px;
    }

    :global(.react-select__group):not(:first-child) {
        border-top: 1px solid $color-fg-border-tertiary;
        margin-top: 2px;
        padding-top: 20px;
    }

    &.hideDisabledMenuOptions {
        :global(.react-select__option--is-disabled) {
            display: none;
        }
    }
}

.success {
    :global(.react-select__control) {
        border-color: $state-success-text;
    }
}

.error {
    :global(.react-select__control) {
        border-color: $state-danger-text;
    }
}

.warning {
    :global(.react-select__control) {
        border-color: $state-warning-text;
    }
}

.noMarginBottom {
    margin-bottom: 0;
}
