@import '../../../theme/bootstrap-custom-variables';

.titleContainer {
    margin-bottom: 15px;
}

.title {
    display: inline-block;
    margin-right: 8px;
}

.statusLabel {
    position: relative;
    top: 7px;
    vertical-align: top !important;
}

@media (max-width: $screen-sm-max) {
    .title {
        margin-bottom: 5px;
    }
}
