.timeCard {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    width: 71px;
}

.timeCard:not(:last-of-type) {
    margin-right: 5px;
}

.timeCardUnitLabel {
    border-top: 1px solid #dce0e0;
    color: #777;
    font-size: 14px;
    font-weight: bold;
}

.timeCardUnitNumber {
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 5px;
    padding-top: 5px;
}
