%label-inline {
    display: inline-block;
    font-weight: normal;
    margin-top: 3px;
    vertical-align: top;
}

.labelInlineToggleLeft {
    @extend %label-inline;
    margin-left: 8px;
}

.labelInlineToggleRight {
    @extend %label-inline;
    margin-right: 8px;
}
