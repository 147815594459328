@import '../../../theme/bootstrap-custom-variables';

.unselectedUser {
    border: 2px dashed $panel-default-border;
    border-radius: 5px;
    color: $text-muted;
    cursor: pointer;
    display: inline-block;
    padding: 5px;

    &.disabledAssign {
        cursor: not-allowed;
    }

    &:not(.disabledAssign):hover {
        background-color: darken($background-gray, 10%);
        border: 2px solid $gray;
        color: $gray;
    }
}

.selectedUser {
    @extend .unselectedUser;
    border: 1px solid transparent;
    color: $text-color;
    padding: 2px 5px;

    &:not(.disabledAssign):hover {
        border-width: 1px;
        color: $text-color;
    }
}

.userPicture {
    display: inline-block;
    margin-right: 10px;
}
