@import '../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.createButton {
    margin-bottom: 5px;
}

@media (min-width: $screen-lg) {
    .controlCol {
        margin: 0 auto;
        max-width: 320px;

        img {
            margin: 0 auto;
        }
    }
}

.restoreButton {
    margin-top: 15px;
}

.dashboardMargin {
    margin-top: $unit-1;
}
