@import '~@opengov/capital-style/scss/tokens';

.panel {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
}

.panelContainer {
    padding: $unit-2 $unit-3 !important;
}

.panelBodyContainer {
    display: flex;
    flex-direction: row;
    font-size: $font-size-default;
    gap: $unit-4;
    line-height: $line-height-tight;
}

.shadow {
    box-shadow: 0 4px 5px -2px $color-fg-border-primary !important;
}

.panelSticky {
    z-index: 1;
}
