.header {
    background-color: #f5f5f5;
    font-weight: bold;
}

.body {
    font-size: 14px;
}

.currentVendorRow {
    background-color: #f5f5f5;
    font-weight: bold;
}

.bidRow {
    border-bottom: 2px solid #ddd !important;
    border-left: 0;
    border-right: 0;
    border-top: 0;

    &:last-child {
        border: 0 !important;
    }
}

.proxyLabel {
    color: #777;
    font-size: 12px;
    font-weight: 400;
}
