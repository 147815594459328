@import '../../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens/layout';

.addBtn {
    margin-top: $unit-3;
}

.header {
    margin-bottom: $unit-2;
    text-align: center;
}

@media (max-width: $screen-xs-max) {
    .addBtn {
        margin-top: $unit-1;
        text-align: right;
    }
}
