@import '../shared';
@import '../../../../../theme/bootstrap-custom-variables';

.buttonContainer {
    margin-bottom: 10px;
}

.deleteButton {
    color: $brand-danger;
}

.editButton {
    margin-right: 5px;
}

.filtersLabel {
    margin-bottom: 20px;
    text-align: center;
}

.actionButtons {
    margin-bottom: 5px;
}
