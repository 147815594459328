@import '../../../../../theme/bootstrap-custom-variables';

.dateText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.subText {
    color: $gray-dark;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    padding-top: 5px;
    white-space: normal;
    width: fit-content;
}
