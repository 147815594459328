@import '../../../theme/bootstrap-custom-variables';

.container {
    min-height: 400px;
}

.filterBtnContainer,
.resolveContainer {
    margin-top: 10px;
    text-align: right;
}

.noComments {
    margin: 40px 0;
    text-align: center;
}

.noCommentsHeader {
    font-size: 24px;
    margin-bottom: 20px;
}

.noCommentsText {
    font-size: 20px;
    margin-bottom: 10px;
}

@media (max-width: $screen-xs-max) {
    .filterBtnContainer,
    .resolveContainer {
        text-align: center;
    }
}
