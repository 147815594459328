.dayContainer {
    margin-bottom: 20px;
    margin-top: 10px;

    > hr {
        margin-bottom: 7px;
        margin-top: 0;
    }
}

.dot {
    margin-right: 5px;
    margin-top: 2px;
    vertical-align: top;
}

.eventsPanel {
    height: 415px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.eventsPanelBody {
    margin-bottom: 15px;
    max-height: 360px;
    overflow-y: scroll;
}

.eventsContainer {
    margin: 5px 15px 0 25px;
}

.eventTime {
    color: #000;
    vertical-align: top;
}

.eventTitle {
    color: #000;
    margin-bottom: 10px;

    &:hover {
        color: #000;
        text-decoration: underline;
    }
}

.panelTitle {
    background-color: #fff;
    margin-bottom: 0;
    text-align: center;
}
