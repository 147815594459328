@import '~@opengov/capital-style/scss/tokens';

.toolsContainer {
    align-items: center;
    border-bottom: 1px solid $color-gray-200;
    color: $color-gray-700;
    display: flex;
    flex-wrap: wrap;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    gap: $unit-1;
    line-height: $line-height-default;
    // the right side content padding is defined way above in the SDv2Layout component
    // this is the easiest way to circumvent that
    margin-left: -$unit-3;
    margin-right: -$unit-3;
    margin-top: -$unit-1;
    padding-bottom: calc($unit-1 + $unit-half);

    .inProgressButton {
        align-items: center;
        background-color: $color-in-progress-50;
        border: 1px solid $color-in-progress-600;
        color: $color-in-progress-600;
        display: flex;

        span {
            background-color: $color-in-progress-600;
            color: $color-in-progress-100;
            font-size: 9px;
            line-height: $line-height-minimum;
            margin-left: $unit-half;
            padding-left: $unit-half;
            padding-right: $unit-half;
        }

        &:active,
        &:hover {
            background-color: $color-in-progress-50 !important;
            border: 1px solid $color-in-progress-600 !important;
            color: $color-in-progress-600 !important;
        }
    }

    .linkLike {
        color: $color-action;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        padding: 0;
        text-decoration: underline;

        &:disabled {
            color: $color-gray-400;
            text-decoration: none;
        }
    }
}
