.vendorCell {
    align-items: flex-end;
    display: flex;
}

.vendorEmail {
    font-style: italic;
}

.vendorName {
    display: inline-block;
    font-size: 1.1em;
    margin-bottom: 1px;
}
