@import '~@opengov/capital-style/scss/tokens';

.readOnly {
    font-family: $base-font;
    font-size: $font-size-base;

    .label {
        color: $color-gray-1000;
        font-weight: $font-weight-medium;
    }

    .prompt {
        color: $color-gray-700;
        font-weight: $font-weight-regular;
    }
}
