@import '../../../theme/bootstrap-custom-variables';

$icon-container-width: 60px;
$upload-button-width: 80px;

.iconContainer {
    float: left;
    margin-top: 8px;
    width: $icon-container-width;
}

.inputContainer {
    margin-left: $icon-container-width;
    margin-right: $upload-button-width + 10px;
}

.removeBtn {
    color: $state-danger-text !important;
    margin-right: 10px;
    padding: 0 !important;

    &:hover {
        color: lighten($state-danger-text, 10%) !important;
    }
}

.uploadButtonContainer {
    float: right;
    margin-top: 2px;
    width: $upload-button-width;
}
