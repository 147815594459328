@import '../../../../theme/bootstrap-custom-variables';

$circle-size: 46px;
$divider-height: 40px;
$border-width: 2px;

.divider {
    background-color: $panel-default-border;
    content: '';
    height: $divider-height;
    margin-left: $circle-size * 0.5 - 1;
    position: absolute;
    top: $circle-size;
    width: $border-width;
}

.dateCol {
    display: inline-block;
    float: left;
    margin-top: 10px;
    text-align: right;
    vertical-align: top;
    width: 140px;
}

.row::after {
    clear: both;
    content: '';
    display: table;
}

.statusHistoryContainer {
    margin-top: 10px;
}

.statusIcon {
    border: $border-width solid $panel-default-border;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    height: $circle-size;
    line-height: $circle-size - 4px;
    text-align: center;
    width: $circle-size;
}

.statusIconCol {
    display: inline-block;
    float: left;
    height: $circle-size + $divider-height;
    padding: 0 12px;
    position: relative;
    text-align: center;
    vertical-align: top;
}

.statusTextCol {
    margin-left: 208px;
    padding-top: 5px;
    vertical-align: top;
}

@media (max-width: $screen-xs-max) {
    .statusTextCol {
        padding-top: 10px;
    }

    .userInfo {
        display: none;
    }
}
