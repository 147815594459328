.noUserContainer {
    margin-bottom: 10px;
    text-align: center;
}

.editButton {
    padding: 0 !important;
}

.statusIcon {
    float: right;
    padding-bottom: 5px;
    padding-left: 5px;
    text-align: center;
}

.assignedDate {
    margin-bottom: 3px;
    margin-top: 8px;
}

.remindButton {
    margin-top: 5px;
}

.optionIcon {
    float: left;
    padding-right: 15px;
}

.optionText {
    overflow: hidden;

    h4 {
        margin-bottom: 5px;
    }
}

.partnerResources {
    margin-top: 30px;

    h5 {
        margin-bottom: 5px;
        text-align: center;
    }
}
