@import '../../../theme/bootstrap-custom-variables';

.toggle {
    margin-top: 5px;
}

.help {
    font-size: 0.95em;
    margin-left: 3px;
    margin-top: -12px !important;
}

.item {
    background-color: #fff;
    padding-top: 5px;
}

.draggingItem {
    border: 1px solid $panel-default-border;

    &::after {
        bottom: 0;
        position: absolute;
        right: 0;
    }
}

.dragIcon {
    margin-left: 10px;
    margin-top: 8px;
    vertical-align: top;
}

.copyButton {
    float: right;
    font-size: 1.1em;
    vertical-align: top;
}

.copyError {
    margin-bottom: 10px;
    margin-top: -10px;
    text-align: center;
}
