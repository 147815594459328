.loadingContainer {
    margin: 50px 0 30px;
}

.title {
    margin-bottom: 20px;
    text-align: center;
}

.alreadyAppliedIcon {
    margin: 20px 0 10px;
}

.alreadyApplied {
    font-size: 1.2em;
    font-style: italic;
    margin-bottom: 20px;
}
