@import '../../../../theme/bootstrap-custom-variables';

.container {
    .row {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        .orderText {
            color: $gray-light;
            line-height: 34px;
        }
    }

    .policies {
        border-bottom: 1px solid #dce0e0;
        border-top: 1px solid #dce0e0;
        min-height: 100px;
    }
}
