@import '~@opengov/capital-style/scss/tokens';

$submit-button-margin: $unit-7 + $unit-half;

.formContainer {
    align-items: flex-end;
    column-gap: $unit-1;
    display: flex;
    justify-content: space-between;
    margin-bottom: $unit-1;
    margin-top: $unit-2;

    :global(.form-group) {
        margin-bottom: 0;
    }
}

.heading {
    display: inline-block;
    margin-bottom: $unit-2;
}

.formField {
    flex-grow: 3;
}

.submitButton {
    width: $unit-20;
}

.generateButton {
    width: 100%;
}

.footer {
    color: $color-gray-700;
    font-size: $font-size-minimum;
}

.copyButton {
    float: right;
    margin-bottom: $unit-2;
    margin-top: $unit-1;
}

@media (max-width: 1185px) {
    .submitButton {
        margin-top: $unit-9 + $unit-half + $unit-quarter;
    }
}

@media (min-width: 863px) and (max-width: 991px), (min-width: 688px) and (max-width: 767px) {
    .submitButton {
        margin-top: $submit-button-margin;
    }
}
