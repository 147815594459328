@import '../../../../../theme/bootstrap-custom-variables';

.deleteButton {
    float: right;
    margin-left: 10px;
}

.itemContainer {
    border-top: 1px solid $panel-default-border;
    clear: both;
    padding: 8px;

    &:last-child {
        border-bottom: 1px solid $panel-default-border;
    }
}

.userName {
    display: inline-block;
    font-size: 16px;
    margin-left: 8px;
}
