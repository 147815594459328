@import '~@opengov/capital-style/scss/tokens';

.verifiedCertificationsDisclaimer {
    background: $color-bg-secondary;
    border: $border-gray-200;
    border-radius: $unit-half;
    gap: $unit-2;

    .title {
        color: $color-gray-800;
    }

    .body {
        color: $color-fg-secondary;
    }

    .verified {
        color: $color-gray-800;
        min-width: $unit-5;
        width: $unit-5;

        i {
            width: $unit-5;
        }
    }
}
