@import '../../../../theme/bootstrap-custom-variables';

.actionButtons {
    margin-top: 30px;
    text-align: center;
}

.info {
    margin-top: 8px;
}

.detailedProposalTable {
    margin-top: 40px;
}

.detailedProposalTitle {
    margin-bottom: 10px;

    a {
        color: rgb(19, 21, 23);
    }

    &:hover {
        color: rgb(19, 21, 23);
    }
}

.aggregateSectionTitle {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}

.proposalsSectionTitle {
    border-top: 1px solid $panel-default-border;
    font-size: 1.5em;
    margin-top: 40px;
    padding-top: 20px;
    text-align: center;
}

.proposalTable {
    h4 {
        font-size: 16px;
    }
}
