.govQuestionFormContainer {
    margin-bottom: 30px;
    margin-top: 10px;
}

.filterForm {
    display: inline-block;
    margin-bottom: 0 !important;
    margin-left: 10px;
    text-align: left;
    width: 150px;
}
