.clickable {
    cursor: pointer;
}

.simpleCountPanel {
    height: 150px;
    text-align: center;

    h3 {
        margin-top: 30px;
    }
}
