@import '~@opengov/capital-style/scss/tokens';

@mixin section-padding {
    padding: $unit-3;
}

.container {
    padding-left: $unit-1;
}

.titleContainer {
    margin-bottom: 20px;
}

.title {
    display: inline-block;
    margin-right: $unit-1;
}

.statusLabel {
    border-radius: 0;
    font-family: $base-font;
    font-size: $font-size-minimum;
    line-height: $line-height-minimum;
    padding: $unit-half $unit-1;

    &:global(.label-default) {
        text-transform: uppercase;
    }
}

.typeLabel {
    font-style: italic;
}

.contentItem {
    margin-bottom: 5px;
}

.connectedClients {
    font-size: 0.9em;
    margin-right: 0;
    margin-top: 5px;
}
