@import '../../../theme/bootstrap-custom-variables';

.topHeader {
    margin-bottom: 30px;
    text-align: center;
}

.subHeader {
    color: $gray-dark;
    margin-bottom: 30px;
    text-align: center;
}

.govtInvite {
    margin: 0 auto;
    max-width: 700px;
}

.successText {
    color: $state-success-text;
    height: 15px;
    margin-top: 10px;
    text-align: center;
}
