@import '../../../../../theme/bootstrap-custom-variables';

.acceptanceContainer {
    display: block !important;
    font-size: 0.8em;
    line-height: 0.8em;
    margin-top: 2px;

    &:hover {
        cursor: pointer;

        .acceptedText {
            text-decoration: underline;
        }
    }

    .agreementIcon {
        font-size: 9px;
    }
}

.positionText {
    font-size: 0.8em;
    line-height: 0.8em;
    margin-top: 2px;
}

.profilePicture {
    display: inline-block;
    margin-right: 8px;
    vertical-align: top;
}

.progressBar {
    margin-bottom: 3px !important;
}

.remindBtn {
    width: 118px;
}

.userInfo {
    display: inline-block;
}

@media (max-width: $screen-sm-max) {
    .container {
        text-align: center;
    }

    .progressBar {
        margin-top: 10px;
    }
}
