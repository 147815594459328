.checkbox {
    margin: 0 !important;
}

.checkboxFormGroup {
    margin: 8px 0 0 !important;
}

.container {
    padding: 10px;
    width: 330px;
}
