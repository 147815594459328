@import '~@opengov/capital-style/scss/tokens';

.wrapper {
    padding-bottom: $unit-3;
    padding-left: $unit-3;
    padding-right: $unit-3;
    padding-top: $unit-3;
}

.container {
    align-items: center;
    color: $color-gray-700;
    display: flex;
    font-family: $base-font;
    line-height: $line-height-default;
    padding-bottom: $unit-2;

    h4 {
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
    }

    .col {
        flex: 1;
    }

    .textRight {
        text-align: right;
    }
}

.containerBordered {
    border-bottom: 1px solid $color-gray-200;
}

.sectionsContainer {
    font-family: $base-font;

    .dividerOpenExtraMargin {
        margin-top: $unit-3;
    }

    .addSection {
        margin-bottom: $unit-1;
        margin-top: $unit-1;
        text-align: center;

        span {
            color: $color-gray-700;
            cursor: pointer;
            font-size: $font-size-minimum;
            font-weight: $font-weight-medium;
            line-height: $line-height-minimum;
        }
    }
}

.childSection {
    margin-left: $unit-4;
}

.addSectionBlockSpacing {
    margin-bottom: $unit-2;
}

.addSectionBlock {
    background-color: $color-gray-50;
    border: 1px solid $color-gray-200;
    margin-top: $unit-2;
}

.marginRight {
    margin-right: $unit-1;
}
