@import '../../../theme/bootstrap-custom-variables';

.container {
    margin: 20px 0 30px;
    text-align: left;

    .containerInnerWrapper {
        margin: 0 auto;
        max-width: 382px;
        text-align: left;

        .retentionCodeSelectContainer {
            display: flex;
            font-size: 16px;
            justify-content: space-between;
            padding-bottom: 5px;

            .retentionCodeSelectTitle {
                font-weight: 500;
            }
        }

        .searchSelect {
            margin-bottom: 0;
        }

        .subText {
            color: $gray-light;
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            padding: 5px 0;
        }
    }
}
