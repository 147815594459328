@import '../../../../theme/bootstrap-custom-variables';

.toggleSectionTitle {
    margin-bottom: 4px;
}

.warningCircle {
    color: $brand-warning;
    margin-left: 5px;
}
