@import '../../theme/bootstrap-custom-variables';

.profilePicture {
    margin-right: 5px;
    vertical-align: top;
}

.lastUpdaterText {
    display: inline-block;
    line-height: 1.2em;
    margin-top: 2px;
}

@media (max-width: $screen-sm-max) {
    .col {
        margin-bottom: 3px;
    }
}
