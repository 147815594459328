.spinner {
    margin-bottom: 10px;
    min-height: 0 !important;
    padding-top: 0 !important;
}

.container {
    min-height: 260px;
    padding: 40px;
}

.downloadLink {
    font-size: 1.4em;
    padding-top: 20px;
    text-align: center;
}

.successIcon {
    margin: 10px 0;
}

.confirmationHeading {
    margin-bottom: 10px;
}
