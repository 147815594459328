.filterButtons {
    margin-bottom: 15px;
}

.proposalsList {
    margin-bottom: 15px;
}

.filterBtn {
    text-align: left !important;
}

.proposalListContainer {
    margin-bottom: 50px;
}

.tourButtonContainer {
    margin: 0 auto;
    max-width: 320px;
    min-height: 40px;
    padding-left: 15px;

    button {
        padding: 5px 5px 5px 0 !important;
    }
}
