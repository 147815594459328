@import '../../../../theme/bootstrap-custom-variables';

@keyframes bidUpdateWinningAnimation {
    from {
        border: 4px solid #147556;
    }

    to {
        border-left: 4px solid #147556;
    }
}

@keyframes bidUpdateLosingAnimation {
    from {
        border: 4px solid #b23e24;
    }

    to {
        border-left: 4px solid #b23e24;
    }
}

.lineItem {
    border-radius: 4px;
    margin-top: 10px;
    padding: 0 15px !important;
}

.lineItemBorderWinning {
    border-left: 4px solid #147556;
}

.lineItemBorderLosing {
    border-left: 4px solid #b23e24;
}

.lineItemBorderNoBid {
    border-left: 4px solid #616365;
}

.bidUpdateWinning {
    animation-duration: 3s;
    animation-iteration-count: initial;
    animation-name: bidUpdateWinningAnimation;
}

.bidUpdateLosing {
    animation-duration: 3s;
    animation-iteration-count: initial;
    animation-name: bidUpdateLosingAnimation;
}

.header {
    margin-bottom: 15px;
    margin-top: 8px;
}

.headerInfoText {
    color: #777;
    display: inline-block;
    font-size: 14px;
    white-space: nowrap !important;
}

.divider,
.dividerTwo {
    border-bottom: 1px solid $list-group-border;
    margin-left: 14px;
}

.divider {
    margin-top: 40px;
}

.description {
    margin-bottom: 5px;
    margin-top: 12px;
}

.statusText {
    float: right;
    font-weight: 700;
    margin-top: 15px;
    white-space: nowrap;
}

.statusWinning {
    color: #147556;
}

.statusLosing {
    color: #b23e24;
}

.statusNoBid {
    color: #777;
}

.bidInfoSection {
    padding-bottom: 6px;
    padding-top: 11px;
}

.bidFormSection {
    background-color: #f5f5f5;
    border-left: 1px solid $list-group-border;
    margin-top: -15px !important;
    padding-top: 10px !important;
    padding-bottom: 30px !important;
}

.priceText {
    font-size: 16px;
}

.currentLowestBidTextWinning {
    color: #777;
    font-size: 16px;
}

.currentLowestBidTextLosing {
    color: #000;
    font-size: 16px;
}

.decrementText {
    display: inline-block;
    margin-left: 30px;
}

.priceLabel {
    color: #777;
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
}

.listItemLabel {
    margin-bottom: 10px;
}

.rank {
    font-size: 16px;
    font-weight: 700;
}

.bidActivityLabel {
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.viewBidActivityButton {
    margin-top: 8px;
}

.currentBidInfo {
    column-gap: 40px;
    display: flex;
}

@media (max-width: $screen-md-max) {
    .rank,
    .priceLabel {
        margin-bottom: 10px;
    }
}

@media (max-width: $screen-xs-min) {
    .decrementText {
        display: block;
        margin-left: 0;
    }

    .statusText {
        float: none;
    }
}
