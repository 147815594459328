@import '~@opengov/capital-style/scss/tokens';

.attachmentBlock {
    margin-bottom: 40px;
    margin-top: 20px;
}

.container {
    min-height: 200px;
}

.attachments {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    margin-bottom: $unit-3;
    padding: $unit-3;

    .heading {
        color: $color-gray-700;
        font-family: $base-font;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        line-height: $line-height-default;
        margin-bottom: $unit-2;

        h3 {
            color: $color-gray-1000;
            line-height: $line-height-medium;
        }

        .badge {
            background-color: $color-gray-100;
            border-radius: $unit-half;
            font-size: $font-size-minimum;
            font-weight: $font-weight-medium;
            padding: $unit-half $unit-1;
        }
    }
}
