@import '~@opengov/capital-style/scss/tokens';

.page {
    font-family: $base-font !important;
}

.pageContainer {
    margin-left: -15px;
    margin-right: -15px;
}

.panel {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    margin-bottom: 0 !important;
}

.panelContainer {
    padding: 0 !important;
}

.sidebarContainer {
    height: 100vh;
    overflow-y: auto;
    padding: $unit-3;
    position: sticky;
    top: 0;
}

.formsContainer {
    background-color: $color-gray-50;
    border-left: 1px solid $color-fg-border-primary;
}
