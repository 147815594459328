@import '../../theme/bootstrap-custom-variables';

$circle-size: 30px;

.logoContainer {
    background-color: $text-muted;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    height: $circle-size;
    margin-right: 8px;
    width: $circle-size;
}

.rowContainer {
    line-height: $circle-size;
}

.certifications {
    line-height: normal;
    margin-top: 10px;
    overflow-wrap: break-word;
    white-space: normal;
}

.certLabel {
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 5px;
}
