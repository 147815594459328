.legendColor {
    border: 1px solid #000;
    border-radius: 2px;
    display: inline-block;
    height: 20px;
    width: 20px;
}

.legendContainer {
    margin-bottom: 5px;
}

.legendText {
    display: inline-block;
    margin-left: 8px;
    vertical-align: top;
}
