.title {
    font-size: 1.05em;
}

.attachmentItem:not(:last-child) {
    margin-bottom: 6px;
}

.htmlContent {
    p:last-child {
        margin-bottom: 0;
    }
}

.paragraph {
    margin-bottom: 0;
}
