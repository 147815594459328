@import '../../../../theme/bootstrap-custom-variables';

.timelineContainer {
    margin-bottom: 16px;
    margin-top: 40px;
}

.saveButton {
    margin-top: 40px;
}

.error {
    color: $brand-danger;
    text-align: center;
}

.titleText {
    margin-right: 10px;
}

.backgroundField:hover .displayBackgroundComment,
.summaryField:hover .displaySummaryComment,
.timelineContainer:hover .displayTimelineComment {
    display: inline-block;
}

.displayBackgroundComment,
.displaySummaryComment,
.displayTimelineComment {
    display: none;
}
