/* stylelint-disable selector-class-pattern, property-no-unknown */

$table-container-border-color: #dfe3e8;
$table-cell-border-color: rgba(0, 0, 0, 0.02);
$highlight-color: rgba(0, 0, 0, 0.05);
$odd-row-background-color: rgba(0, 0, 0, 0.03);

.legacyReactTableContainer {
    position: relative;
    border: 1px solid $table-container-border-color;
    overflow: auto;
    width: 100%;

    table {
        min-width: 100%;
        width: fit-content;
        table-layout: fixed;
    }

    thead {
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    }

    tr {
        height: 30px;
        width: 100%;
    }

    th,
    td {
        border: 1px solid $table-cell-border-color;
        padding: 0.25rem;
        padding: 7px 5px;
        vertical-align: top;
        white-space: nowrap;
        overflow-x: hidden;
    }

    th {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        cursor: pointer;
        font-weight: normal;
        height: 30px;
        padding: 7px 5px;
        position: relative;
        text-align: center;
    }

    td {
        height: 30px;
    }

    &:global(.-striped) tbody tr:global(.-odd) {
        background: $odd-row-background-color;
    }

    th:global(.-sort-asc),
    td:global(.-sort-asc) {
        box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
    }

    th:global(.-sort-desc),
    td:global(.-sort-desc) {
        box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
    }

    :global(.resizer) {
        background: transparent;
        cursor: col-resize;
        height: 100%;
        position: absolute;
        right: -8px;
        top: 0;
        touch-action: none;
        user-select: none;
        width: 16px;
        z-index: 100;
    }

    th:last-child :global(.resizer) {
        right: 0;
        width: 8px;
    }

    @media (hover: hover) {
        :global(.resizer) {
            opacity: 0;
        }

        *:hover > :global(.resizer) {
            opacity: 1;
        }
    }

    &:global(.-highlight) tbody tr:global(.-padRow) td {
        padding: 7px 5px;
    }

    &:global(.-highlight) tbody tr:not(:global(.-padRow)):hover {
        background: $highlight-color;
    }

    :global(.-pagination) {
        align-items: stretch;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        box-align: stretch;
        box-pack: justify;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 3px;
        z-index: 1;
    }

    :global(.-pagination) input,
    :global(.-pagination) select {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        font-size: inherit;
        font-weight: normal;
        outline-width: 0;
        padding: 5px 7px;
    }

    :global(.-pagination) :global(.-btn) {
        appearance: none;
        background: rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 3px;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        display: block;
        font-size: 1em;
        height: 100%;
        outline-width: 0;
        padding: 6px;
        transition: all 0.1s ease;
        width: 100%;
    }

    :global(.-pagination) :global(.-btn[disabled]) {
        cursor: default;
        opacity: 0.5;
    }

    :global(.-pagination) :global(.-btn:not([disabled]):hover) {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
    }

    :global(.-pagination) :global(.-previous),
    :global(.-pagination) :global(.-next) {
        flex: 1;
        text-align: center;
    }

    :global(.-pagination) :global(.-center) {
        align-items: center;
        display: flex;
        flex: 1.5;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 0;
        text-align: center;
    }

    :global(.-pagination) :global(.-pageInfo) {
        display: inline-block;
        margin: 3px 10px;
        white-space: nowrap;
    }

    :global(.-pagination) :global(.-pageJump) {
        display: inline-block;
    }

    :global(.-pagination) :global(.-pageJump) input {
        text-align: center;
        width: 70px;
    }

    :global(.-pagination) :global(.-pageSizeOptions) {
        margin: 3px 10px;
    }

    :global(.rt-noData) {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;
        z-index: 1;
        pointer-events: none;
        padding: 20px;
        color: rgba(0, 0, 0, 0.5);
    }

    :global(.-loading) {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;
        z-index: -1;
        opacity: 0;
        pointer-events: none;

        > div {
            position: absolute;
            display: block;
            text-align: center;
            width: 100%;
            top: 50%;
            left: 0;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.6);
            transform: translateY(-52%);
            transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }

        &:global(.-active) {
            opacity: 1;
            z-index: 2;
            pointer-events: all;

            > div {
                transform: translateY(50%);
            }
        }
    }
}
