@import '../../../theme/bootstrap-custom-variables';

.timeCardContainer {
    display: inline-block;
}

.labelContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;

    .timeRemainingLabel,
    .label {
        display: inline-block;
    }

    .timeRemainingLabel {
        font-weight: 700;
        padding-right: 8px;
    }
}

.timeCardBoxesContainer {
    display: flex;
    gap: 5px;
}
