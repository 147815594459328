.actionMenu {
    margin-bottom: 15px;
}

.actionInstructions {
    margin-bottom: 0 !important;
}

.editButton {
    width: 74px;
}

.buttonRight {
    margin-left: 5px;
}

.switchActionButtons {
    margin-bottom: 10px;
    margin-top: 20px;
}

.switchActionButton {
    width: 198px;
}

.actionButtonsInfo {
    font-size: 0.9em;
}

.decisionButton {
    width: 150px;
}
