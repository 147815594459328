@import '../../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.addInstructionBtn {
    margin-top: 32px;
}

@media (max-width: $screen-sm-max) {
    .addInstructionBtn {
        margin-bottom: 15px;
        margin-top: 0;
    }
}

.sharedSectionContentHeader {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.condensendCheckboxes {
    :global(.form-group) {
        margin-bottom: 0;
    }

    :global(.checkbox) {
        margin-bottom: $unit-1;
    }
}

.pseudoLabel {
    color: $color-gray-700;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
}
