@import '../../../theme/bootstrap-custom-variables';

.sortable {
    @extend %prevent-double-click;
    cursor: pointer;
}

.table {
    padding: 0 15px;
}

.controlsContainer {
    margin-bottom: 10px;
}

.externalCheckbox {
    margin: 2px 0;
}

.userListRow {
    background-color: $color-bg-primary;
    border-bottom: 1px solid $table-border-color;
    padding-bottom: 15px;
    padding-top: 15px;

    &:first-child {
        border-top: 1px solid $table-border-color;
    }

    &:hover {
        background-color: $color-primary-hover-light;
    }

    p {
        margin-top: 8px;
    }
}

.userRequestRow p {
    margin-top: 8px;
}

.userHead {
    font-weight: bold;
    padding: 0 5px 4px;
}

.userButton {
    color: #333 !important;
    font-weight: bold;
    margin-top: -3px;
    padding-bottom: 1px !important;
    text-align: left;
    text-decoration: none !important;
    width: 100%;
}

.userButton:focus-visible {
    background-color: #e6e6e6;
    border: 2px solid #000 !important;
    margin-bottom: -2px;
    margin-left: -5px;
    margin-top: -5px;
    padding-left: 3px !important;
}

.userButton:focus:not(:focus-visible) {
    background-color: transparent;
}

.userCol {
    padding: 0 5px !important;
    white-space: pre-wrap;
}

.userFormCol {
    padding: 0 5px !important;
}

.button {
    display: inline-block;
    margin-bottom: 2px;
    margin-right: 2px;
}

.inactiveRemove {
    color: $danger-faded;
    cursor: not-allowed;
    margin-left: 10px;
    position: relative;
    top: 5px;
}

.nameField {
    word-wrap: break-word;
}

.name {
    margin-bottom: 3px;
    min-height: 5px;
}

.nameText {
    font-size: 1.1em;
    font-weight: bold;
    margin-right: 6px;
}

.requestingUsersTitle {
    margin-bottom: 15px;
    text-align: center;
}

@media (max-width: $screen-sm-max) {
    .userHead {
        display: none;
    }

    .userCol {
        margin-bottom: 5px;
    }
}
