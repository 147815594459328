@import '~@opengov/capital-style/scss/tokens';

.dragIcon {
    display: inline-block;
    font-size: 1.5em;
}

.tableHeader {
    background-color: #edefed;
    border-bottom: 2px solid #dce0e0;
    border-left: 1px solid #dce0e0;
    border-right: 1px solid #dce0e0;
    border-top: 1px solid #dce0e0;
    padding: 10px 10px 5px;
}

.tableButton {
    margin-left: 10px;
}

// sdv2 styles
.container {
    font-family: $base-font;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    margin-bottom: $unit-3;

    :global(.ag-theme-alpine) {
        font-family: $base-font;
    }

    :global(.ag-root-wrapper) {
        border-color: $color-gray-200;
        border-left: 0;
        border-right: 0;
    }

    :global(.ag-header-cell) {
        overflow: hidden;
    }

    .item {
        background-color: $color-white;
        border: 1px solid $color-gray-200;

        .header {
            align-items: center;
            display: flex;
            padding: $unit-3;

            h3 {
                color: $color-gray-1000;
            }

            p {
                color: $color-gray-700;
                font-size: $font-size-minimum;
                line-height: $line-height-minimum;
                margin-bottom: 0;
            }

            button {
                padding: 0;
            }

            .destructiveButton {
                color: $color-error-700;
            }
        }
    }
}
