@import '../../../../theme/bootstrap-custom-variables';

.timezone {
    font-size: 10px;
    margin-top: -10px;
    text-align: right;
}

.title {
    margin-bottom: 30px;
}

.calendar {
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: left;
}

.postDate {
    display: inline-block;
    font-size: 1.2em;
    font-style: italic;
    margin: 30px 0;
}

.warning {
    margin-bottom: 30px;
    text-align: center;
}

.toggleBox {
    margin-bottom: 30px;
}

.submitButton {
    margin-right: 20px;
}

.errorMsg {
    margin-top: 20px;
}

@media (max-width: $screen-xs-max) {
    .calendar {
        padding: 0 25px;
    }
}
