@import '~@opengov/capital-style/scss/tokens';

.label {
    color: $color-gray-1000 !important;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
    margin: 0;
    margin-bottom: $unit-half;
}

.noMarginBottom {
    margin-bottom: 0;
}

.helpIcon {
    color: $color-gray-600;

    &:hover {
        color: $color-action;
    }
}

.wrapperLabel {
    display: flex;
    align-items: end;

    .checkedLabel {
        color: $color-primary-selection !important;
    }

    .disabledLabel {
        color: $color-gray-600 !important;
    }

    .leftLabel {
        color: $color-gray-1000;
        font-family: $base-font;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        margin-bottom: 0;
        margin-right: $unit-1;
    }

    .rightLabel {
        color: $color-gray-1000;
        font-family: $base-font;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        margin-bottom: 0;
        margin-left: $unit-1;
    }
}

.input,
.input:hover {
    :first-child {
        background-color: $color-gray-600 !important;
    }

    :nth-child(2) {
        border-color: $color-gray-600 !important;
    }
}

.checked,
.checked:hover {
    :first-child {
        background-color: $color-primary-selection !important;
    }

    :nth-child(2) {
        border-color: $color-primary-selection !important;
    }
}

.disabledInput,
.disabledInput:hover {
    :first-child {
        background-color: $color-gray-600 !important;
    }

    :nth-child(2) {
        border-color: $color-gray-600 !important;
    }
}
