/****************************
 * CUSTOM VARIABLES
 ****************************
 *
 * Define custom variables here
 */
@import '~@opengov/capital-style/scss/tokens/colors';

$pale-gold: #f2efc2;
$gold: #efec8f;
$govlist-blue: #003c81;
$procurenow-blue: #233152;
$opengov-blue: $color-fg-link;
$dark-red: #610a09;
$background-gray: $color-gray-50;
$danger-faded: #e36662;
$panel-header-gray: #edefed;
$panel-header-border-gray: $color-gray-200;
$fancy-red: #d9534f; // not used
$white: #fff;
$lightest-grey: $color-gray-50;
$light-highlight-yellow: #fffbe1;
$bg-in-progress-dark: $color-bg-in-progress-dark;

$raleway: Raleway, sans-serif;

$zindex-popover: 1301;
$zindex-tooltip: 1311;

/* Custom royal bootstrap-like variables */
$state-royal-text: #6b477a;
$brand-royal: $color-fg-in-progress;
$btn-royal-color: $color-fg-ko;
$btn-royal-bg: $brand-royal;
$btn-royal-border: $brand-royal;

$label-royal-bg: $brand-royal;
$progress-bar-royal-bg: $brand-royal;

/* Custom opengov bootstrap-like variables */
$state-opengov-text: $opengov-blue;
$brand-opengov: $opengov-blue;

$btn-opengov-color: $color-fg-ko;
$btn-opengov-bg: $opengov-blue;
$btn-opengov-border: $color-fg-link;

$label-opengov-bg: $brand-opengov;
$progress-bar-opengov-bg: $brand-opengov;

/* Custom breakpoints */
$navbar-breakpoint-max-width: 1200px;

/****************************
 * PLACEHOLDER CLASSES
 ****************************
 *
 * Used to extend other classes (cannot be directly referenced as a class)
 * Do not get duplicated when imported, so safe to include in imported files
 */
%prevent-double-click {
    /* Prevent double clicks from highlighting the element */
    user-select: none;
}

/****************************
 * MIXINS
 ****************************
 *
 * Reusable style blocks
 */

@mixin focus-border {
    border: 1px solid #fff;
    border-radius: 5px;
    box-sizing: border-box;
}
