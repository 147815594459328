@import '../../../../../../theme/bootstrap-custom-variables';

.addBtn {
    color: $state-info-text;
    cursor: pointer;
    padding: 1px;

    &:hover {
        color: lighten($state-info-text, 5%);
    }

    &.disabled,
    &.disabled:hover {
        color: lighten($state-info-text, 10%);
        cursor: not-allowed;
    }
}

.controlButtons {
    display: block;
    float: right;
    padding-left: 6px;
    text-align: center;
}

.dragIcon {
    font-size: 1.5em;
    margin: 5px 0;
}

.editingControls {
    display: block;
}

.listItem.error {
    background-color: $state-danger-bg;
    border-color: $state-danger-border;
}

.listItem:hover {
    background-color: $list-group-hover-bg;
    cursor: pointer;

    &.error {
        background-color: $state-danger-bg;
    }

    &.notEditable {
        background-color: $list-group-bg;
        cursor: default;
    }

    &.disabledListItem {
        cursor: not-allowed;
    }
}

.listItem:focus,
.listItem:active {
    outline: none;
}

.questionType {
    font-weight: bold;
}

.removeBtn {
    color: $state-danger-text;
    padding: 1px;

    &:hover {
        color: lighten($state-danger-text, 5%);
        cursor: pointer;
    }

    &.disabled,
    &.disabled:hover {
        color: lighten($state-danger-text, 10%);
        cursor: not-allowed;
    }
}

.sectionHeader {
    cursor: pointer;
    padding: 10px 15px 10px 0;

    &:hover .editingControls {
        display: inline-block;
    }

    &.disabledListItem {
        cursor: not-allowed;
    }

    &.subheader {
        margin-left: 30px;
    }
}

.title {
    font-weight: bold;
    margin-bottom: 5px;
}

.conditional {
    margin-left: 30px;
}

.contentBlock {
    :global(.action-sidebar) {
        button {
            padding: 0 !important;
        }
    }
}
