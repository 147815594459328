@import '~@opengov/capital-style/scss/tokens';

.section {
    align-items: center;
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    border-radius: $unit-half;
    display: flex;
    font-family: $base-font;
    margin-bottom: $unit-1;
    padding: $unit-2 $unit-2;

    .title {
        align-items: center;
        display: flex;
        flex: 12;

        h4 {
            color: $color-gray-1000;
            font-size: $font-size-base;
            font-weight: $font-weight-medium;
        }

        .childTitle {
            font-size: $font-size-small;
            line-height: $line-height-minimum;
        }

        .unboldTitle {
            font-weight: $font-weight-regular;
        }

        .numbering {
            font-weight: $font-weight-medium;
        }

        .manualNumber {
            display: block;
            line-height: $line-height-default;
        }

        .leftSideActionsContainer {
            margin-right: $unit-2;
        }

        .leftSideActionsContainerWithDivider {
            margin-right: $unit-1;
        }

        .sectionType {
            color: $color-gray-700;
            font-size: $font-size-small;
            margin-top: $unit-half;
        }

        .expandDivider {
            color: $color-gray-700;
            cursor: pointer;
            display: inline-block;
            margin-left: calc($unit-2 + 2px);
        }

        .expandDividerUndragable {
            margin-left: 0;
        }

        .warning {
            color: $color-warning-700;
            font-size: $font-size-small;
            font-weight: $font-weight-medium;
            line-height: $line-height-minimum;
        }
    }

    .actions {
        flex: 3;
    }
}

.childSection {
    margin-left: $unit-4;
}
