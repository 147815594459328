@import '../../theme/bootstrap-custom-variables';

.centerTitle {
    text-align: center;
}

.helpIcon {
    font-size: 16px !important;
    vertical-align: top;
}

.info {
    margin-bottom: 20px;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.leftTitle {
    text-align: left;
}

.title {
    font-size: 24px;
    margin-bottom: 10px;
}

.titleOnly {
    @extend .title;
    margin-bottom: 20px;
}
