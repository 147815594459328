.container {
    font-size: 24px;
    margin-top: 40px;
    padding-bottom: 80px;
    text-align: center;
}

.icon {
    margin-bottom: 10px;
}

.button {
    margin-top: 40px;
}
