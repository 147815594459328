@import '../../../../../../../../theme/bootstrap-custom-variables';

.sectionDescriptionDisplay {
    color: $text-muted;
    font-style: italic;
    position: relative;

    .editIcon {
        display: none;
    }

    &:hover .editIcon {
        display: inline-block;
    }
}

.addSectionContainer {
    border: 1px dashed transparent;
    color: $text-muted;
    font-size: 0.9em;
    font-style: italic;
    padding: 5px;
    text-align: center;

    &:hover:not(.disabled) {
        background-color: $lightest-grey;
        border-color: $brand-primary;
        color: $brand-primary;
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
    }
}
