@import '../../../../theme/bootstrap-custom-variables';

.title {
    margin-bottom: 20px;
    text-align: center;
}

.sectionsStatus {
    font-size: 1.1em;
}

.warningIcon {
    color: $state-danger-text;
    margin-left: 5px;
}

.checkContainer {
    float: right;
    margin-right: 3px;
}

.iconCircle {
    color: $input-border;
}

.sectionTitle {
    display: inline-block;
    margin-top: 4px;

    &.indent {
        padding-left: 20px;
    }
}

.submitBtnContainer {
    margin-bottom: 40px;
    text-align: center;
}

.submitBtn {
    font-size: 22px !important;
    padding: 10px 16px !important;
}

.error {
    color: $state-danger-text;
    margin-top: 10px;
}

.nextSteps {
    margin-top: 10px;
}
