@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    font-size: $font-size-base;
    gap: $unit-3;

    :global(.form-group) label {
        font-family: $base-font-alt;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
    }

    :global(.rw-widget-container),
    :global(.form-control) {
        height: 32px;
    }

    :global(.react-select__control) {
        font-family: $base-font-alt;
        min-height: 32px;
    }
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: $unit-2;
}

.titleContainer {
    align-items: center;
    display: flex;
    gap: $unit-3;
}

.linkButton {
    align-items: center;
    display: flex;
    gap: $unit-half;
}

.warningIcon {
    color: $color-fg-error;
}

.jumpToVendorSelectionButton {
    color: $color-action;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
    text-align: left;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color-action;
        line-height: $line-height-default;
    }
}

.noVendorSelectedErrorMessage {
    color: $color-fg-error;
    margin-top: -20px; /* approvalContainer class in RequisitionsCreateForm/index.scss has a padding-bottom of 24px. To create a space between this error message and the vendor section specifically, I added a -20px margin-top so that there is 4px of space between the container and this message.*/
}
