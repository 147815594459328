@import '../../../../theme/bootstrap-custom-variables';

.removeBtn {
    color: $state-danger-text !important;
    padding: 0 !important;

    &:hover {
        color: lighten($state-danger-text, 5%) !important;
    }
}

.checkbox {
    display: inline-block;
    margin-right: 20px;
}

@media (max-width: $screen-xs-max) {
    .checkbox {
        margin-right: 10px;
    }
}
