@import '~@opengov/capital-style/scss/tokens';

.container {
    background-color: $color-gray-50;
    border: 1px solid $color-gray-200;
    border-radius: $unit-half;
    font-family: $base-font;
    font-size: $font-size-base;
    padding: $unit-3;

    .attachmentRow {
        border-bottom: 1px solid $color-gray-300;
        padding-bottom: $unit-2;

        &:not(:first-of-type) {
            padding-top: $unit-3;
        }
    }
}
