.checklistsPanel {
    height: 298px;
}

.contractStatus {
    margin-left: 10px;
}

.contractTitle {
    display: inline-block;
    margin-bottom: 5px;
}

.listGroupItem {
    margin-bottom: 10px !important;
}

.noChecklistsIcon {
    color: #449d44;
    font-size: 9em !important;
    margin-top: 39px;
}

.noChecklistsText {
    margin-top: 40px;
}

.panelBody {
    max-height: 245px;
    overflow-y: scroll;
}

.panelTitle {
    text-align: center;
}
