@import '~@opengov/capital-style/scss/tokens';

.summary {
    display: flex;
    gap: $unit-3;
    padding: 0 0 0 $unit-2;
    width: 100%;

    .accordionIcon {
        color: $color-fg-secondary;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        height: $unit-2;
        width: $unit-2;

        @media (max-width: 600px) {
            display: none;
        }
    }

    .line {
        width: 30px;
    }

    .budget {
        width: 46px;
    }

    .budgetPass {
        color: $color-fg-success-small;
        font-weight: $font-weight-semi-bold;
    }

    .budgetFail {
        color: $color-fg-error;
        font-weight: $font-weight-semi-bold;
    }

    .description {
        flex: 1 0 0;
    }

    .quantity {
        width: 50px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .tax {
        display: flex;
        justify-content: center;
        width: 23px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .unitOfMeasure {
        width: 75px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .discount {
        text-align: right;
        width: 77px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .unitPrice {
        @media (max-width: 1280px) {
            display: none;
        }
    }

    .unitPrice,
    .total {
        text-align: right;
        width: 130px;
    }

    @media (max-width: 600px) {
        .unitPrice {
            width: 75px;
        }
    }
}
