@import '~@opengov/capital-style/scss/tokens';

.inlineWithLabel {
    margin-left: 10px;
    vertical-align: top !important;
}

.formGroup {
    font-family: $base-font;

    .label {
        color: $color-gray-1000 !important;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
        margin-bottom: $unit-half;
    }

    input {
        display: none;
    }

    label[title] {
        align-items: center;
        color: $color-gray-1000;
        display: flex;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        line-height: $line-height-default;
        margin: 0;
        padding: 0;
    }

    :global(.disabled) {
        label[title] {
            color: $color-gray-500;
        }
    }
}
