.userProfilePicture {
    float: left;
    margin-right: 7px;
    vertical-align: top;
}

.center {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}

.tableList {
    margin-top: 15px;
}

.removeVendor {
    cursor: pointer;
    text-align: center;
}

.newVendorButton {
    margin-bottom: 10px;
    text-align: right;

    button {
        margin-left: 10px;
    }
}

.logo {
    height: 40px;
    margin-right: 5px;
    width: 40px;
}

.vendorDataCell {
    align-items: center;
    display: inline-flex;
}

.vendorName {
    font-size: 1.05em;
    vertical-align: top;
}
