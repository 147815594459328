.autoNumberButton {
    margin-left: 10px;
}

.contactEdit {
    margin-top: -12px;
    text-align: right;
}

.rebidCheckbox input[type='checkbox'] {
    margin-left: -15px;
}

.submitButtonContainer {
    margin-bottom: 10px;
    margin-top: 30px;
    text-align: center;
}
