@import '../../../../theme/bootstrap-custom-variables';

.modalContainer {
    padding-bottom: 15px;
    text-align: left;

    .title {
        color: $gray-dark;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 16px;

        .subText {
            font-weight: 400;
        }
    }

    .table {
        border-collapse: collapse;
        color: $gray-light;
        empty-cells: show;
        width: 100%;

        td {
            color: #333;
        }

        td,
        th {
            border: 1px solid $pre-border-color;
            padding: 0.5rem;
            text-align: left;
        }
    }
}
