@import '../../../theme/bootstrap-custom-variables';

.container {
    a {
        color: $text-color;
        text-decoration: none;
    }

    label {
        color: $gray-dark;
        font-weight: 500;
    }
}

.noHorizontalPadding {
    padding-left: 0;
    padding-right: 0;
}

.noVerticalPadding {
    padding-bottom: 0;
    padding-top: 0;
}

.contentPanel {
    margin-bottom: 0;
}

.contentContainer {
    border-left: 1px solid $panel-default-border;
    padding-bottom: 16px;
    padding-right: 0;
}

.projectHeaderRow {
    margin: 16px 0;
}

.projectDataContainer {
    display: flex;
    gap: 0 32px;
}

.formContainer {
    background-color: $body-bg;
}

.editArea {
    background-color: #fff;
    border: 1px solid $panel-default-border;
    margin: 32px 0 32px 32px;
    padding: 16px;
}

.bottomControlButtonsContainer {
    border-top: 1px solid $panel-default-border;
}

.bottomControlButtonsDiv {
    padding: 16px 38px 0 28px;
}

.header {
    margin-bottom: 8px;
    margin-left: 20px;
}

.sectionTypeHeader {
    margin-left: 20px;
    margin-top: 8px;
}

.sideNavContainer {
    padding-left: 0;
}

.mainNavItems {
    display: inline-block;
    width: 91%;
}

.titleContainer {
    border-bottom: 1px solid $panel-default-border;
    padding: 16px 0;
}

.valid {
    color: $brand-success;
}

.invalid {
    color: $input-border;
}

.navigationItem {
    border-bottom: 1px solid $panel-default-border;
    min-height: 48px;

    &.activeTab {
        background-color: $component-active-bg;
        border-left: 4px solid $brand-primary;

        &:hover {
            background-color: $component-active-bg;
        }
    }

    &:hover {
        background-color: $state-info-bg;
        cursor: pointer;
    }

    &.sectionDivider {
        &:hover {
            background-color: #fff;
            cursor: default;
        }
    }
}

.activeTab,
.inactiveTab:hover {
    .valid {
        color: $brand-success;
    }

    .invalid {
        color: $input-border;
    }
}

.inactiveTab .tabText {
    color: $text-color;
}

.tabText {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 0 12px 8px;

    &.sectionDivider {
        &:hover {
            cursor: default;
        }
    }
}

.checkContainer {
    float: right;
    font-size: 8px;
    padding-top: 15px;
}

.commentIcon {
    font-size: 14px;
    margin-left: 10px;
    position: absolute;
    top: 6px;
}

.sectionEditButton {
    float: right;
    padding: 0 !important;
}

.tourButton {
    float: right;
    padding: 0 !important;
}

.connectedClients {
    float: right;
    margin-right: 0 !important;
}

.topMenuButtons {
    float: right;
}

.projectTitle {
    display: inline-block;

    span:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

@media (max-width: $screen-sm-max) {
    .mainNavItems {
        margin-bottom: 16px;
        width: 100%;
    }

    .contentContainer {
        border-left: unset;
        padding-left: 0;
        padding-right: 15px;
    }

    .editArea {
        margin: 16px;
        width: 95%;
    }

    .topMenuButtons {
        float: left;
        margin-bottom: 16px;
        margin-left: 0;
        text-align: left;
    }

    .sectionEditButton {
        float: none;
    }

    .tourButton {
        float: none;
        margin-left: 16px;
    }

    .connectedClients {
        float: none;
        margin-left: 26px;
        padding-left: 16px;
        padding-top: 1px;
    }

    .header {
        margin-left: 0;
    }

    .sectionTypeHeader {
        margin-left: 0;
    }

    .bottomControlButtonsDiv {
        padding: 16px 0;
    }
}

@media (max-width: $screen-xs-max) {
    .projectDataContainer {
        flex-wrap: wrap;
    }

    .editArea {
        background-color: #fff;
        border: unset;
        margin: 0;
        padding: 16px;
        width: 100%;
    }

    .header {
        margin-left: -12px;
    }

    .projectHeaderRow {
        margin: 16px 16px 32px;
    }

    .projectInfoText {
        margin-bottom: 12px;
        width: 100%;
    }

    .sideNavContainer {
        padding-left: inherit;
    }

    .titleContainer {
        margin-left: 0;
    }

    .bottomControlButtonsContainer {
        margin: 0;
    }
}

.headerContainer {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: $screen-xs-max) {
        padding: 0;
    }
}
