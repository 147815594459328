.errorContainer {
    margin-top: 10px;
}

.signupContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        padding: 0;
    }
}

.message {
    font-style: italic;
    margin-bottom: 20px;
}
