@import '~@opengov/capital-style/scss/tokens';

.container {
    padding: 10px 15px;

    &:not(:last-child) {
        border-bottom: 1px solid $color-fg-border-primary;
    }
}

.title {
    margin-bottom: 10px;
}
