@import '../../../theme/variables-bootstrap-default';

.starsDisplayContainer {
    align-items: center;
    background-color: #fff;
    border: 1px;
    border-color: $color-fg-border-tertiary;
    border-radius: 5px;
    border-style: solid;
    color: $gray-dark;
    display: inline-flex;
    height: 24px;
    margin-left: 5px;
    padding: 4px;

    .badgeText {
        align-self: center;
    }

    .greyStar {
        color: $color-fg-border-tertiary;
        padding-right: 4px;
    }

    .logo {
        height: 16px;
        margin-right: 4px;
        width: 16px;
    }

    .logoHidden {
        height: 16px;
        margin: 0 4px;
        width: 16px;
    }
}
