@import '../../../../theme/bootstrap-custom-variables';

.alert {
    margin-bottom: 15px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    text-align: center;
}

.closedSubstatus {
    font-weight: 700;
    margin-right: 15px;
}

.closedSubstatusAwarded {
    color: $color-fg-success-small;
}

.closedSubstatusOther {
    color: #293fa1;
}

.closeOutReason {
    margin-bottom: 8px;
}

.details {
    margin-bottom: 40px;
}

.projectTitle {
    margin-bottom: 10px;

    h3 {
        margin-bottom: 3px;
    }
}

.projectStatus {
    display: flex;
}

.statusLabel {
    margin-right: 15px;
}

.subscribeButtonContainer {
    float: right;
    padding-bottom: 5px;
    padding-left: 10px;

    .reportsButton {
        vertical-align: top;
    }
}
