@import '../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.addBtn {
    color: $state-info-text !important;
    padding: 0 !important;

    &:hover {
        color: lighten($state-info-text, 5%) !important;
    }
}

.removeBtn {
    &:global(.btn) {
        color: $state-danger-text;
        padding: 0;

        &:hover {
            color: lighten($state-danger-text, 5%);
        }

        &.addMargin {
            margin-bottom: 10px;
        }
    }
}

.dragIcon {
    font-size: 1.5em;
    margin: 5px 0;
}

.hiddenDragIcon {
    display: none;
}

.criteria {
    margin-bottom: 20px;

    &:hover .displayComment {
        display: inline-block;
    }

    .editingControls {
        display: inline-block;
    }
}

.titleItem {
    margin-bottom: 5px;
}

.question {
    color: $brand-primary;
    cursor: pointer;
    float: right;
    margin-left: 7px;
    margin-right: 8px;
    position: relative;
    top: -7px;
}

.controlButtons {
    text-align: center;
    width: 24px;

    &.topControl {
        margin-top: 5px;
    }
}

.displayComment {
    display: none;
}

.editingControls {
    display: none;
}

.instructions {
    margin-bottom: 5px;
}

.uploadContainer {
    margin-top: -8px;
    padding-left: 12px;

    .uploadTooltip {
        display: none;
    }

    &:hover .uploadTooltip {
        display: inline-block;
    }
}

.isUploadCheckbox {
    @extend %prevent-double-click;
    display: inline-block;

    label {
        font-weight: normal !important;
    }
}

.isUploadRequiredCheckbox {
    @extend .isUploadCheckbox;
    margin-left: 16px;
}

.needsReviewStatusIcon {
    margin-right: 5px;
}

.criteriaBuilderIcon {
    margin-top: 5px;
    padding: 1px !important;

    &:hover {
        border-color: #adadad;
        border-radius: 4px;
    }
}

.questionLogicIcon {
    margin-top: 5px;
}

.textAreaItem {
    :global(.mce-content-body) {
        min-height: 400px;
    }
}

.container {
    color: $color-gray-1000;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .reviewCompleted {
        color: $color-success-700;
        font-weight: $font-weight-medium;
    }

    .link {
        color: $color-action;
    }
}

.emptyError {
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
}

.error {
    color: $color-error-700;
}
