@import '~@opengov/capital-style/scss/tokens';

.error {
    margin-bottom: 10px;
    text-align: center;
}

.opengovStyleError {
    color: $color-error-700;
    font-family: $base-font;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
}
