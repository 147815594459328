@import '~@opengov/capital-style/scss/tokens';

.accountSplitReview {
    display: flex;
    flex-direction: column;
    gap: $unit-1;
    padding-left: 40px;

    @media (max-width: 900px) {
        padding: 0;
    }

    .header {
        margin: 0;
        padding: 0 $unit-1;
    }

    .accounts {
        background-color: $color-bg-secondary;
        border: 1px solid $color-fg-border-primary;
        color: $color-fg-secondary;
        margin: 0;
        min-height: 58px;
        padding: $unit-1;

        .amount,
        .accountNumber {
            color: $color-fg-primary;
        }
    }
}
