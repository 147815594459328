@import '~@opengov/capital-style/scss/tokens';

.categorySetTitle {
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 12px;
}

.firstCategorySetTitle {
    margin-top: 0;
}

.grayText {
    color: $color-fg-secondary;
}
