@import '../../../../theme/bootstrap-custom-variables';

.submitButton {
    float: right;
    text-align: center;
    width: 110px;
}

.followersContainer {
    margin-right: 130px;
    min-height: 36px;
}

.selectInput {
    margin-right: 34px;
}

.closeBtn {
    float: right;
    width: 24px;

    &:hover {
        cursor: pointer;
    }
}

.errorMsg {
    margin-bottom: 10px;
}

@media (max-width: $screen-xs-max) {
    .followersContainer {
        margin-right: 0;
    }

    .submitButton {
        margin-bottom: 10px;
    }
}
