@import '../../../../../../theme/bootstrap-custom-variables';

.container {
    margin-bottom: 45px;
}

.header {
    background-color: #f5f5f5;
    font-weight: 700px;
    margin-bottom: 10px;
    padding: 10px 15px;
}

.flexRow {
    display: flex;
    justify-content: space-between;
}

.reviewStepTitle {
    display: flex;
    flex-wrap: wrap-reverse;
    margin-bottom: 6px;
    margin-top: 6px;
}

.reviewStepButtons {
    display: flex;
    margin-right: -10px;
}

.reviewStepUser {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -10px;
}

.titlePadding {
    padding-bottom: 5px;
}

.titleMargin {
    margin-right: 8px;
}

.statusLabel {
    margin-bottom: 5px;
}
