@import '../../../../../../theme/bootstrap-custom-variables';

.buttonGroup {
    margin-top: 10px;
    text-align: center;
}

.submitBtn {
    margin-left: 10px;
}

.errorContainer {
    color: $state-danger-text;
    margin-top: 5px;
    text-align: center;
}
