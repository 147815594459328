.dateText {
    margin-top: 8px;
}

.contactEdit {
    margin-top: -12px;
    text-align: right;
}

.attachmentBlock {
    margin-top: 20px;
}
