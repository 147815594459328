@import '~@opengov/capital-style/scss/tokens';

.layout {
    :global(.form-control[disabled]) {
        background-color: transparent;
    }

    :global(.form-control) {
        &:hover {
            border: 0;
            box-shadow: none;
        }
    }
}

.manualNumber {
    :global(.form-control) {
        font-size: $font-size-base;
        line-height: $line-height-default;
    }
}

.label {
    color: $color-gray-700;
    font-family: $base-font-condensed;
    font-weight: $font-weight-medium;
    line-height: $line-height-tight;
    margin-bottom: $unit-half;
}

.titleContainer {
    border-bottom: 1px solid $color-gray-200;
}
