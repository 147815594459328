@import '../../../../theme/bootstrap-custom-variables';

.titleContainer {
    margin-bottom: 20px;
}

.templateTitle {
    font-size: 1.15em;
    margin-right: 8px;

    i {
        margin-right: 5px;
    }
}

.listHeader {
    padding: 0 16px 5px;
}

.orderableHeading {
    color: #333 !important;
    font-weight: bold;
    margin-top: -3px;
    padding-bottom: 1px !important;
    text-align: left;
    text-decoration: none !important;
    width: 100%;
}

.statusLabel {
    font-size: 12px;
    position: relative;
    top: 5px;
    vertical-align: top !important;
}

.typeText {
    color: $text-muted;
    font-size: 1em;
    font-style: italic;
}

.navItems {
    margin-bottom: 20px !important;
}

.counter {
    font-size: 0.9em;
    font-weight: normal;
}

.templateInUse {
    margin-bottom: 3px;
}

.ownerProfilePicture {
    float: left;
}

.ownerNameInfo {
    overflow: hidden;
    padding-left: 5px;
}
