.container {
    margin-bottom: 40px;
}

.progressContainer {
    margin: 20px 0;
    text-align: center;
}

.panelBody {
    min-height: 350px;
}

.loadingHeader {
    font-size: 22px;
}

.scheduledAtDate {
    margin-top: 10px;
}

.titleContainer {
    text-align: center;
}

.title:hover {
    cursor: pointer;
    text-decoration: underline;
}
