@import '../../../../../theme/bootstrap-custom-variables';

.status {
    color: $brand-primary;
    margin-top: 10px;
    text-align: center;
}

.removeButton {
    color: $state-danger-text !important;
}

.directions {
    margin-bottom: 5px;
    text-align: center;
}

.errorMsg {
    color: $state-danger-text;
    margin-bottom: 10px;
    text-align: center;
}

.attachmentInstructions {
    margin-bottom: 5px;
    text-align: center;
}

.selectedDescription {
    margin-top: 30px;
}

.includeAttachment {
    margin-top: 30px;
}
