@import '~@opengov/capital-style/scss/tokens';

.tableHeader {
    background-color: $color-bg-tertiary;
    color: $color-fg-secondary;
    font-family: $base-font-condensed;
    font-weight: $font-weight-medium;
    padding: $unit-1 $unit-2;
}

.header {
    padding: $unit-2 $unit-2 $unit-2 0;

    .title {
        font-size: $font-size-medium;
        font-weight: $font-weight-semi-bold;
    }
}

.tableRow {
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    padding: $unit-2;
}

.description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.total {
    background-color: $color-bg-secondary;
    font-size: $font-size-base;
    font-weight: $font-weight-semi-bold;
}

.requisitionLinkContainer {
    padding-right: 0;
}

.requisitionLink {
    font-size: $font-size-base;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: left;
}
