@import '../../../../../theme/bootstrap-custom-variables';

.container {
    padding-bottom: 40px;
}

.title {
    margin-bottom: 20px;
    text-align: center;
}

.sectionTitle {
    border-bottom: 1px solid $brand-primary;
    border-top: 1px solid $brand-primary;
    color: $brand-primary;
    margin-bottom: 10px;
    padding: 10px 15px;
    text-align: center;
}

.body {
    margin-bottom: 50px;
    padding: 0 15px;
}
