@import '../../../../theme/bootstrap-custom-variables';

.activeLabel {
    margin-left: 8px;
    vertical-align: top;
}

.documentTitleContainer {
    display: block !important;

    .documentTitle {
        font-size: 1.2em;
    }

    &:hover {
        cursor: pointer;

        .documentTitle {
            text-decoration: underline;
        }
    }
}

.linkButton {
    color: $brand-primary;
    font-size: 12px;
}
