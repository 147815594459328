.errorContainer {
    margin-bottom: 10px;
}

.error,
.errorDetails {
    color: #b23e24;
    font-size: 12px;
}

.error {
    font-weight: 700;
}

.errorDetails {
    font-weight: 400;
}
