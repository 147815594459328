@import '../variables';
@import '~@opengov/capital-style/scss/tokens';

.listItem {
    border: 1px solid transparent;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    width: 100%;

    &:focus,
    &:hover {
        border-color: $select-color;

        .title {
            text-decoration: underline;
        }
    }
}

.title {
    color: $select-color;
}

.iconMenu {
    margin-left: 10px;
}

.row {
    margin-bottom: 3px;
    overflow: hidden;
    white-space: break-spaces;
}

.hotCopy {
    color: $state-success-text;
    font-weight: bold;
}

.searchTextHighlight {
    background-color: $state-warning-bg;
    color: $color-fg-secondary;
    font-weight: bold;
}
