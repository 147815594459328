.header {
    font-weight: bold;
}

.manualEntryText {
    font-size: 0.8em;
    font-style: italic;
}

.rows {
    padding-bottom: 8px;
    padding-top: 8px;
}

.sealedRows {
    @extend .rows;
    cursor: not-allowed;

    &:hover .tooltip {
        display: block;
        opacity: 0.9;
    }
}

.tooltip {
    display: none;
}

.unsealedRows {
    @extend .rows;
    cursor: pointer;
}
