.disabledTooltip {
    cursor: not-allowed;
}

.inlineComponent {
    display: inline-block;
}

.wrap {
    overflow-wrap: break-word;
    text-overflow: wrap;
}
