@import '../../../../theme/bootstrap-custom-variables';

.addBtn {
    border-color: $state-info-text !important;
    color: $state-info-text !important;
}

.addPhase {
    @extend .addBtn;
    margin-right: 10px;
}

.evaluationPhaseContainer {
    margin-bottom: 50px !important;
}

.headerText {
    font-size: 1.1em;
    margin-top: 4px;
}

.tableHeader {
    background-color: $panel-header-gray;
    border-left: 1px solid $panel-header-border-gray;
    border-right: 1px solid $panel-header-border-gray;
    border-top: 1px solid $panel-header-border-gray;
    padding: 10px 15px;
}
