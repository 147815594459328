$circle-size: 30px;

.pictureContainer {
    border-radius: 50%;
    color: #fff;
    height: $circle-size;
    line-height: $circle-size;
    text-align: center;
    width: $circle-size;
}

.avatarContainer {
    @extend .pictureContainer;
    background-size: cover;
}
