@import '~@opengov/capital-style/scss/tokens';

.labelContainer {
    column-gap: $unit-2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.label {
    color: $color-gray-1000 !important;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
    margin-bottom: $unit-half;
    max-width: 65%;
    min-width: 50%;
}

.noMarginBottom {
    margin-bottom: 0;
}

.help {
    flex-basis: 67%;
    margin-top: $unit-quarter;
    order: 1;

    &.fullWidth {
        flex-basis: 100%;
    }
}

.inlineButton {
    align-self: flex-end;
    order: 2;
}

.control {
    border: 0;
}

.dropdownIndicator {
    color: $color-gray-700;
}

.selectBlock {
    border: 1px solid $color-gray-400;
    border-radius: $unit-half;
    box-shadow: none;
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    :global(.react-select__control) {
        &:hover {
            border-color: $color-gray-700;
            box-shadow: 0 0 0 1px $color-gray-700;
        }
    }

    :global(.react-select__control--is-disabled) {
        background-color: $color-gray-200;
        border-color: $color-fg-border-tertiary;

        :global(.react-select__single-value) {
            color: $color-fg-primary;
        }
    }

    :global(.react-select__multi-value__label) {
        cursor: default;
    }

    :global(.react-select__multi-value__remove) {
        cursor: pointer;
    }

    :global(.react-select__placeholder) {
        color: $color-fg-secondary;
    }

    :global(.react-select__group):not(:first-child) {
        border-top: 1px solid $color-fg-border-tertiary;
        margin-top: 2px;
        padding-top: 20px;
    }

    &.hideDisabledMenuOptions {
        :global(.react-select__option--is-disabled) {
            display: none;
        }
    }
}

.searchSelect-readOnly {
    :global(.react-select__control--is-disabled) {
        // Match the readOnly text box stylings from CDS
        background-color: #f7f9fe;

        // override disabled border color and reset back to react-select default
        border-color: hsl(0, 0%, 90%);

        :global(.react-select__single-value) {
            // Match the readOnly text box stylings from CDS
            color: $color-gray-700;
        }
    }
}

.error {
    :global(.react-select__control) {
        border-color: $color-fg-error;
    }
}

.errorText {
    color: $color-error-700 !important;
    margin-top: $unit-half;
}

.warning {
    :global(.react-select__control) {
        border-color: $color-warning-700;
    }
}

.errorInput,
.errorInput:hover {
    border: 1px solid $color-fg-error !important;
}
