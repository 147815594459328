@import '../../theme/bootstrap-custom-variables';

.contactRole {
    font-size: 0.8em;
    font-style: italic;
    line-height: 1em;
}

.procurementContact {
    clear: both;
    margin-top: 10px;
}

.date,
.projectId {
    font-size: 0.8em;
    font-style: italic;
}

.dateLabel {
    display: inline-block;
    width: 82px;
}

.dateValue {
    display: inline-block;
    width: 100px;
}

.department {
    line-height: normal;
    margin-bottom: 2px;
}

.item {
    padding-bottom: 15px !important;
    padding-top: 15px !important;

    &:hover {
        .title {
            text-decoration: underline;
        }

        .primary {
            background-color: darken($btn-primary-bg, 10%);
        }

        .success {
            background-color: darken($btn-success-bg, 10%);
        }

        .info {
            background-color: darken($btn-info-bg, 10%);
        }

        .warning {
            background-color: darken($btn-warning-bg, 10%);
        }

        .danger {
            background-color: darken($btn-danger-bg, 10%);
        }

        .muted {
            background-color: darken($gray-light, 10%);
        }

        .royal {
            background-color: darken($btn-royal-bg, 10%);
        }

        .dark-gray {
            background-color: darken($gray-dark, 10%);
        }
    }
}

.onHoldLabel {
    color: $state-warning-text;
    font-size: 0.9em;
    font-weight: bold;
    margin-top: 2px;
    text-align: center;
}

.skinnyCol {
    padding-left: 0 !important;
}

.skinnyColRight {
    padding-right: 0 !important;
}

.statusLabel {
    display: block !important;
    line-height: normal !important;
    margin-bottom: 2px;
    padding: 4px !important;
    white-space: normal !important;
}

.closedSubstatus {
    display: flex;
    justify-content: center;
    font-weight: 700;
}

.closedSubstatusAwarded {
    color: $color-fg-success-small;
}

.closedSubstatusOther {
    color: #293fa1;
}

.title {
    font-size: 16px;
    margin-bottom: 2px;
}

.user {
    overflow: hidden;
}

.userLabel {
    display: none;
    font-weight: bold;
}

.userProfilePicture {
    float: left;
    margin-right: 7px;
    vertical-align: top;
}

.priceTableWell {
    background-color: #fff !important;
    border-radius: 0 !important;
}

@media (max-width: $screen-md-max) {
    .projectListItem {
        .titleCol {
            margin-bottom: 5px;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .deptCol {
        margin-bottom: 5px;
    }

    .skinnyCol {
        padding-left: 15px !important;
    }

    .statusLabel {
        margin-top: 2px;
    }

    .titleCol {
        margin-bottom: 5px;
    }

    .userLabel {
        display: block;
        margin-top: 5px;
    }
}
