@import '~@opengov/capital-style/scss/tokens';

.container {
    font-family: $base-font-alt !important;
}

.goBackBtn {
    align-items: center;
    color: $color-fg-secondary;
    display: flex;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    gap: $unit-half;
}

.goBackBtn:hover {
    color: $color-fg-primary;
}

.header {
    display: flex;
    flex-direction: column;
    gap: $unit-half;
    padding: 12px $unit-6 $unit-3; /* There is a margin-bottom of 20px beneath the navigation bar, so the additional 12px top padding will create 32px of space from the header to the top */
    background-color: $color-gray-50;
    margin: auto -15px;
    /* The container-fluid class is adding a 15px margin to the left and right which adds white blocks, so this resets those styles. */
    /* When container-fluid can be removed from the entire page's container, this style should be removed */
}

.title {
    font-size: $font-size-large;
}

.separator {
    border-top: 1px solid $color-fg-border-primary;
    margin-bottom: $unit-2;
    margin-top: $unit-2;
}

.content {
    background-color: $color-bg-primary;
    border-top: 1px solid $color-fg-border-secondary;
    height: 100%;
    margin-left: -15px;
    margin-right: -15px;
    padding: $unit-3 $unit-7 $unit-3 $unit-7;
}
