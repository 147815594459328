@import '~@opengov/capital-style/scss/tokens';

.container {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .warning {
        background-color: $color-info-50;
        border: 1px solid $color-info-600;
        padding: $unit-2;

        i {
            color: $color-action;
            font-size: $font-size-xxlarge;
        }
    }

    .content {
        background-color: $color-white;
        border: 1px solid $color-gray-200;
        margin-bottom: $unit-3;
        padding: $unit-3;

        h3 {
            line-height: $line-height-medium;
        }

        h5 {
            color: $color-gray-700;
        }

        .link {
            color: $color-action;
        }

        .info {
            color: $color-gray-700;
            font-size: $font-size-small;
            line-height: $line-height-small-font;
        }

        .timeline {
            color: #000;
            font-size: $font-size-small;
        }

        .bottomBordered {
            border-bottom: 1px solid $color-gray-200;
        }

        .commentContainer {
            button {
                padding: 0;
            }
        }
    }
}
