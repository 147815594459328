@import '../../../../theme/bootstrap-custom-variables';

.addBtn {
    border-color: $state-info-text !important;
    color: $state-info-text !important;
    margin-right: 10px;
}

.checkbox {
    @extend %prevent-double-click;
    font-weight: normal !important;
}

.dragIcon {
    font-size: 1.5em;
    margin: 5px 0 0 7px;
}

.deleteIcon {
    margin-left: 5px;
}

.hide {
    display: none !important;
}

.editBtn {
    border-color: $state-info-text !important;
    color: $state-info-text !important;
}

.editBtnIsEditing {
    border-color: $state-success-text !important;
    color: $state-success-text !important;
}

.editingControls {
    display: none;
    padding-top: 3px;

    &.show {
        display: inline-block;
    }
}

.toggleWell {
    margin-top: 20px;
}
