@import '../../../../../theme/bootstrap-custom-variables';

.downloadReportText {
    color: $govlist-blue;
    cursor: pointer;
    font-size: 1.1em;
    margin-top: 10px;
}

.preview {
    border: 0;
    height: 550px;
    width: 100%;
}

.downloadBody {
    font-size: 14px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;

    a:hover {
        text-decoration: none;
    }
}

.iconLink {
    color: $govlist-blue;
    cursor: pointer;
}

.confirmationHeader {
    margin: 25px 0 15px;
}
