@import '~@opengov/capital-style/scss/tokens';

.subText {
    color: $color-fg-secondary;
    font-weight: $font-weight-regular;
}

.budgetCheck {
    display: flex;
    flex-direction: column;
    gap: $unit-1;
    padding: $unit-4 0;

    .header {
        @media (min-width: 1280px) {
            display: grid;
            grid-template-columns: 1fr 2fr;
        }

        @media (max-width: 600px) {
            display: none;
        }

        h4 {
            font-weight: $font-weight-semi-bold;
        }

        .emptyHeader {
            padding: 0 $unit-1;

            @media (max-width: 1280px) {
                display: none;
            }
        }

        .headerLabels {
            display: flex;
            gap: $unit-3;
            padding: 0 $unit-1;

            .budgetCheckLabel {
                width: 100px;
            }

            .accountLabel {
                flex: 1 0 0;
                min-width: 328px;
            }

            .amountLabel {
                width: 200px;
            }
        }
    }

    .body {
        @media (min-width: 1280px) {
            display: grid;
            grid-template-columns: 1fr 2fr;
        }

        .description {
            background-color: $color-bg-secondary;
            border: 1px solid $color-fg-border-primary;
            color: $color-fg-secondary;
            display: flex;
            flex-direction: column;
            gap: $unit-half;
            justify-content: center;
            padding: $unit-1;

            @media (max-width: 1280px) {
                display: none;
            }

            @media (min-width: 1280px) {
                border-right: 0;
            }
        }

        .accountLookup {
            font-size: $font-size-default;

            :global(.btn-default) {
                color: $color-fg-secondary;
                padding: 3px 8px 5px;
            }
        }
    }
}
