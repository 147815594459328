@import '~@opengov/capital-style/scss/tokens';

.button {
    padding: 1px 5px !important;

    &:hover {
        border-color: #adadad;
        border-radius: 4px;
    }
}

.textButton {
    background: none !important;
    color: $color-action !important;
    padding: 0 !important;
}
