@import '../../theme/bootstrap-custom-variables';

.buttonGroup {
    width: 100%;
}

.toggleButton {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 50%;

    &:last-child {
        margin-left: 0 !important;
    }
}

.toggleBox {
    border: 1px solid $btn-default-border;
    border-radius: $border-radius-base;
}

@media (max-width: $screen-xs-max) {
    .toggleButton {
        width: 100%;

        &:last-child {
            border-radius: 0 !important;
        }
    }
}
