@import '../../../../theme/bootstrap-custom-variables';

.actionButton {
    padding: 3px 6px !important;
}

.actionButtons {
    float: right;
    margin-left: 5px;
}

.govCity {
    color: $text-muted;
    margin: 2px 0 5px;
}

.listItem:hover {
    background-color: $well-bg;
    cursor: pointer;
}

.logo {
    border: 1px solid $gray-light;
    max-width: 100%;
    padding: 3px;
    vertical-align: top;
    width: 100px;
}

.logoContainer {
    padding-right: 0 !important;
}

@media (max-width: $screen-xs-max) {
    .actionButtons {
        float: none;
        margin-left: 0;
        text-align: center;
    }

    .logoContainer {
        margin-bottom: 10px;
        padding-right: 15px !important;
        text-align: center;
    }
}

.cityName {
    font-size: 18px;
}
