.loadingSpinnerContainer {
    min-height: 600px;
    padding-top: 100px;
}

.loadingSpinner {
    left: 50%;
    position: relative;
    transform: translate(-50%);
}

.loadingSpinnerCentered {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.text {
    font-size: 1.6em;
    margin-top: 30px;
    text-align: center;
}
