@import '~@opengov/capital-style/scss/tokens';

.exportToCSVButton {
    margin-bottom: 15px;
    text-align: right;
}

.questionnaireItem {
    margin-bottom: 30px;
}

.questionnaireConditionalItem {
    padding-left: 20px;
}

.questionnaireItemReducedMargin {
    margin-bottom: 15px;
}

.sdv2QuestionnaireItem {
    margin-bottom: $unit-3;
}

.sdv2QuestionnaireBoxedItem {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    font-family: $base-font;
    padding: $unit-3;

    :global(.questionnaire-multiple-choice-prompt) {
        color: $color-gray-700;
    }
}

.sdv2QuestionnaireChildItem {
    margin-left: $unit-4;
}
