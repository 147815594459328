.minScale {
    float: left;
    margin-right: 5px;
    margin-top: 2px;
}

.maxScale {
    float: right;
    margin-left: 5px;
    margin-top: 2px;
}
