.description {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 20px;
}

.minBidDecrement {
    color: #777;
    font-size: 14px;
}

.bidSectionContainer {
    margin-left: 70px;
    text-align: left !important;
}

.bidSectionContainer,
.unitPriceContainer {
    display: flex;
    justify-content: start;
}

.bidFormInput {
    margin-top: 2px;
    width: 60%;
}

.unitPriceLabel {
    font-size: 18px;
    margin-bottom: 10px;
}

.quantityLabel {
    color: #777;
    font-size: 16px;
    margin-left: 5px;
    margin-top: 6px;
}

.totalSection {
    margin-left: 20px;
}

.totalLabel {
    font-size: 18px;
}

.totalPrice {
    font-size: 16px;
    margin-top: 15px;
}

.confirmationInfo {
    margin-bottom: 30px;
    margin-top: 20px;
}
