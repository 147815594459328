@import '../../proposals/ProposalDetails/index';
@import '~@opengov/capital-style/scss/tokens';

.attachment:not(:last-child) {
    margin-bottom: 3px;
}

.editTextButton {
    font-size: 0.9em !important;
    margin-left: 8px;
    padding: 0 !important;
    vertical-align: top;
}

.fullImageContainer {
    background-color: $panel-default-border;
    background-size: cover;
    border: 2px solid $panel-default-border;
    border-radius: 10%;
    display: inline-block;
    height: 130px;
    width: 130px;
}

.imageContainer {
    background-color: $panel-default-border;
    background-size: cover;
    border: 2px solid $panel-default-border;
    border-radius: 10%;
    height: 205px;
    margin-right: 25px;
    min-width: 205px;
    width: 205px;
}

.profileHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.smallImageContainer {
    background-color: $panel-default-border;
    background-size: cover;
    border: 2px solid $panel-default-border;
    border-radius: 10%;
    height: 130px;
    margin-right: 25px;
    min-width: 130px;
    width: 130px;
}

.smallProcuratedBox {
    display: none;
}

@media (max-width: 1500px) {
    .horizontalProcuratedBox {
        display: none;
    }

    .smallProcuratedBox {
        display: inline-flex;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .smallImageContainer {
        height: 205px;
        margin-right: 0;
        min-width: 205px;
        width: 205px;
    }

    .profileHeader {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;
    }
}

.certifications {
    gap: $unit-1;

    .certificationTitle {
        color: $color-fg-secondary;
        font-size: $font-size-small;
        text-transform: uppercase;
    }

    .certificationList {
        gap: $unit-1;

        .certification {
            padding-top: $unit-1;

            .certificationType {
                align-items: center;
                display: flex;
                gap: $unit-half;
            }

            .certificationDetails {
                display: flex;
                flex-direction: column;
                gap: $unit-half;
            }
        }
    }
}

.contact {
    margin-bottom: $unit-1;
}

.user {
    margin-bottom: $unit-half;
    margin-top: $unit-1;
}

.email {
    align-items: center;
    display: flex;
    gap: $unit-1;
    margin-bottom: $unit-half;
    overflow-wrap: anywhere;
}

.phone {
    margin-bottom: $unit-half;
}

.hr {
    margin: $unit-1 + 1 0;
}

.italic {
    font-style: italic;
}

.showAll {
    font-style: normal;
    cursor: pointer;
    color: $color-primary-brand;
}

.linkContainer {
    text-align: right;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-family: $base-font-alt;
    font-size: $unit-2;
    line-height: $unit-2;
    color: $opengov-blue !important;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.arrow {
    font-family: $base-font;
    margin-left: $unit-half;
}

.title {
    margin-top: $unit-2;
    color: #000;
}

.list {
    margin-bottom: $unit-quarter;
}

.listTitle {
    margin-bottom: 0;
}
