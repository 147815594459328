@import '~@opengov/capital-style/scss/tokens';

.priceItemHeader {
    display: flex;
    font-size: $font-size-small;
    font-weight: $font-weight-semi-bold;
    gap: $unit-3;
    padding: $unit-3 $unit-2 $unit-1 $unit-6;
    border: 1px solid transparent;

    @media (max-width: 600px) {
        padding-left: $unit-4;
    }

    .emptyDiv {
        height: $unit-2;
        width: $unit-2;

        @media (max-width: 600px) {
            display: none;
        }
    }

    .line {
        width: 30px;
    }

    .budget {
        width: 46px;
    }

    .description {
        flex: 1 0 0;
    }

    .quantity {
        width: 50px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .unitOfMeasure {
        width: 75px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .discount {
        text-align: right;
        width: 77px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .tax {
        width: 23px;

        @media (max-width: 1280px) {
            display: none;
        }
    }

    .unitPrice {
        @media (max-width: 1280px) {
            display: none;
        }
    }

    .unitPrice,
    .total {
        text-align: right;
        width: 130px;
    }

    @media (max-width: 600px) {
        .unitPrice {
            width: 75px;
        }
    }
}
