@import '../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.container {
    min-height: 200px;
}

.initButton {
    padding-top: 40px;
}

.error {
    color: $brand-danger;
    text-align: center;
}

.questionIcon {
    color: #337ab7;
    cursor: pointer;
    margin-left: 5px;
}

.library {
    margin-bottom: 30px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.attachmentBlock {
    margin-top: 30px;
}

.libraryButtonsContainer {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    gap: $unit-1 $unit-1 + $unit-half;
    justify-content: space-between;
}

.libraryButton {
    flex-basis: $unit-20 - $unit-1;
    flex-grow: 1;

    &.disabled,
    &.disabled:hover {
        background-color: $color-white;
        border-color: $color-gray-300;
        box-shadow: none;
        color: $color-gray-500;
        cursor: not-allowed;

        &:focus {
            outline: none;
        }
    }
}

.betaBadge {
    background-color: $color-fg-in-progress;
    color: $color-bg-in-progress-light;
    font-size: 0.7em;
    padding: $unit-half $unit-half;
    vertical-align: 8%;
}

.successAlert {
    background-color: $color-success-50;
    border: 1px solid $color-success-700;
    margin-bottom: $unit-2;
}

.checkCircle {
    color: $color-success-700;
}

$full-width: 100%;
$partial-width: 40%;

@mixin set-flex-basis($selector, $width) {
    #{$selector} {
        flex-basis: $width;
    }
}

@media (max-width: 1418px) {
    .fourButtonsContainer {
        @include set-flex-basis('.libraryText', $full-width);
    }
}

@media (max-width: 1132px) {
    @include set-flex-basis('.libraryText:not(.fourButtonsContainer > .libraryText)', $full-width);
}

@media (max-width: 1274px) {
    .fourButtonsContainer {
        @include set-flex-basis('.libraryButton', $partial-width);
    }
}

@media (max-width: 574px) {
    @include set-flex-basis(
        '.libraryButton:not(.fourButtonsContainer > .libraryButton)',
        $full-width
    );
}

@media (max-width: 425px) {
    .fourButtonsContainer {
        @include set-flex-basis('.libraryButton', $full-width);
    }
}

// sdv2 attachments block
.attachments {
    background-color: $color-white;
    border: 1px solid $color-gray-200;
    margin-bottom: $unit-3;
    padding: $unit-3;

    .heading {
        color: $color-gray-700;
        font-family: $base-font;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        line-height: $line-height-default;
        margin-bottom: $unit-2;

        h3 {
            color: $color-gray-1000;
            line-height: $line-height-medium;
        }
    }
}
