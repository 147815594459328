@import '~@opengov/capital-style/scss/tokens';

$screen-xs-min: 480px !default;

.sectionHeader {
    display: flex;
    flex-direction: column;
    font-family: $base-font;
    padding: $unit-4;

    .row {
        display: flex;
    }

    .rowWrap {
        display: flex;
        flex-wrap: wrap;
    }

    .alignItemsCenter {
        align-items: center;
    }

    .responsiveRow {
        align-items: center;
        display: flex;

        @media (max-width: $screen-xs-min) {
            align-items: normal;
            flex-direction: column;
        }
    }

    .col {
        flex: 1;
    }

    .col2 {
        flex: 2;
    }

    .halfCol {
        flex: 1;
        width: 50%;
    }

    .fullwidthCol {
        flex: 0 0 100%;
    }

    .footerActions {
        margin-top: $unit-2;
    }

    .deleteButton {
        color: $color-error-700;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        margin-left: $unit-1;
    }

    .marginBottom {
        margin-bottom: $unit-2;

        @media (max-width: $screen-xs-min) {
            margin-bottom: $unit-2;
        }
    }

    .marginBottomTriple {
        margin-bottom: $unit-3;

        @media (max-width: $screen-xs-min) {
            margin-bottom: $unit-2;
        }
    }

    .titleContainer {
        flex: 4;

        .manualNumber {
            color: $color-gray-1000;
            font-size: $font-size-medium;
            font-weight: $font-weight-medium;
            line-height: $line-height-medium;
            margin-bottom: $unit-1;
        }

        .title {
            align-items: center;
            display: flex;
            font-size: $font-size-xlarge;
            font-weight: $font-weight-bold;
            line-height: $line-height-medium;
            padding-bottom: $unit-half;

            .titleInstructionsIcon {
                color: $color-gray-600;
                cursor: pointer;
                font-size: $font-size-base;
                font-weight: $font-weight-regular;
                margin-left: $unit-half;
            }
        }

        .subtitle {
            color: $color-gray-700;
            display: flex;
            font-size: $font-size-small;
            font-weight: $font-weight-regular;

            .editors {
                margin-left: $unit-5;
            }

            @media (max-width: $screen-xs-min) {
                flex-direction: column;

                .editors {
                    margin-left: 0;
                    margin-top: $unit-1;
                }
            }
        }

        .error {
            color: $color-error-700;
            font-size: $font-size-small;
            font-weight: $font-weight-regular;
            line-height: $line-height-default;

            .errorText {
                color: $color-error-700;
            }

            .errorButton {
                font-weight: $font-weight-regular;

                &:hover {
                    color: $color-gray-700;
                }
            }

            .errorButtonUnderline {
                text-decoration: underline;
            }
        }

        .warning {
            color: $color-warning-700;
            font-size: $font-size-small;
            font-weight: $font-weight-medium;
            line-height: $line-height-minimum;
        }
    }

    .actionsContainer {
        flex: 1;
        text-align: right;

        button {
            font-weight: $font-weight-regular;
        }

        div {
            margin-bottom: $unit-1;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    .actionsContainerSmall {
        margin-top: $unit-3;
    }

    .h3 {
        font-size: $font-size-medium;
        font-weight: $font-weight-medium;
    }

    .textRight {
        text-align: right;
    }

    .removeInstructionsContainer {
        text-align: right;

        @media (max-width: $screen-xs-min) {
            order: 1;
            text-align: left;
        }
    }

    input {
        font-size: $font-size-base;
    }

    label {
        color: $color-gray-700;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
    }

    label[title] {
        font-size: $font-size-default;
        font-weight: $font-weight-regular;
        margin-right: $unit-1;

        @media (max-width: $screen-xs-min) {
            display: block;
            margin-left: $unit-half;
        }
    }

    .radioCustomLabel {
        margin-bottom: $unit-half;
    }

    .readonlyEditorsContainer {
        margin-bottom: $unit-3;

        label {
            color: $color-gray-1000 !important;
            margin-bottom: $unit-1;
        }

        .readonlyEditorsText {
            color: $color-gray-700;
            font-size: $font-size-base;
            font-weight: $font-weight-regular;
        }

        .readonlyEditorsHelp {
            color: $color-gray-700;
            font-size: $font-size-small;
            font-weight: $font-weight-regular;
        }
    }

    input[type='radio'] {
        cursor: pointer;
        font-weight: $font-weight-medium;
        transform: scale(1.5);
    }

    .condensendCheckboxes {
        :global(.form-group) {
            margin-bottom: 0;
        }

        :global(.checkbox) {
            margin-bottom: $unit-1;
        }
    }

    .pseudoLabel {
        color: $color-gray-700;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
    }
}
