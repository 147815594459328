@import '~@opengov/capital-style/scss/tokens';

@mixin section-padding {
    padding: $unit-3;

    @media (max-width: 728px) {
        padding: $unit-1;
    }

    @media (max-width: 1000px) {
        padding: $unit-2;
    }
}

.menuAndMainContainer {
    border-top: $border-gray-200;
    display: grid;
    grid-template-columns: 400px auto; /* TODO left column will need adjusted as we get a better picture of the content */

    &.collapsedMenuAndMainContainer {
        grid-template-columns: 56px auto;
    }

    &.hiddenMenuAndMainContainer {
        display: block;
    }

    .menuContainer {
        @include section-padding;
        background-color: $color-white;

        &.collapsedMenuContainer {
            padding: calc($unit-1 + $unit-half + $unit-quarter);
        }

        .stickyContainer {
            position: sticky;
            top: $unit-3;
        }
    }

    .collapseButton {
        background-color: $color-white;
        font-size: $font-size-medium;
        padding: 0;
        position: absolute;
        right: -26px;
        top: $unit-quarter;
    }

    .expandButton {
        i {
            font-size: $font-size-medium;
        }
    }

    .mainContentContainer {
        border-left: $border-gray-200;

        .header {
            background: $color-white;
            border-bottom: $border-gray-200;
        }

        .content {
            @include section-padding;
            background: $color-gray-50;
            border-bottom: $border-gray-200;
        }

        .emptyContent {
            border-top: 0;
            padding: 0;
        }

        .footer {
            @include section-padding;
            background: $color-white;
        }
    }
}

.hidden {
    display: none;
}
