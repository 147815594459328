@import '~@opengov/capital-style/scss/tokens';

.statusBarContainer {
    display: flex;
    flex-direction: row;
    gap: $unit-4;
}

.statusBarItem {
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    gap: $unit-half;
}

.statusBarItemLabel {
    color: $color-fg-secondary;
    font-weight: $font-weight-semi-bold;
    line-height: $unit-2;
}

.statusBarItemValue {
    line-height: $line-height-tight;

    .statusBarItemBoldValue {
        font-weight: $font-weight-semi-bold;
    }
}
