@import '../../../../theme/bootstrap-custom-variables';

.container {
    padding: 15px 0;

    .title {
        color: $gray-dark;
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 5px;
    }

    .row,
    .policies {
        display: flex;
        justify-content: space-between;
    }

    .row {
        .applyButton {
            margin-top: 25px;
            max-height: 34px;
        }

        .filter {
            display: flex;
            flex-direction: column;
        }
    }

    .policyAbbreviation {
        font-weight: 600;
    }

    .leftSection {
        display: flex;
        gap: 21px;
    }
}

.dropdownToggle {
    text-transform: capitalize;
}

.spinner {
    margin-right: 4px;
}
