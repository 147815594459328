@import '../../theme/bootstrap-custom-variables';

.primary {
    border-color: $brand-primary !important;
    color: $brand-primary !important;

    &.invertHover:hover {
        background-color: $btn-primary-bg !important;
        border-color: $btn-primary-border !important;
        color: $btn-primary-color !important;
    }
}

.success {
    border-color: $state-success-text !important;
    color: $state-success-text !important;

    &.invertHover:hover {
        background-color: $btn-success-bg !important;
        border-color: $btn-success-border !important;
        color: $btn-success-color !important;
    }
}

.info {
    border-color: $state-info-text !important;
    color: $state-info-text !important;

    &.invertHover:hover {
        background-color: $state-info-text !important;
        border-color: $state-info-text !important;
        color: $btn-info-color !important;
    }
}

.warning {
    border-color: $state-warning-text !important;
    color: $state-warning-text !important;

    &.invertHover:hover {
        background-color: $btn-warning-bg !important;
        border-color: $btn-warning-border !important;
        color: $btn-warning-color !important;
    }
}

.danger {
    border-color: $state-danger-text !important;
    color: $state-danger-text !important;

    &.invertHover:hover {
        background-color: $btn-danger-bg !important;
        border-color: $btn-danger-border !important;
        color: $btn-danger-color !important;
    }
}

.royal {
    border-color: $state-royal-text !important;
    color: $state-royal-text !important;

    &.invertHover:hover {
        background-color: $btn-royal-bg !important;
        border-color: $btn-royal-border !important;
        color: $btn-royal-color !important;
    }
}
