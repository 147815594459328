@import '../../../theme/bootstrap-custom-variables';

.container {
    margin: 20px 0 30px;
    text-align: left;
}

.endDateWrapper {
    margin: 0 auto;
    max-width: 382px;
    text-align: left;

    .endDateText {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 10px;
    }

    .endDateSubText {
        color: $gray-light;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 0;
    }
}
