.bidSecuritySpan {
    margin-left: 20px;
}

.loginBlurb {
    margin-top: 15px;
}

.verificationHelpBlock {
    margin-top: 5px;
}
