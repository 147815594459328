@import '../../../../../theme/bootstrap-custom-variables';

.submitBtn {
    margin-bottom: 20px;
    margin-top: 25px;
    text-align: center;
}

.errorContainer {
    color: $state-danger-text;
    margin-bottom: 10px;
    text-align: center;
}

.toggleEmail {
    margin-top: 20px;
    text-align: center;
}
