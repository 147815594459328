@import '../../theme/bootstrap-custom-variables';

.avatarContainer {
    display: inline-block;
}

.editIcon {
    color: $white;
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.imageContainer {
    background-color: $panel-default-border;
    background-size: cover;
    border: 2px solid $panel-default-border;
    border-radius: 10%;
    cursor: pointer;
    position: relative;

    &:focus-visible,
    &:hover {
        border-style: dashed;
        filter: brightness(80%);

        .editIcon {
            display: block;
        }
    }
}
