@import '~@opengov/capital-style/scss/tokens';

.openGovStyle {
    font-family: 'Barlow', sans-serif !important;

    :global(.ag-header-cell-text) {
        font-size: $unit-2;
    }

    :global(.ag-cell) {
        font-size: $unit-2;
    }
}
