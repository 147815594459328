@import '~@opengov/capital-style/scss/tokens';

.breadcrumb {
    background: none !important;
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-small;
    line-height: $line-height-tight;
    margin-bottom: $unit-half;
    padding-left: 0;

    li a {
        color: inherit;
        text-decoration: underline;
    }

    > li + li::before {
        content: '/';
        padding: 0 $unit-1;
    }

    :global(.active) {
        &,
        &::before {
            color: $color-gray-700;
        }
    }
}

.item {
    cursor: pointer;
    text-decoration: underline;
}
