@import '../../../../theme/bootstrap-custom-variables';

.policy:not(:first-child) {
    border-top: 1px solid #dce0e0;
}

.policy {
    margin: 0;
    padding-right: 10px;

    .abbreviation {
        background-color: $background-gray;
        color: $gray-dark;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 5px;
        padding: 4px;
        width: fit-content;
    }

    .block {
        color: $gray-dark;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0;
    }

    .dragIcon {
        padding-left: 15px;
        padding-top: 15px;
    }

    .title {
        color: $gray-dark;
        display: block;
        font-size: 18px;
        font-weight: 500;
        overflow-wrap: break-word;
        text-overflow: wrap;
        width: 100%;
    }

    .dateText {
        font-size: 14px;
        font-weight: 700;
    }

    .padLeft {
        padding-left: 30px;
    }
}
