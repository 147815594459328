@import '../../../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.panel {
    font-size: $font-size-default;
    line-height: $line-height-tight;
    margin-bottom: 0 !important;
}

.panelHeading {
    font-weight: $font-weight-semi-bold;
    padding: $unit-2 !important;

    &.panelHeadingButton {
        @extend %prevent-double-click;
        cursor: pointer;
    }
}

.panelBody {
    padding: $unit-2 !important;
}

.panelBodyContainer {
    display: flex;
    flex-direction: column;
    gap: $unit-2;
}
