@import '~@opengov/capital-style/scss/tokens';

.formGroup {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;
    line-height: $line-height-default;

    .label {
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
        margin-bottom: 0;
    }

    .noMarginBottom {
        margin-bottom: 0;
    }

    .optionRow {
        align-items: center;
        background-color: inherit;
        border: 0;
        display: flex;
        font-weight: $font-weight-regular;
        padding: 0;

        &:not(:last-child) {
            padding-bottom: $unit-1;
        }

        &:hover {
            background-color: inherit;
        }
    }

    .optionIconSVGContainer {
        fill: none;
        flex-shrink: 0;
        height: $unit-3;
        margin-right: $unit-1;
        width: $unit-3;
    }

    .optionIconCircle {
        stroke: $color-gray-400;
        stroke-width: 2;

        &.disabledOption {
            fill: $color-gray-200;
            stroke: $color-gray-300;
        }
    }

    .optionIconSelectedOuterCircle {
        stroke: $color-action;
        stroke-width: 2;

        &.disabledOption {
            stroke: rgba($color-action, 0.4);
        }
    }

    .optionIconSelectedInnerCircle {
        fill: $color-primary-selection;

        &.disabledOption {
            fill: rgba($color-primary-selection, 0.4);
        }
    }

    .optionIconSquare {
        fill: $color-white;
        height: 22.5px;
        stroke: $color-gray-400;
        stroke-width: 1.5px;
        width: 22.5px;

        &.disabledOption {
            fill: $color-gray-200;
            stroke: $color-gray-300;
        }
    }

    .optionIconSelectedSquare {
        @extend .optionIconSquare;

        fill: $color-primary-selection;
        stroke: $color-gray-600;

        &.disabledOption {
            fill: $color-gray-400;
            stroke: $color-gray-400;
        }
    }
}
