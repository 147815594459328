@import '../../../theme/bootstrap-custom-variables';

.projectsContainer {
    min-height: 500px;
    padding-bottom: 30px;
}

.title {
    color: $brand-primary;
    margin-bottom: 20px;
    text-align: center;
}

.name {
    font-size: 1.4em;
    line-height: 1.2em;
    margin-bottom: 7px;
    margin-top: 15px;
}

.tourButtonContainer {
    margin: 0 auto;
    max-width: 320px;
    padding-left: 15px;

    button {
        padding: 5px 5px 5px 0 !important;
    }
}

@media (min-width: $screen-lg) {
    .sideBar {
        margin: 0 auto;
        max-width: 320px;

        img {
            margin: 0 auto;
        }
    }
}
