@import '~@opengov/capital-style/scss/tokens';

.budgetCheck {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $unit-half;
    justify-content: center;
    width: 100px;

    @media (max-width: 600px) {
        height: 44px;
    }

    .mobileLabel {
        @media (min-width: 600px) {
            display: none;
        }

        padding-bottom: $unit-half;
    }

    .overBudget {
        color: $color-fg-error;
        font-weight: $font-weight-semi-bold;
    }

    .onBudget {
        color: $color-fg-success-small;
        font-weight: $font-weight-semi-bold;
    }

    .viewDetails {
        color: $color-fg-secondary;
        padding: 0;
        text-decoration: underline;

        &:hover {
            color: $color-fg-secondary;
        }
    }
}

.budgetChecking {
    color: $color-fg-secondary;
    padding-top: $unit-1;
    width: 100px;
}

.budgetCheckFallbackIcon {
    color: $color-fg-secondary;
}
