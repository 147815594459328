@import '../../../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.panelHeadingClosed {
    background-color: transparent !important;
    border-bottom: 0 !important;
}

.panelHeadingOpen {
    background-color: $color-gray-50 !important;
}
