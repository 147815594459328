@import '../../../../../theme/bootstrap-custom-variables';

.name {
    font-weight: bold;
}

.userList {
    border: 1px solid $panel-default-border;
    border-bottom: 0;
    border-left: 0;
    color: #000;
    cursor: pointer;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 15px 10px;
}

.userList:hover {
    background-color: $gray-lighter;
}

.userPicture {
    display: inline-block;
    float: left;
    margin: 3px 15px 10px 5px;
    vertical-align: top;
}

.loadMore {
    border-right: 1px solid $panel-default-border;
    border-top: 1px solid $panel-default-border;
    padding: 15px 10px;
    text-align: center;
}

.selected {
    background-color: $breadcrumb-color;
}

.selected:hover {
    background-color: $breadcrumb-color;
}

.userList:first-child {
    border-top: 0;
}

.revisionList {
    border: 1px solid $panel-default-border;
    max-height: 300px;
    overflow: -moz-scrollbars-vertical;
    overflow-x: visible;
    overflow-y: scroll;
}

.revisionList::-webkit-scrollbar {
    appearance: none;
}

.revisionList::-webkit-scrollbar:vertical {
    width: 11px;
}

.revisionList::-webkit-scrollbar:horizontal {
    height: 11px;
}

.revisionList::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #fff; /* should match background, can't be transparent */
    border-radius: 8px;
}
