@import '~@opengov/capital-style/scss/tokens';

.container {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .subtitle {
        color: $color-gray-700;
        font-size: $font-size-small;
    }

    .emptyMessage {
        color: $color-gray-700;

        a {
            color: $color-action;
        }
    }

    .attachments {
        margin-top: $unit-2;

        .attachment {
            border-top: 1px solid $color-gray-200;
            color: $color-action;
            padding-bottom: $unit-1;
            padding-top: $unit-1;

            i {
                margin-right: $unit-1;
            }
        }
    }

    .internalLabel {
        background-color: $color-gray-100;
        border-radius: $unit-half;
        color: $color-gray-700;
        font-size: $font-size-minimum;
        font-weight: $font-weight-medium;
        padding: $unit-half $unit-1;
    }
}
