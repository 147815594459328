$price-table-header-font-size: 13px;

.priceTableHeader {
    font-size: $price-table-header-font-size !important;
    line-height: $price-table-header-font-size !important;
    min-height: $price-table-header-font-size !important;
}

.rowWrapText {
    white-space: normal !important;
}

.projectLink {
    display: inline-block;
    text-decoration: none !important;

    &:hover .title {
        text-decoration: underline !important;
    }
}

.releaseDate {
    display: inline-flex;
    float: right;
    vertical-align: top;
}
