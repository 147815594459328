@import '~@opengov/capital-style/scss/tokens';

.purchaseOrderItem {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $unit-half;

    label {
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-minimum;
        margin: 0;
    }
}

.date {
    margin-left: auto;
}

.successMessage {
    align-items: center;
    color: $color-fg-success-small;
    display: flex;
    font-size: $unit-2;
    font-weight: $font-weight-semi-bold;
}
