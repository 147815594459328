@import '../variables';

.searchControls {
    margin-bottom: 10px;
    margin-top: 3px;
}

.sortDirection {
    padding-left: 0;
}

.pagination {
    margin: 0 !important;
}

.projectsList {
    margin-bottom: 20px;
}

.noSearch {
    margin-top: 40px;
    text-align: center;

    h4 {
        margin-bottom: 10px;
    }

    i {
        margin-bottom: 5px;
    }
}

.loading {
    min-height: 0;
}
