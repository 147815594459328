@import '../../../../theme/bootstrap-custom-variables';

.addendaConfirm {
    margin-top: 8px;

    .addendaConfirmTable {
        margin-top: 10px;
    }
}

.addendumItem {
    border: 1px solid transparent;
    padding: 5px;

    .editButton {
        display: none;
        margin-left: 10px;
        vertical-align: top;
    }

    &:hover {
        .editButton {
            display: inline-block;
        }
    }
}

.diffButton {
    margin-bottom: 10px;
}

.title {
    border-bottom: 1px solid $brand-primary;
    border-top: 1px solid $brand-primary;
    color: $brand-primary;
    margin-bottom: 10px;
    overflow: auto;
    padding: 10px 15px;
}

.titleInfo {
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
}

.releasedAt {
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
    margin-top: 3px;
    text-align: right;
}

.body {
    margin-bottom: 45px;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: $screen-xs-max) {
    .releasedAt {
        margin-top: 5px;
        text-align: left;
    }
}
