$between-font-size: 11px;
$between-padding: 4px;

.betweenEnd {
    font-size: $between-font-size;
    padding-left: $between-padding !important;
}

.betweenStart {
    font-size: $between-font-size;
    padding-right: $between-padding !important;
}

.filterButton {
    text-align: left !important;
}

.filterButtonGroup {
    margin-bottom: 10px;
}

.filterSelectLabel {
    font-size: 14px;
    margin-bottom: 0;
}

.filtersFormContainers {
    font-size: 12px;
}

.filtersList {
    padding: 5px;
}

.filterTypeSelect {
    margin-bottom: 5px !important;
}

.formContainer {
    margin-bottom: 5px;
}

.removeFilterButton {
    font-size: 14px;
    padding: 0 !important;
}

.savedFilterControl {
    float: right;
    vertical-align: top;

    button {
        margin-left: 8px;
        padding: 0 !important;
    }
}

.savedFilterSearchSelect {
    margin-bottom: 5px !important;
}

.smallButtonPadding {
    padding: 3px !important;
}
