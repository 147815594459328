@import '../../../../../../../../theme/bootstrap-custom-variables';

.colToggle {
    margin-top: 3px;
    padding-right: 0 !important;
}

.optionsTitle {
    margin-bottom: 8px;
}

.searchSelect {
    font-size: 12px;
    margin-bottom: 5px !important;
}

@media (max-width: $screen-sm-max) {
    .colToggle {
        padding-right: 15px !important;
        text-align: left;
    }
}
