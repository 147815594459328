.auntLynne {
    font-style: italic;
    padding-bottom: 15px;
    text-align: center;
}

.nav {
    margin-bottom: 20px !important;
}

.title {
    font-size: 26px;
    margin-bottom: 10px;
}
