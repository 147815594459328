@import '../../../theme/bootstrap-custom-variables';

.container {
    background-color: $state-warning-bg;
    border: 1px solid $brand-warning;
    display: flex;
    margin-bottom: 15px;
}

.icon {
    color: $brand-warning;
    margin-left: 15px;
    margin-right: 16px;
    margin-top: 15px;
}

.title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 15px;
}

.listItem {
    font-size: 16px;
    margin-left: 25px;
    padding: 0;
}

.link {
    font-weight: 700;

    &:hover {
        cursor: pointer;
    }
}
