@import '~@opengov/capital-style/scss/tokens';

.mobileLabel {
    @media (min-width: 600px) {
        display: none;
    }

    padding-bottom: $unit-half;
}

.accountNumber {
    flex: 1 0 0;

    @media (min-width: 600px) {
        min-width: 328px;
    }

    :global(.form-group) {
        margin-bottom: $unit-half;
    }

    .accountDescription {
        color: $color-fg-secondary;
        font-size: $font-size-small;
    }
}

.amount {
    @media (min-width: 600px) {
        width: 200px;
    }

    :global(.input-group-addon) {
        background-color: $color-gray-50;
    }

    :global(.form-group) {
        margin-bottom: 0;
    }

    input {
        text-align: right;
    }

    .unstyledButton {
        border: 0;
        color: $color-fg-secondary;
        margin: 0;
        padding: 0;
        text-decoration: underline;
    }
}
