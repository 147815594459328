@import '../../theme/bootstrap-custom-variables';

.editIcon {
    font-size: 18px !important;
    margin-top: -4px;
}

.reviewIcon {
    font-size: 14px;
    margin-top: -2px;
}

$edit-color: $text-muted;
$review-color: $brand-primary;

.projectToggle {
    margin: 0 6px;

    // Unchecked toggle styles
    :global(.react-toggle-track) {
        background-color: $review-color;
    }

    &:global(.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track) {
        background-color: darken($review-color, 10%);
    }

    :global(.react-toggle-thumb) {
        border-color: $review-color;
    }

    // Checked toggle styles
    &:global(.react-toggle--checked .react-toggle-track) {
        background-color: $edit-color;
    }

    &:global(.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track) {
        background-color: darken($edit-color, 10%);
    }

    &:global(.react-toggle--checked .react-toggle-thumb) {
        border-color: $edit-color;
    }
}
