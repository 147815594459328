@import '~@opengov/capital-style/scss/tokens';

.gridContainer {
    margin-bottom: 15px;
}

.filesToUpload {
    margin-bottom: 10px;
}

.attachmentsContainer {
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .attachmentsDropzoneContainer {
        background-color: $color-gray-50;
    }

    .label {
        color: $color-gray-1000;
        font-weight: $font-weight-medium;
        padding-bottom: $unit-half;
    }

    .dropzone {
        background-color: $color-gray-50;
        border: 2px dashed $color-gray-600;
        border-radius: 0;
        padding: $unit-2;

        &:focus {
            background-color: $color-gray-50;
            border: 2px dashed $color-gray-600;
        }

        &:hover {
            background-color: $color-gray-100;
            border: 2px dashed $color-gray-600;
        }
    }

    .dropzoneContainer {
        border: 1px solid $color-gray-200;
        border-radius: $unit-half;
    }

    .dropzoneContent {
        color: $color-gray-700;
        text-align: center;

        .link {
            color: $color-action;
        }
    }

    .dropzoneActive {
        background-color: $color-action-50;

        .dropzone {
            background-color: $color-action-50;
            border-color: $color-primary-brand;
        }
    }
}
