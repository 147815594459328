@import '../../theme/bootstrap-custom-variables';

.profilePicture {
    border: 1px solid $gray-light;
    cursor: pointer;
}

.actionButton {
    display: inline-block;

    &:not(:last-child) {
        margin-right: 20px;
    }
}

.displayTitle {
    color: #000;
}

.logoContainer {
    float: left;
    max-width: 160px;
    padding-right: 30px;
}

.publicProfileDetails {
    overflow: hidden;
}

.menuRight {
    float: right;
    margin-left: 10px;
    text-align: right;

    .menuComponent {
        display: inline-block;
        margin-left: 10px;
        vertical-align: top;
    }

    .subscribeButton {
        display: inline-block;
    }
}

.retentionInfo {
    vertical-align: top;
}

.tags {
    margin-top: 5px;
}

.buttonBar {
    margin-top: 10px;
}

.tag {
    display: inline-block;
    margin-right: 10px;
}

.projectId,
.timelineDetails,
.postDetails,
.sealedBidDetails {
    margin-top: 3px;
}

.timelineItem {
    display: inline-block;
}

.privateBid {
    margin-right: 10px;
}

.calendarDate {
    margin-left: 10px;
}

.title > span:hover {
    cursor: pointer;
    text-decoration: underline;
}

.lastAddendum {
    font-size: 1.05em;
    font-weight: bold;
    margin-top: 3px;

    .changedLink {
        margin-left: 10px;
    }
}

.socialButton {
    display: inline-block;
    vertical-align: top;
}

.socialButtonBar {
    float: right;
    margin-top: 5px;

    > .socialButton:not(:last-child) {
        margin-right: 6px;
    }

    @media print {
        margin-top: 0;
    }
}

.timezone {
    margin-top: 3px;
}

.timer {
    font-style: italic;
    margin-top: 5px;
}

.title {
    font-size: 26px;
}

.statusLabel {
    display: block !important;
    font-size: 12px;
    margin-top: 5px;
    white-space: normal;
}

.alert {
    margin-bottom: 15px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    text-align: center;
}

.sealedPricingDetails {
    display: inline-block;
    margin-left: 10px;
}

.closedSubstatus {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
}

.closedSubstatusAwarded {
    color: $color-fg-success-small;
}

.closedSubstatusOther {
    color: #293fa1;
}

@media (max-width: $screen-xs-max) {
    .logoContainer {
        float: none;
        margin: 0 auto;
        padding-right: 0;
    }

    .title {
        margin-top: 10px;
        text-align: center;
    }

    .lastAddendum {
        font-size: 1em;
        font-weight: normal;
        text-align: center;

        .changedLink {
            display: block;
            margin-left: 0;
        }
    }

    .postDetails,
    .projectId,
    .sealedBidDetails,
    .tags,
    .timelineDetails,
    .timezone,
    .timer {
        text-align: center;
    }

    .timelineDot {
        display: none;
    }

    .timelineItem {
        display: block;
        margin-bottom: 3px;
    }

    .socialButtonBar {
        float: none;
        margin: 10px 0;
        text-align: center;

        @media print {
            margin: 0;
        }
    }

    .actionButton {
        display: block;
        margin-bottom: 10px;
        text-align: center;

        &:not(:last-child) {
            margin-right: 0;
        }
    }

    .menuBar {
        text-align: center;

        .subscribeButton {
            margin: 10px 0;
        }
    }

    .tag {
        display: block;
        margin-left: 0;
        margin-top: 5px;
    }

    .sealedPricingDetails {
        display: block;
        margin-left: 0;
        margin-top: 3px;
    }
}
