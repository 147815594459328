@import '../../../../theme/bootstrap-custom-variables';

.buttons {
    padding-top: 5px;
    text-align: center;
}

.image {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 8px;
    width: 95%;
}

.title {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
}
