@import '../../../../../theme/bootstrap-custom-variables';

.listItem:focus,
.listItem:active {
    outline: none;
}

.statusIcon {
    float: left;
    font-size: 22px;
    padding-right: 15px;
}

.statusText {
    overflow: hidden;

    h3 {
        margin-bottom: 1px;
    }
}

.titleDisabled {
    color: $text-muted;
    opacity: 0.65;
}
