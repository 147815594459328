@import '~@opengov/capital-style/scss/tokens';

// legacy, untouched
.checkbox {
    line-height: 20px;
}

.inlineWithLabel {
    margin-left: 10px;
    vertical-align: top !important;
}

// sdv2 stuff
.formGroup {
    font-family: $base-font;

    .label {
        color: $color-gray-1000 !important;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
        margin-bottom: $unit-half;
    }

    input[type='checkbox'] {
        display: none;
    }

    label[title] {
        align-items: center;
        color: $color-gray-1000;
        display: flex;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        line-height: $line-height-default;
        margin: 0;
        padding: 0;
    }

    :global(.disabled) {
        label[title] {
            color: $color-gray-500;
        }
    }

    .optionIconSVGContainer {
        fill: none;
        height: $unit-3;
        margin-right: $unit-1;
        width: $unit-3;

        .optionIconSquare {
            fill: $color-white;
            height: 22.5px;
            stroke: $color-gray-400;
            stroke-width: 1.5px;
            width: 22.5px;

            &.disabledOption {
                fill: $color-gray-200;
                stroke: $color-gray-300;
            }
        }

        .optionIconSelectedSquare {
            @extend .optionIconSquare;

            fill: $color-primary-selection;
            stroke: $color-gray-600;

            &.disabledOption {
                fill: $color-gray-400;
                stroke: $color-gray-400;
            }
        }
    }
}
