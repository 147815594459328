@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    flex-direction: column;
    gap: $unit-2;

    label {
        font-family: $base-font-alt !important;
    }
}

.searchSelectContainer {
    :global(.form-group) {
        margin-bottom: 0 !important;
    }
}
