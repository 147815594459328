@import '../../../theme/bootstrap-custom-variables';

.container {
    min-height: 600px;
}

.pills {
    border: 1px solid $panel-default-border;
    border-radius: 5px;
    margin-bottom: 20px;

    &.hiddenPills {
        display: none;
    }
}
