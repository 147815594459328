.description {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
}

.minDecrement {
    color: #777;
    font-size: 14px;
}

.priceSection {
    align-items: center;
    color: #000;
    display: flex;
    justify-content: space-evenly;
    text-align: left;
}

.priceLabel {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.priceValue {
    color: #000;
    font-size: 18px;
    font-weight: 400;
}
