@import '~@opengov/capital-style/scss/tokens';

.section {
    align-items: center;
    background: none;
    border: 0;
    border-bottom: 1px solid $color-gray-200;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 0;
    width: 100%;

    &:hover {
        background-color: $color-gray-50;
    }

    .sectionSubContainer {
        border-left: $unit-half solid transparent;
        display: flex;
        padding-bottom: $unit-2;
        padding-left: $unit-2;
        padding-right: 20px;
        padding-top: $unit-2;

        &.active {
            background-color: $color-primary-hover-light;
            border-left: $unit-half solid $color-action;
        }
    }

    .sectionTitle {
        align-items: center;
        display: flex;
        flex: 4;

        .expandIconContainer {
            margin-right: 5px;

            i {
                color: $color-gray-700;
            }
        }

        .titleContainer {
            display: flex;
            text-align: left;

            .title {
                font-family: $base-font;
                font-size: $font-size-base;
                font-weight: $font-weight-medium;
                line-height: $line-height-default;
            }

            .shortName {
                color: $color-gray-700;
                display: block;
                font-size: $font-size-small;
                font-weight: $font-weight-regular;
            }

            .numbering {
                margin-right: $unit-half;
            }

            .titleSmall {
                font-size: $font-size-small;
            }

            .fontWeightNormal {
                font-weight: normal;
            }
        }

        .manualNumbered {
            display: block;
        }
    }

    .sectionStatus {
        align-self: center;
        flex: 1;
        text-align: right;
    }

    .invisible {
        opacity: 0;
    }
}

.subSection {
    .sectionSubContainer {
        padding-left: $unit-6;
    }
}

.sectionIcon {
    font-family: $base-font;
    font-size: $font-size-small;

    i {
        font-size: $font-size-base;
    }

    &.error {
        color: $color-error-700;
    }

    &.comments {
        color: $color-action;
    }
}
