.modalBody {
    padding: 15px 0 0 !important;
}

.revisionDiff {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;

    :global(.criteria-item-lock-icon-container) {
        display: none !important;
    }
}

.revisionSearch {
    padding-left: 15px;
    padding-right: 15px;
}
