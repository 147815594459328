@import '../../theme/bootstrap-custom-variables';

.container {
    color: $text-muted;
    cursor: grab;
    display: inline-block;
    padding: 0 3px;
    white-space: nowrap;

    i:first-child {
        margin-right: 3px;
    }

    &.disabled {
        cursor: not-allowed;
    }
}
