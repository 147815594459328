.versionHeader {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    padding-bottom: 8px;
    padding-top: 8px;
}

.backButton {
    padding: 3px !important;
}
