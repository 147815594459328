.titleContainer {
    margin-bottom: 20px;
}

.title {
    display: inline-block;
    margin-right: 8px;
}

.statusLabel {
    position: relative;
    top: 7px;
    vertical-align: top !important;
}

.buttonPaddingRight {
    margin-right: 8px;
}
