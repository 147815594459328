@import '../../theme/bootstrap-custom-variables';
@import '@opengov/capital-style/scss/tokens';

.cardContentContainer {
    background-color: #f7f9fa;
}

.cardContent {
    background-color: #fff;
    border: 1px solid $panel-default-border;
    padding: $unit-3;
}
