@import '~@opengov/capital-style/scss/tokens';

.titleContainer {
    align-items: center;
    display: flex;
    gap: $unit-2;

    h1 {
        font-size: $font-size-xxlarge;
        line-height: $line-height-large;
    }
}

.statusLabel {
    font-size: 12px;
    padding: $unit-half $unit-1 $unit-half $unit-1;
}

.actionButtons {
    align-items: center;
    display: flex;
    gap: $unit-1;
    justify-content: flex-end;
}

.formErrorContainer {
    padding-left: 9px;
    padding-right: 9px;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    gap: $unit-2;
    margin-left: -15px;
    margin-right: -15px;
    padding: $unit-2 $unit-7;

    @media (max-width: 900px) {
        padding: $unit-2;

        .actionButtons {
            align-items: center;
            display: flex;
            gap: $unit-1;
            justify-content: center;
            padding-top: $unit-2;
        }
    }
}
