@import '~@opengov/capital-style/scss/tokens';

.certifications {
    gap: $unit-1;

    label {
        font-weight: $font-weight-medium;
    }
}

.card {
    border: $border-gray-200;
    border-radius: $unit-half;
    gap: $unit-2;

    &.editing {
        align-items: flex-start;
        background: $color-bg-secondary;
        flex-direction: column;
        gap: $unit-2;

        .buttons {
            gap: $unit-1;
        }

        .headingText {
            color: $color-fg-primary;
        }

        .row {
            gap: $unit-3;
        }
    }

    .noCertificationsListed {
        color: $color-gray-800;
    }

    .certificationType {
        gap: $unit-1;

        .certificationDetails {
            background-color: $color-bg-tertiary;
            border: 1px solid $color-fg-border-secondary;
            color: $color-fg-secondary;

            .certificationTitle {
                font-size: $font-size-minimum !important;
                font-weight: $font-weight-semi-bold;
            }

            .certificationDescription {
                font-size: $font-size-minimum !important;
            }
        }
    }

    .deleteButton {
        align-items: center;
        color: $color-error-800;
        display: flex;
        gap: $unit-half;
        padding: 0;
    }
}

.verifiedCertification {
    background-color: $color-bg-secondary;
    border: 1px solid $color-fg-border-primary;
    gap: $unit-1;
}

.addCertificationButton {
    align-items: center;
    display: flex !important;
    gap: $unit-half !important;
}
