.colToggle {
    display: inline-block;
    width: 65px;
}

.colToggleContainer {
    margin-bottom: 4px;
}

.colToggleLabel {
    display: inline-block;
    font-size: 13px !important;
    margin-top: 5px;
    padding-right: 4px;
    vertical-align: top;
}

.colToggleTitle {
    @extend .colToggleLabel;
    font-size: 16px !important;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 0;
}

.well {
    display: inline-block;
}
