.container {
    cursor: pointer;
    font-size: 1.2em;
    margin-top: 16px;
}

.stat {
    font-weight: bold;
    text-align: right;
}

.progressBar {
    height: 3px !important;
    margin-bottom: 1px !important;
    margin-top: 3px;
}

.progressPercent {
    font-style: italic;
    text-align: right;
}
