.profilePicture {
    margin-right: 5px;
}

.addButton {
    border-radius: 50% !important;
}

.listText {
    margin-right: 5px;
}

.extraUsers {
    margin-left: 5px;

    &:hover {
        cursor: pointer;
    }
}
