@import '../../theme/bootstrap-custom-variables';

.logo {
    border: 1px solid $gray-light;
    max-width: 100%;
    padding: 3px;
    vertical-align: top;
    width: 100px;
}

.logoContainer {
    padding-right: 0 !important;
}

.name {
    margin-bottom: 5px;
}

@media (max-width: $screen-xs-max) {
    .logoContainer {
        margin-bottom: 10px;
        padding-right: 15px !important;
        text-align: center;
    }
}
