.projectTypeSelect {
    display: inline-block;
    text-align: left;
    width: 120px;
}

.projectTypeSelectLabel {
    margin-right: 8px;
    margin-top: 8px;
    vertical-align: top;
}
