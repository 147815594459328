@import '~@opengov/capital-style/scss/tokens';

.main {
    flex: 1;
    padding: $unit-3;

    &.borderlessInputs {
        :global(.form-group) {
            margin-bottom: 0;
        }

        :global(.form-control),
        :global(.form-control:focus) {
            border: 0;
            box-shadow: none;
            font-family: $base-font;
            font-size: $font-size-medium;
            font-weight: $font-weight-medium;
            line-height: $line-height-medium;
            padding: 0;
        }

        :global(.mce-edit-focus:focus-visible) {
            outline: 0;
        }
    }

    &.disabled {
        background-color: $color-gray-100;
    }
}

.withOnClick {
    cursor: pointer;
}
