@import '~@opengov/capital-style/scss/tokens';

.container {
    display: flex;
    font-family: $base-font !important;
    font-size: $font-size-default !important;
}

.item a {
    background-color: $color-white !important;
    border-color: $color-fg-border-tertiary !important;
    color: $color-fg-secondary !important;
}

.activeItem span {
    background-color: $color-primary-selection !important;
    border-color: $color-fg-border-tertiary !important;
}

.navButton {
    align-items: center;
    color: $color-fg-secondary !important;
    display: flex;
    gap: $unit-half;
    padding: 6px $unit-1;
}

.previous {
    margin-right: $unit-2;
}

.next {
    margin-left: $unit-2;
}
