@import '../../../../../theme/bootstrap-custom-variables';

.titleText {
    font-size: 1.2em;
    margin-bottom: 8px;
    text-align: center;
}

.previewTitle {
    @extend .titleText;
    color: $brand-primary;
}

.templateInUse {
    margin-top: 10px;
}

@media (max-width: $screen-sm-max) {
    .previewTitle {
        margin-top: 30px;
    }
}
