@import '../../../../../theme/bootstrap-custom-variables';

.addBtn {
    padding-top: 25px;
}

@media (max-width: $screen-xs-max) {
    .addBtn {
        padding-top: 0;
        text-align: right;
    }
}
