@import '../../../../../../../theme/bootstrap-custom-variables';

.buttonGroup {
    margin-top: 10px;
    text-align: center;
}

.submitBtn {
    margin-left: 10px;
}
