.footer {
    font-size: 1.1em;
    padding-bottom: 15px !important;
    padding-top: 10px !important;
}

.footerWarning {
    margin-bottom: 2px;
}

.navItems {
    margin-bottom: 20px !important;

    @media print {
        margin-bottom: 0 !important;
    }
}

.panelBody {
    min-height: 600px;
}

.phaseSelectContainer {
    display: inline-block;
    vertical-align: top;

    label {
        margin-top: 8px;
        vertical-align: top;
    }
}

.phaseSelectForm {
    display: inline-block;
    margin-bottom: 5px !important;
    text-align: left;
    width: 160px;
}
