@import '../../theme/bootstrap-custom-variables';

.text {
    display: inline-block;
    font-weight: 500;
    margin-top: 3px;
    vertical-align: top;
}

.modeText {
    @extend .text;
    text-align: left;
    width: 60px;
}
