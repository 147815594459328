@import '~@opengov/capital-style/scss/tokens';

.helpIcon {
    cursor: pointer;
    font-size: $unit-2 !important;
    margin-top: $unit-1 / 2;
}

.btn {
    font-family: $base-font;
    font-weight: 500;
    font-size: $font-size-medium;
}

.container {
    font-family: $base-font-alt;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .header {
        background-color: $color-white;
        border-bottom: 1px solid $color-gray-200;
        border-top: 1px solid $color-gray-200;
        display: flex;
        align-items: center;
    }

    .content {
        padding: $unit-4 $unit-15;
        padding-bottom: $unit-1;
        background-color: $color-white;
    }
}
