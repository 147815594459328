@import '~@opengov/capital-style/scss/tokens';

.cell {
    align-items: center;
    color: $color-gray-1000 !important;
    display: flex;
    font-weight: 400 !important;
    padding-left: $unit-3 !important;
    padding-right: $unit-3 !important;

    :global(.ag-react-container) {
        align-items: center;
        display: flex;
    }
}

.notesCell {
    @extend .cell;

    :global(.ag-react-container) {
        overflow: hidden;
    }
}

.header {
    background-color: $color-gray-50 !important;
    color: $color-gray-1000 !important;
    font-weight: 600 !important;
    padding-left: $unit-3 !important;
    padding-right: $unit-3 !important;
}

.headerWhite {
    color: $color-gray-1000 !important;
    font-weight: 600 !important;
    background-color: $color-white !important;
    padding-left: $unit-3 !important;
    padding-right: $unit-3 !important;
}

.iconCells {
    display: inline-block;
    padding-left: $unit-3 !important;
    padding-right: $unit-3 !important;
}

.borderlessTable {
    :global(.ag-root-wrapper),
    :global(.ag-header) {
        border: 0 !important;
    }
}

.tooltip {
    color: $color-gray-700 !important;
    height: $unit-2 !important;
    margin-left: $unit-1 !important;
    width: $unit-2 !important;
}

.flex {
    align-items: center !important;
    display: flex !important;
}

.dropzone {
    background-color: $color-white !important;
    border: 0 !important;
    padding: 0 !important;
    width: fit-content !important;
}

.dropzoneContainer {
    padding: 0 !important;
}
