@import '~@opengov/capital-style/scss/tokens';

.summaryContainer {
    .summaryItems {
        display: flex;
        flex-direction: column;
        gap: $unit-2;
    }

    .summaryLine {
        align-items: baseline;
        align-self: stretch;
        color: $color-fg-secondary;
        display: flex;
        font-size: $unit-2;
        justify-content: space-between;

        .secondaryText {
            font-weight: 400;
        }

        .summaryText {
            font-size: $unit-2;
        }
    }

    .grandTotal {
        color: $color-fg-primary;
        font-size: $font-size-medium;
    }

    .grandTotalLabel {
        font-weight: $font-weight-medium;
    }

    .grandTotalAmount {
        font-weight: $font-weight-bold;
    }
}
