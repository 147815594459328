@import '../../../theme/bootstrap-custom-variables';

.checklistsContainer {
    min-height: 350px;
    padding-bottom: 30px;
}

.contractTitle {
    color: $gray-dark;

    &:hover {
        text-decoration: underline $gray-dark;
    }
}

.filterBtn {
    text-align: left !important;
}

.logo {
    border: 1px solid $gray-light;
    max-width: 100%;
    padding: 3px;
    vertical-align: top;
    width: 50px;
}

.logoContainer {
    text-align: right;
}

.mutedInfo {
    color: $text-muted;
    font-style: italic;
}

.statusLabel {
    margin-bottom: 10px;
}
