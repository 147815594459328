@import '../../../../../theme/variables-bootstrap-default';

.projectIdStyles {
    color: $gray-dark;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    padding-top: 5px;
    width: fit-content;
}

.titleStyles {
    color: $brand-primary;
    display: block;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.renewalStyles {
    color: $brand-warning;
    font-size: 12px;
    font-weight: 700;
    font-style: italic;
    line-height: 16px;
    padding-top: 5px;
    width: fit-content;
}
