@import '~@opengov/capital-style/scss/tokens';

.attachmentRowItemButton {
    margin-left: 8px;
}

.linkContainer {
    border-top: 1px solid $color-gray-200;
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;

    .link {
        color: $color-action;
    }
}

.linkContainerBottomBordered {
    border-bottom: 1px solid $color-gray-200;
}
