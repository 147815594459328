.govliaTable {
    margin-bottom: 20px;
    margin-top: 20px;
}

.govliaTextLogo {
    margin-right: 5px;
    margin-top: 8px;
    max-width: 100%;
    vertical-align: top;
    width: 80px;
}

.govliaLogo {
    margin-left: 5px;
    max-width: 100%;
    vertical-align: top;
    width: 20px;
}

.govliaInfo {
    margin-top: 8px;
    text-align: center;
}
