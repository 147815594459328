@import '~@opengov/capital-style/scss/tokens';

.formGroup {
    .input {
        color: $color-gray-400;
        font-family: $base-font;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;

        :global(input[type='radio']) {
            cursor: pointer;
            font-weight: $font-weight-medium;
            margin-left: -16px !important;
            margin-right: $unit-1;
            position: relative;
            transform: scale(1.5);
        }
    }

    .errorInput {
        :global(input[type='radio']) {
            appearance: none;
            width: 13px;
            height: 13px;
            border: 1px solid #992f3a;
            border-radius: 50%;
            outline: none;
            background: #fff;
        }
    }

    .label {
        color: $color-gray-1000 !important;
        font-family: $base-font;
        font-size: $font-size-small;
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
        margin-bottom: 0;
    }

    .noMarginBottom {
        margin-bottom: 0;
    }

    label[title] {
        color: $color-gray-1000 !important;
        display: block;
        font-size: $font-size-default !important;
        font-weight: $font-weight-regular !important;
        margin-bottom: calc($unit-1 + 2px);

        &:disabled {
            color: $color-gray-700 !important;
        }
    }

    .help {
        color: $color-gray-700;
        display: block;
        font-size: $font-size-small;
        line-height: $line-height-small-font;
        margin: 0;
        margin-bottom: $unit-half;
    }

    &:global(.has-error) .helpErrorBlock {
        color: $color-fg-error;
    }
}

.formGroupInline {
    display: flex;
    flex-wrap: wrap;

    .label {
        flex: 0 0 100%;
        margin-bottom: $unit-half;
    }

    .radioItemContainer {
        &:not(:last-child) {
            margin-right: $unit-3;
        }

        .radioItemHelp {
            color: $color-gray-700;
            font-weight: $font-weight-regular;
            margin-bottom: calc($unit-1 + 2px);
            margin-left: $unit-3;
        }
    }

    .radioItemContainerWithHelp {
        label[title] {
            margin-bottom: 0;
        }
    }
}
