.exampleHeading {
    font-weight: bold;
}

.exampleInput {
    height: 100px !important;
    margin-bottom: 30px;
}

.modalTitle {
    text-align: center;
}

.modalBody {
    padding: 20px 30px !important;
}

.modal {
    max-width: 580px;
}
