@import '~@opengov/capital-style/scss/tokens';

.infoContainer {
    align-items: center;
    display: flex;
    font-size: $font-size-base;
    justify-content: space-between;
    padding: $unit-2;
}

.infoMessageContainer {
    align-items: start;
    background-color: $banner-background-info;
    border: $banner-border-info;
    display: flex;
    gap: $unit-2;
    padding: $unit-2;
}

.infoTitleContainer {
    align-items: center;
    display: flex;
    gap: $unit-1;
}

.infoTitle {
    color: $color-fg-primary;
    font-weight: $font-weight-medium;
}

.infoDescription {
    color: $color-fg-primary;
    font-weight: $font-weight-regular;
}

.icon {
    background-color: $banner-icon-info;
    border-radius: $border-radius-default;
    color: $banner-background-info;
    font-size: $font-size-large;
    padding: 5px 12px;
}

.jumpToErrorBtn {
    color: $color-fg-link;
    font-size: $font-size-base;
}
