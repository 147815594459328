@import '~@opengov/capital-style/scss/tokens';

.accountNumberInput {
    :global(.MuiOutlinedInput-root) {
        background-color: $color-white;
    }

    :global(.MuiFormControl-root) {
        padding-left: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    :global(.MuiFormHelperText-root.Mui-error) {
        color: $color-fg-error;
        font-size: $font-size-small;
        margin-left: 0;
        // Override `white-space: nowrap` from CDS component
        // https://github.com/OpenGov/component-library/blob/d5743441c491a7aae106e04974fd9095df4088b1/applications/components-account-number-input/src/AccountNumberInput/AccountNumberInput.tsx#L950
        white-space: normal;
    }

    :global(.MuiFormHelperText-root.Mui-error > .MuiFormHelperText-root.Mui-error) {
        display: contents;
    }

    :global(.MuiFormHelperText-root.Mui-error > .MuiFormHelperText-root.Mui-error::before) {
        content: '\f071';
        font-family: 'FontAwesome', $base-font;
        margin-right: $unit-1;
    }

    :global(.MuiInputBase-root) {
        border-radius: $border-radius-default;

        :global(.MuiAutocomplete-endAdornment) {
            // To allow the "clear" button to be visible even when the tooltip is on top of it.
            z-index: 1600;
        }

        input {
            height: 20.5px !important;
            padding: 4.75px !important;
        }
    }

    .loadingAccountData {
        border: 1px solid $color-gray-400;
        border-radius: $border-radius-default;
        cursor: not-allowed;
        height: 32px;
        padding: $unit-quarter $unit-2;
        width: 100%;
    }

    .accountDescription {
        color: $color-fg-secondary;
        font-size: $font-size-small;
        padding-top: $unit-half;
    }

    .cachedAccountNumber {
        @extend .loadingAccountData;
        font-family: $base-font-alt;
        font-size: 1.14rem;
    }

    .formError {
        align-items: baseline;
        align-self: stretch;
        color: $color-fg-error;
        display: flex;
        gap: $unit-half;
        font-size: $font-size-small;
    }

    .nonExpenseAccountInformation {
        margin-top: 0;
        margin-bottom: 0;
    }

    .inputError {
        :global(.MuiOutlinedInput-notchedOutline) {
            border: 1px solid $color-fg-error;
            border-color: $color-fg-error !important;
        }
    }
}
