@import '../../../../../theme/bootstrap-custom-variables';

.attachmentTable {
    li {
        padding: 10px 15px;

        &:not(:last-child) {
            border-bottom: 1px solid $list-group-border;
        }
    }
}
