@import '~@opengov/capital-style/scss/tokens';

.page {
    font-family: $base-font-alt !important;
}

.pageContainer {
    border-top: 1px solid $color-fg-border-primary;
}

.bodyContainer {
    padding-left: 0;
    padding-right: 0;
}

.historySidebarContainer {
    background-color: $color-white;
    border-left: 1px solid $color-fg-border-primary;
    height: 100vh;
    overflow-y: auto;
    padding: $unit-2 $unit-3;
    position: sticky;
    top: 0;
}
