@import '../variables';

.selectAllContainer {
    margin-bottom: 5px;
}

.selectAllIcon {
    color: $select-color;
    cursor: pointer;
}

.copyBtnContainer {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}

.itemCol {
    padding-left: 0;
}

.listItem {
    cursor: pointer;
    margin-bottom: 10px;

    &:hover .scopeItem {
        border-color: $select-color;
    }

    &:hover .selectIcon {
        color: $select-color;
    }

    &:hover .scopeItemTitle {
        text-decoration: underline;
    }
}

.selectIconWrapper {
    margin-top: 20px;
}

.selectIcon {
    color: $muted-color;
    text-align: right;
}

.selectedIcon {
    @extend .selectIcon;
    color: $select-color;
}

.scopeItem {
    border: 1px solid $muted-color;
    padding: 10px;

    table {
        width: 100% !important;
    }
}

.scopeItemTitle {
    color: $select-color;
    margin-bottom: 8px;
}

.selectedItem {
    @extend .scopeItem;
    background-color: $list-group-hover-bg;
    border-color: $select-color;

    &:hover {
        background-color: darken($list-group-hover-bg, 5%);
    }
}

.sidebar {
    margin-bottom: 15px;
    text-align: right;
}

.backBtn {
    margin-bottom: 15px;
}

.projectTitle {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}

.scopeSummary {
    color: $text-muted;
    margin-bottom: 20px;
    text-align: center;
}
