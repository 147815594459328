@import '~@opengov/capital-style/scss/tokens';

.mutedBackground {
    background-color: $color-gray-600;
}

.mutedText {
    color: $color-gray-700;
}

.actionBackground {
    background-color: $color-action;
}

.actionText {
    color: $color-action;
}

.successBackground {
    background-color: $color-bg-success-dark;
}

.externalLink {
    color: $color-action;
    margin-left: auto;
}
