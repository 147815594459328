.controlButtons {
    margin-top: 10px;
    text-align: center;
}

.removeNeedsReviewButton {
    margin-right: 10px;
}

.preview {
    margin-top: 50px;
}

.previewTitle {
    margin-bottom: 20px;
    text-align: center;
}

.toggleText {
    display: inline-block;
    margin-right: 5px;
    margin-top: 3px;
    vertical-align: top;
}
