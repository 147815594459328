.noProjectsContainer {
    margin-top: 100px;
    text-align: center;
}

.noProjectsText {
    font-size: 24px;
    margin-bottom: 30px;
}

.noProjectsHeader {
    font-size: 30px;
    margin-bottom: 8px;
}

.createBtn {
    font-size: 24px !important;
    padding: 12px 20px !important;
}
