@import '../../../theme/bootstrap-custom-variables';

.topHeader {
    margin-bottom: 30px;
    text-align: center;
}

.subHeader {
    color: $gray-dark;
    margin-bottom: 20px;
    text-align: center;
}

.subText {
    color: $gray-dark;
    margin-bottom: 20px;
    text-align: center;
}

.emailInput {
    margin: 0 auto;
    max-width: 500px;
}

.submit {
    padding-top: 25px;
}

.icon {
    margin-right: 5px;
}

.noSubscription {
    color: $gray-dark;
    margin-bottom: 30px;
    text-align: center;
}
