@import '../../../theme/bootstrap-custom-variables';

.companyName {
    font-size: 16px;
    font-style: italic;
    line-height: normal;
    margin-bottom: 3px;
}

.contact {
    margin-bottom: 3px;
}

.contractDateName {
    display: inline-block;
    font-weight: bold;
    text-align: left;
    width: 100px;
}

.contractDate {
    display: inline-block;
    text-align: right;
    width: 100px;
}

.contractDocument {
    margin-top: 10px;
}

.contractDocumentButton {
    overflow: hidden;
    padding: 3px !important;
    text-align: left !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    width: 80%;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: 70%;
    }
}

.dateIcon {
    color: $btn-warning-bg;
    margin-right: 3px;
}

.detail {
    @media (max-width: $screen-xs-max) {
        margin-top: 5px;
        text-align: center;
    }
}

.statusText {
    font-weight: bold;
}

.title {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 3px;
}

.linkContainer {
    .primary {
        color: $btn-primary-bg;
    }

    .success {
        color: $btn-success-bg;
    }

    .muted {
        color: $gray-light;
    }

    &:hover {
        .title {
            text-decoration: underline;
        }

        .primary {
            color: darken($btn-primary-bg, 10%);
        }

        .success {
            color: darken($btn-success-bg, 10%);
        }

        .muted {
            color: darken($gray-light, 10%);
        }
    }
}
