@import '../../../../theme/bootstrap-custom-variables';

$image-width: 60px;
$padding: 15px;
$image-container: $image-width + $padding * 2;

.userInfo {
    margin-bottom: 5px;
}

.logoContainer {
    padding: 0 $padding;
    position: absolute;
    width: $image-container;
}

.logo {
    border: 1px solid $table-border-color;
}

.commentContainer {
    min-height: $image-width + 2px !important;
    padding-left: $image-container !important;
}

.questionContainer {
    margin-bottom: 30px;
    padding: 10px;
    padding-bottom: 20px;

    &:last-child {
        border-bottom: 0;
    }
}

.subject {
    margin-bottom: 10px;
}

.reqBadge {
    margin-left: 5px;
}

.listGroup {
    margin-bottom: 0 !important;
    margin-left: 20px;
}

.verified {
    font-size: 9px;
    margin-left: 3px;
}

.attachmentsList {
    margin-top: 10px;
}

.commentInput {
    background-color: $background-gray !important;
}

.selectedField {
    background-color: $light-highlight-yellow;
}

.publicSidebar {
    margin-bottom: 20px;
    margin-top: 10px;
}

.govSidebar {
    padding-top: 10px;
    text-align: center;
}

.govSidebarTitle {
    margin-bottom: 5px;
}

.awaitingReviewCount {
    margin-bottom: 3px;
}

.questionsTOC {
    border: 1px solid $panel-default-border;
    margin-top: 20px;
    max-height: 320px;
    overflow: -moz-scrollbars-vertical;
    overflow-y: auto;
    text-align: left;
}

.questionsTOC::-webkit-scrollbar {
    appearance: none;
}

.questionsTOC::-webkit-scrollbar:vertical {
    width: 11px;
}

.questionsTOC::-webkit-scrollbar:horizontal {
    height: 11px;
}

.questionsTOC::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #fff; /* should match background, can't be transparent */
    border-radius: 8px;
}

.questionLink {
    padding: 6px 10px;

    &:hover {
        background-color: $list-group-hover-bg;
        cursor: pointer;
    }
}

.questionText {
    padding: 5px 0;
}

.questionPicture {
    float: right;
    padding-left: 5px;
}

.questionActive {
    background-color: $breadcrumb-color;

    &:hover {
        background-color: $breadcrumb-color;
        cursor: default;
    }
}

.closeQuestionFormButton {
    padding: 0 !important;
}

.progressBar {
    margin-bottom: 0 !important;
}

.questionComment {
    .editButton {
        display: none;
    }

    &:hover .editButton {
        display: inline-block;
    }
}

.releaseButton {
    margin-top: 10px;
}

@media (max-width: $screen-sm-max) {
    .approvalMenu {
        text-align: center !important;
    }
}

.formLinkDiv {
    margin-bottom: 15px;
}
