@import '~@opengov/capital-style/scss/tokens';

.container {
    border-bottom: 1px solid $color-fg-border-primary;
    padding: $unit-2 0;

    label {
        font-size: $font-size-default;
        font-weight: $font-weight-semi-bold;
    }
}
