@import '../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.container {
    border: 1px solid $input-border;
    border-radius: 4px;
}

.borderless {
    .container {
        border: 0;
        border-radius: 0;

        :global(.tox-tinymce) {
            border-radius: 0;
        }
    }
}

.hasError {
    @extend .container;
    border-color: $state-danger-text;
}

.hasSuccess {
    @extend .container;
    border-color: $state-success-text;
}

.hidden {
    height: 0;
    visibility: hidden;
}

.loadingBlock {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingGradientAnimation;
    animation-timing-function: linear;
    background: $color-gray-100;
    background: linear-gradient(
        to right,
        $color-gray-100 8%,
        $color-gray-200 38%,
        $color-gray-100 54%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    height: 100%;
    padding-bottom: 88px;
    padding-left: 32px;
    user-select: none;
    width: 100%;

    .background {
        display: none;
    }

    img {
        visibility: hidden;
    }
}

.loadingBlock * {
    border: 0 !important;
    color: transparent !important;
}

.padded {
    :global(.mce-content-body) {
        padding: 8px;
    }
}

@keyframes loadingGradientAnimation {
    0% {
        background-position: 100% 0%;
    }

    100% {
        background-position: -100% 0%;
    }
}

:global(.tox-tinymce) {
    border: 0 !important;
}

:global(.tox-tbtn svg) {
    fill: $color-gray-1000 !important;
}

:global(.tox-tbtn--disabled svg) {
    fill: $color-gray-500 !important;
}

:global(.tox .tox-tbtn:hover) {
    background: $color-primary-hover-light !important;
}

:global(.tox-tbtn--enabled, .tox .tox-tbtn--enabled:hover) {
    background: $color-primary-hover !important;
}

:global(.tox-tbtn__select-chevron svg) {
    fill: $color-gray-400 !important;
}

:global(.mce-content-body) {
    min-height: 100px;
}

.openGovStyle {
    word-break: break-word;

    *:focus-visible {
        outline: none;
    }

    :global(.mce-content-readonly) {
        background-color: $color-gray-100;
    }

    :global(.control-label) {
        color: $color-gray-1000 !important;
        font-family: $base-font;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
        margin-bottom: $unit-half;
    }

    :global(.help-block) {
        font-size: $font-size-small;
    }
}
