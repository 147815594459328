@import '~@opengov/capital-style/scss/tokens';

.page {
    font-family: $base-font-alt !important;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: $unit-half;
    padding-bottom: $unit-half;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.filters {
    background: $color-white;
    border-top: 1px solid $color-fg-border-secondary;
}

.headerButtons {
    display: flex;
    gap: $unit-1;
    height: fit-content;
}

.newRequestBtn {
    height: fit-content;
    padding: $unit-1;
}

.accountComponent {
    width: 250px;
}

.accountContainer {
    display: flex;
    gap: $unit-half;
}

.accountLabel {
    font-weight: $font-weight-regular;
    margin-top: $unit-1;
}

.searchByAccount {
    font-weight: $font-weight-semi-bold;
}
