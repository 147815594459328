@import '../../theme/bootstrap-custom-variables';

.container {
    min-height: 500px;
}

.logo {
    border: 1px solid $gray-light;
    display: inline-block;
    margin-right: 20px;
    max-width: 100%;
    padding: 3px;
    vertical-align: top;
    width: 80px;
}

.info {
    display: inline-block;
    padding: 15px 0;

    h4 {
        margin-bottom: 3px;
    }
}
