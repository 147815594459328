@import '~@opengov/capital-style/scss/tokens';

.container {
    font-family: $base-font;

    .headerEditContainer {
        background-color: $color-white;
        border: 1px solid $color-gray-200;
        padding: $unit-3;
    }

    .title {
        font-size: $font-size-medium;
        font-weight: $font-weight-medium;
        line-height: $line-height-medium;
    }

    .deleteButton {
        color: $color-error-700;
        padding: 0;
    }

    .body {
        margin-top: $unit-2;
    }
}
