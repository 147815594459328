@import '../../../../theme/bootstrap-custom-variables';

.instructions {
    padding-bottom: 16px;
    text-align: center;
}

.title {
    color: $gray-dark;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 16px;

    .subText {
        color: $gray-light;
        font-size: 14px;
        font-weight: 400;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 34px;
    justify-content: flex-end;
    margin-top: 10px;
}
