@import '../../../../theme/bootstrap-custom-variables';

.approveButton {
    margin-right: 20px;
}

.statusText {
    bottom: 5px;
    margin-left: 5px;
    position: relative;
}

.infoText {
    margin-top: 5px;
}

.editLink {
    &:hover {
        cursor: pointer;
    }

    &:visited {
        color: $brand-primary;
    }
}
