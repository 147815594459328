@import '../../../../theme/bootstrap-custom-variables';

.lineItemAwards {
    margin-top: 20px;
}

.completeEvaluationButtonWrapper {
    text-align: center;
}

.noProposals {
    margin: 40px 0;
    text-align: center;
}

.noProposalsHeader {
    font-size: 22px;
    margin-bottom: 20px;
}

.vendorName {
    margin-top: 40px;
}

.toolbar {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.vendorFilter {
    padding: 0;

    @media (max-width: $screen-xs-max) {
        flex-grow: 1;
        padding-right: 10px;
    }
}
