@import '../../../theme/bootstrap-custom-variables';

.exportBody {
    font-size: 14px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;

    a:hover {
        text-decoration: none;
    }

    i {
        color: $govlist-blue;
        cursor: pointer;
    }
}

.downloadText {
    color: $govlist-blue;
    cursor: pointer;
    font-size: 1.1em;
}

.googleText {
    color: $govlist-blue;
    font-size: 1.1em;
    margin-top: 15px;
}

.preview {
    border: 0;
    height: 550px;
    width: 100%;
}

.googleSave {
    margin-top: 9px;
}

.disclaimer {
    color: #000;
    font-size: 9px;
    font-style: italic;
}

.introFile {
    margin-bottom: 10px;
}

.postDownload {
    color: $govlist-blue;
    cursor: pointer;
    font-size: 1.1em;
    margin-top: 10px;
}

.introRow {
    margin-bottom: 50px;
}
