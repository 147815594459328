.detailsContainer {
    margin-left: 30px;
    margin-top: 10px;
}

.scoringCriterium {
    margin-bottom: 10px;
}

.toggleButton {
    font-size: 12px;
}
