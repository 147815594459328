@import '../../../../../theme/variables-bootstrap-default';

.circleIcon {
    font-size: 0.7em;
    margin-right: 5px;
}

.danger {
    color: $color-fg-error;
}

.disabledModule {
    color: $gray-light;
    font-size: 1em;
    font-style: italic;
    margin-left: 10px;
}

.disabled,
.disabled:hover {
    cursor: default;
    pointer-events: none;
    text-decoration: none;
}

.itemDetail {
    font-size: 1em;
    font-style: italic;
    padding-left: 27px;

    &.pending {
        padding-left: 0;
    }
}

.lock {
    color: $gray-light;
}

.panelBody {
    max-height: 175px;
    overflow-y: scroll;
}

.panelHeader {
    background-color: $color-bg-tertiary;
    border-color: $color-fg-border-secondary;
    color: $color-fg-primary;

    &:hover {
        color: $color-fg-primary;
    }

    &.noProjects {
        background-color: #dbf0db;
    }
}

.panelIcon {
    margin-right: 5px;
}

.projectsPanel {
    height: 415px;
    margin-bottom: 15px;
    margin-top: 10px;
    overflow-y: scroll;
}

.panelTitle {
    text-align: center;
}

.projectTitle {
    font-weight: bold;
}

.success {
    color: $color-fg-success-small;
}

.warning {
    color: $color-fg-warning-large;
}
