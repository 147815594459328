@import '../../../theme/bootstrap-custom-variables';

.dropBox {
    background-color: $lightest-grey;
    border-color: $brand-primary;
    border-radius: 5px;
    border-style: dashed;
    border-width: 2px;
    cursor: pointer;
    padding: 8px;
    width: 100%;

    &:active,
    &:focus,
    &:hover {
        background-color: $color-primary-hover-a50;
        border-style: solid;
    }
}

.dropText {
    font-style: italic;
    margin-bottom: 5px;
}

.dropIcons i {
    color: $brand-primary;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }
}

.labelContainer {
    font-weight: bold;
    width: 100%;
}
