@import '../../theme/bootstrap-custom-variables';

$font-size-md: 36px;
$icon-container-size-md: 72px;

$font-size-lg: 50px;
$icon-container-size-lg: 100px;

$line-width-md: 100px;
$line-width-lg: 100px;

$icon-with-line-margin-md: 40px;
$icon-with-line-margin-lg: 70px;
$margin-subtraction: 20px;

$success: darken($btn-success-bg, 10%);

.container {
    display: inline-block;
    vertical-align: top;
}

.lineContainer {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 0;
}

.nextLine {
    border-top: 1px dashed $text-muted;
    display: inline-block;
    position: absolute;
    vertical-align: top;
}

.nextLine-md {
    @extend .nextLine;
    left: ($icon-with-line-margin-md - $line-width-md) * 0.5;
    top: $font-size-md;
    width: $line-width-md;
}

.nextLine-lg {
    @extend .nextLine;
    left: ($icon-with-line-margin-lg - $line-width-lg) * 0.5;
    top: $font-size-lg;
    width: $line-width-lg;
}

.activeNextLine {
    border-color: $success;
}

.activeNextLine-md {
    @extend .nextLine-md;
    @extend .activeNextLine;
}

.activeNextLine-lg {
    @extend .nextLine-lg;
    @extend .activeNextLine;
}

.iconContainer {
    display: inline-block;
    text-align: center;
    width: 150px;
}

.iconContainer:hover {
    cursor: pointer;

    .incompleteIcon {
        background-color: darken(#fff, 2%);
        border-color: darken($text-muted, 10%);
        color: darken($text-muted, 10%);
    }

    .completeIcon {
        background-color: darken($success, 10%);
    }

    .unselectedText {
        border-color: darken($text-muted, 10%);
        border-width: 1px;
        color: darken($text-muted, 10%);
    }

    .completedText {
        border-color: darken($success, 10%);
        border-width: 1px;
        color: darken($success, 10%);
    }
}

.addMargin-md {
    margin-left: $icon-with-line-margin-md;
}

.addMargin-lg {
    margin-left: $icon-with-line-margin-lg;
}

.icon {
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
}

.icon-md {
    @extend .icon;
    font-size: $font-size-md;
    height: $icon-container-size-md;
    line-height: $icon-container-size-md;
    width: $icon-container-size-md;
}

.icon-lg {
    @extend .icon;
    font-size: $font-size-lg;
    height: $icon-container-size-lg;
    line-height: $icon-container-size-lg;
    width: $icon-container-size-lg;
}

.incompleteIcon {
    background-color: #fff;
    border: 1px dashed $text-muted;
    color: $text-muted;
}

.incompleteIcon-md {
    @extend .icon-md;
    @extend .incompleteIcon;
}

.incompleteIcon-lg {
    @extend .icon-lg;
    @extend .incompleteIcon;
}

.completeIcon {
    background-color: $success;
    border: 1px solid $state-success-text;
    color: #fff;
}

.completeIcon-md {
    @extend .icon-md;
    @extend .completeIcon;
}

.completeIcon-lg {
    @extend .icon-lg;
    @extend .completeIcon;
}

.incompleteSelectedIcon {
    background-color: $white;
    border: 1px dashed $success;
    color: $success;
}

.incompleteSelectedIcon-md {
    @extend .icon-md;
    @extend .incompleteSelectedIcon;
}

.incompleteSelectedIcon-lg {
    @extend .icon-lg;
    @extend .incompleteSelectedIcon;
}

.completeSelectedIcon {
    background-color: lighten($success, 5%);
    border: 1px solid $state-success-text;
    color: #fff;
}

.completeSelectedIcon-md {
    @extend .icon-md;
    @extend .completeSelectedIcon;
}

.completeSelectedIcon-lg {
    @extend .icon-lg;
    @extend .completeSelectedIcon;
}

.textContainer {
    margin-top: 10px;
}

.text {
    border-bottom: 2px solid transparent;
    display: inline-block;
    padding-bottom: 3px;
}

.unselectedText {
    @extend .text;
    color: $text-muted;
}

.completedText {
    @extend .text;
    color: $success;
}

.selectedText {
    @extend .text;
    border-color: $success;
    color: $success;
    font-size: 1.1em;
}

@media (max-width: $screen-sm-max) {
    .addMargin-md {
        margin-left: $icon-with-line-margin-md - $margin-subtraction;
    }

    .addMargin-lg {
        margin-left: $icon-with-line-margin-lg - $margin-subtraction;
    }

    .nextLine-md {
        width: $line-width-md - $margin-subtraction;
    }

    .nextLine-lg {
        width: $line-width-lg - $margin-subtraction;
    }
}

@media (max-width: $screen-xs-max) {
    .container {
        display: block;
        margin-bottom: 20px;
    }

    .addMargin-md,
    .addMargin-lg {
        margin-left: 0;
    }
}
