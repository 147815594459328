@import '../../theme/bootstrap-custom-variables';

/**
 * Custom Navbar
 * Generated using: https://work.smarchal.com/twbscolor/
 */
$bg-default: $opengov-blue;
$bg-highlight: $color-primary-dark;
$col-default: $gray-lighter;
$col-highlight: $color-fg-ko;

.opengovNavbar {
    flex-shrink: 0;
    font-size: 13px;
    z-index: 902 !important;

    &:global(.navbar-default) {
        background-color: $bg-default;
        border-color: $bg-highlight;

        :global(.navbar-brand) {
            color: $col-default;

            &:hover,
            &:focus {
                color: $col-highlight;
            }
        }

        :global(.navbar-text) {
            color: $col-default;
        }

        :global(.navbar-nav) {
            > li {
                > a {
                    color: $col-default;
                    font-weight: 500;

                    &:hover,
                    &:focus {
                        color: $col-highlight;
                    }
                }
            }

            > :global(.active) {
                > a,
                > a:hover,
                > a:focus {
                    background-color: $bg-highlight;
                    color: $col-highlight;
                }
            }

            > :global(.open) {
                > a,
                > a:hover,
                > a:focus {
                    background-color: $bg-highlight;
                    color: $col-highlight;
                }
            }
        }

        :global(.navbar-toggle) {
            border-color: $bg-highlight;

            &:hover,
            &:focus {
                background-color: $bg-highlight;
            }

            :global(.icon-bar) {
                background-color: $col-default;
            }
        }

        :global(.navbar-collapse),
        :global(.navbar-form) {
            border-color: $col-default;
        }

        :global(.navbar-link) {
            color: $col-default;

            &:hover {
                color: $col-highlight;
            }
        }
    }
    @media (max-width: $navbar-breakpoint-max-width) {
        &:global(.navbar-default .navbar-nav .open .dropdown-menu) {
            > li > a {
                color: $col-default;

                &:hover,
                &:focus {
                    color: $col-highlight;
                }
            }

            > :global(.active) {
                > a,
                > a:hover,
                > a:focus {
                    background-color: $bg-highlight;
                    color: $col-highlight;
                }
            }
        }
    }

    // Control when the Navbar collapses
    // https://stackoverflow.com/a/36289507/2518231
    @media (max-width: $navbar-breakpoint-max-width) {
        :global(.navbar-header) {
            float: none;
        }

        :global(.navbar-left),
        :global(.navbar-right) {
            float: none !important;
        }

        :global(.navbar-toggle) {
            display: block;
        }

        :global(.navbar-collapse) {
            border-top: 1px solid transparent;
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
        }

        :global(.navbar-fixed-top) {
            border-width: 0 0 1px;
            top: 0;
        }

        :global(.navbar-collapse.collapse) {
            display: none !important;
        }

        :global(.navbar-nav) {
            float: none !important;
            margin-top: 7.5px;
        }

        :global(.navbar-nav) > li {
            float: none;
        }

        :global(.navbar-nav) > li > a {
            padding-bottom: 10px;
            padding-top: 10px;
        }

        :global(.collapse.in) {
            display: block !important;
        }

        :global(.dropdown-menu) {
            float: none !important;
        }

        :global(.dropdown-header) {
            color: $col-highlight;
        }

        // Our addition to the above to handle the dropdown-menu. This is just copy/paste what
        // Bootstrap does on its own at the default 768px breakpoint
        :global(.navbar-nav .open .dropdown-menu) {
            background-color: transparent;
            border: 0;
            box-shadow: none;
            float: none;
            margin-top: 0;
            position: static;
            width: auto;
        }
    }
}

.appSwitcher {
    display: flex;
    float: left;
    height: 50px;
    padding: 0 8px;

    button {
        height: 32px;

        label {
            display: none;
        }

        // gridItem
        div > div > div > div {
            background-color: #eee !important;
        }

        &:hover > div {
            background-color: $color-bg-primary;
        }

        // gridItem
        &:hover div > div > div {
            background-color: $opengov-blue !important;
        }
    }

    // grid
    button > div > div {
        padding: 4px;
    }

    > div {
        background-color: #eee;
        z-index: 200;
    }
}
