@import '~@opengov/capital-style/scss/tokens';

.purchaseDetails {
    display: flex;
    flex-direction: column;
    gap: $unit-3;

    .summaryCard {
        align-self: stretch;
        background: $color-bg-primary;
        border: 1px solid $color-fg-border-primary;
        border-radius: $unit-half;
        display: flex;
        flex-direction: column;
        gap: $unit-3;
        padding: $unit-3;

        @media (max-width: 600px) {
            padding: $unit-2;
        }
    }

    .priceItemsContainer {
        display: flex;
        flex-direction: column;
        gap: $unit-1;

        .priceItem {
            display: flex;
            flex-direction: column;
            gap: $unit-1;

            :global(.input-group-addon) {
                background-color: $color-bg-secondary;
                color: $color-fg-secondary;
                font-weight: $font-weight-semi-bold;
            }
        }

        .addAnotherLineItem {
            padding-top: $unit-2;

            button {
                align-items: center;
                border: 1px solid #3e5de6;
                color: #3e5de6;
                display: flex;
                font-weight: $font-weight-semi-bold;
                gap: $unit-half;
            }
        }
    }

    .error {
        color: $color-fg-error;
        font-size: $font-size-small;
    }
}
